import { stringify } from "query-string";
import { fetchUtils, DataProvider } from "ra-core";
import { createQueryParams, mapResource } from "./provider.utils";

const apiService = (
  apiUrl: string,
  httpClient: any = fetchUtils.fetchJson,
  customProviders: object
): DataProvider => {
  const existsInResource = (resource, method) => {
    if (
      customProviders[resource] &&
      typeof customProviders[resource][method] === "function"
    ) {
      return customProviders[resource][method];
    }
  };

  const baseQueries: DataProvider = {
    getList: (resource, params) => {
      const override = existsInResource(resource, "getList");

      if (override) return override(resource, params);

      const query = createQueryParams(params);
      const url = `${apiUrl}/${mapResource(resource)}?${stringify(query)}`;

      return httpClient(url, {}).then(
        ({ headers, json }: { headers: any; json: any }) => {
          return {
            data: json.content,
            total: json.totalElements,
          };
        }
      );
    },

    getOne: (resource, params) => {
      const override = existsInResource(resource, "getOne");
      if (override) return override(resource, params);

      const url = `${apiUrl}/${mapResource(resource)}/${params.id}`;
      return httpClient(url).then(({ json }: { json: any }) => {
        return {
          data: json,
        };
      });
    },

    getMany: (resource, params) => {
      const override = existsInResource(resource, "getMany");
      if (override) return override(resource, params);

      const query = { filter: JSON.stringify({ id: params.ids }) };
      const url = `${apiUrl}/${mapResource(resource)}?${stringify(query)}`;
      return httpClient(url).then(({ json }: { json: any }) => {
        return { data: json.content };
      });
    },

    getManyReference: (resource, params) => {
      const override = existsInResource(resource, "getManyReference");
      if (override) return override(resource, params);

      const query = createQueryParams(params);
      const url = `${apiUrl}/${mapResource(resource)}?${stringify(query)}`;

      return httpClient(url, {}).then(({ json }: { json: any }) => {
        return {
          data: json.content,
          total: json.totalElements,
        };
      });
    },

    update: (resource, params) => {
      const override = existsInResource(resource, "update");
      if (override) return override(resource, params);

      const url = `${apiUrl}/${mapResource(resource)}/${params.id}`;
      return httpClient(url, {
        method: "PUT",
        body: JSON.stringify(params.data),
      }).then(({ json }: { json: any }) => {
        return { data: { ...json, id: json.id || json.uid } };
      });
    },

    updateMany: (resource, params) => {
      const override = existsInResource(resource, "updateMany");
      if (override) return override(resource, params);

      const url = `${apiUrl}/${mapResource(resource)}`;
      return Promise.all(
        params.ids.map((id) =>
          httpClient(`${url}/${id}`, {
            method: "PUT",
            body: JSON.stringify(params.data),
          })
        )
      ).then((responses) => ({ data: responses.map(({ json }) => json.id) }));
    },

    create: (resource, params) => {
      const override = existsInResource(resource, "create");
      if (override) return override(resource, params);

      const url = `${apiUrl}/${mapResource(resource)}`;
      return httpClient(url, {
        method: "POST",
        body: JSON.stringify(params.data),
      }).then(({ json }: { json: any }) => ({
        data: { ...params.data, id: json.id },
      }));
    },

    delete: (resource, params) => {
      const override = existsInResource(resource, "delete");
      if (override) return override(resource, params);

      const url = `${apiUrl}/${mapResource(resource)}/${params.id}`;
      return httpClient(url, { method: "DELETE" }).then(
        ({ json }: { json: any }) => ({ data: json })
      );
    },

    deleteMany: (resource, params) => {
      const override = existsInResource(resource, "deleteMany");
      if (override) return override(resource, params);

      const url = `${apiUrl}/${mapResource(resource)}`;

      return Promise.all(
        params.ids.map((id) => httpClient(`${url}/${id}`, { method: "DELETE" }))
      ).then((responses) => ({
        data: responses.map(({ json }: { json: any }) => json.id),
      }));
    },
  };

  const specialQueries = Object.entries(customProviders).reduce(
    (prev, [resource, queries]) => {
      return { ...prev, ...queries };
    },
    {}
  );

  return {
    ...specialQueries,
    ...baseQueries,
  };
};

export default apiService;
