import { Map, Marker } from 'pigeon-maps';

const SimpleMapComponent = (props) => {
  const latitude =
    props.coordinates?.latitude === undefined
      ? 48.14816
      : props.coordinates?.latitude;
  const longitude =
    props.coordinates?.longitude === undefined
      ? 17.10674
      : props.coordinates?.longitude;
  const color = `rgb(199, 58, 48)`;

  const handleClick = ({ latLng }) => props.coordinatesInputEvent(latLng)

  return (
    <Map onClick={handleClick} height={400} defaultCenter={[latitude, longitude]} defaultZoom={17}>
      <Marker width={50} color={color} anchor={[latitude, longitude]} />
    </Map>
  );
};

export default SimpleMapComponent;
