import styled from "styled-components";
import Dialog from "./Dialog";

const Image = styled.img(({ theme }) => ({}));

type ImagePreviewProps = {
  src: string;
  onClose: () => void;
  title?: string;
};

const ImagePreview = function ({ src, onClose, title }: ImagePreviewProps) {
  return (
    <Dialog title="" modal={false} onClose={onClose}>
      <Image src={src} alt="Preview image" />
    </Dialog>
  );
};

export default ImagePreview;
