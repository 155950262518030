import { useRecordContext } from "react-admin";
import ImagePreview from "../../common/ImagePreview";

const ImageFieldField = (props) => {
  const record = useRecordContext(props);

  return <ImagePreview url={props.url || record[props.source]} {...props} />;
};

export default ImageFieldField;
