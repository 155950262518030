import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { useCallback, useMemo } from "react";
import { MediaType } from "../types/enums";

type Props = {
  folder?: string;
};

/**
 * Firebase filestorage hook
 * @returns {upload, deleteFile, getUrl}
 */
export const useFileStorage = (props: Props = { folder: "" }) => {
  const storage = useMemo(() => getStorage(), []);
  const { folder } = props;

  /* Upload file to firestorage */
  const upload = useCallback(
    (f, success?, error?) => {
      if (!f?.length) return;
      const FIREBASE_STORAGE = process.env.REACT_APP_FIREBASE_STORAGE_FOLDER
      const file = f[0];
      const path = `${FIREBASE_STORAGE}/${folder}/${Date.now()}_${file.name}`;
      const isImage = file.type.includes("image");
      const storageRef = ref(storage, path);

      if (!isImage) return;

      uploadBytes(storageRef, file)
        .then((snapshot) => {
          typeof success === "function" &&
            success({
              url: path,
              type: isImage ? MediaType.PHOTO : MediaType.VIDEO,
              preview: false,
            });
        })
        .catch((err) => {
          console.error(err);
          typeof error === "function" && error(err);
        });
    },
    [folder, storage]
  );

  /* Get file URL from path */
  const getUrl = useCallback(
    (path) => {
      const storageRef = ref(storage, path);
      return getDownloadURL(storageRef);
    },
    [storage]
  );

  /** Detele file from Firestorage */
  const deleteFile = useCallback(
    (path, callback) => {
      const storageRef = ref(storage, path);
      deleteObject(storageRef)
        .then((data) => callback(data))
        .catch((error) => {
          console.error(error);
        });
    },
    [storage]
  );

  return {
    upload,
    deleteFile,
    getUrl,
  };
};
