import useChatContext from "./useChatContext";

const useText = () => {
  const { translations }: Record<string, string> = useChatContext();
  const t = (pattern: any) => {
    if (!translations) return pattern;
    return translations[pattern] || pattern;
  };
  return t;
};

export default useText;
