import { ReactNode } from "react";
import { ThemeProvider } from "styled-components";

export interface ChatTheme {
  palette: {
    primary: {
      dark: string;
      main: string;
      light: string;
    };
    secondary: {
      dark: string;
      main: string;
      light: string;
    };
    common: {
      white: string;
      black: string;
    };
    grey: Record<number, string>;
  };
  spacing: (i: number) => void;
}

function ChatThemeProvider({
  children,
  theme,
}: {
  children: ReactNode;
  theme?: ChatTheme;
}) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

export default ChatThemeProvider;
