import PasswordIcon from "@mui/icons-material/Lock";
// import useClipboard from "../../../hooks/useClipboard";
import { useCallback } from "react";
import { Button, ButtonProps } from "ra-ui-materialui";

const ResetPasswordButton = (props: ButtonProps) => {
  // const { writeText } = useClipboard();

  const handleClickButton = useCallback(() => {
    // userService()
    //   .getPasswordResetLink(record?.email)
    //   .then(({ json }) => writeText(json?.passwordResetLink));
  }, []);

  return (
    <Button label="general.resetPassword" onClick={handleClickButton}>
      <PasswordIcon />
    </Button>
  );
};

export default ResetPasswordButton;
