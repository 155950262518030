import React, { ReactElement } from "react";
import ChatThemeProvider from "./ChatThemeProvider";

const defaultTheme = {
  palette: {
    primary: {
      dark: "#1b1b1b",
      light: "#6c6c6c",
      main: "#414141",
      contrastText: "#fff",
    },
    secondary: {
      dark: "#c63d37",
      light: "#ffa18f",
      main: "#ff6f61",
      contrastText: "#fff",
    },
    common: {
      white: "#ffffff",
      black: "#000000",
    },
    grey: {
      100: "#efefef",
      200: "#eeeeee",
      300: "#dddddd",
      400: "#cccccc",
      500: "#bbbbbb",
      600: "#aaaaaa",
      700: "#999999",
      800: "#888888",
      900: "#777777",
    },
  },
  spacing: function (i: number) {
    if (i === 0) {
      return 0;
    } else if (i === 1) {
      return 8;
    } else if (i === 2) {
      return 16;
    } else if (i === 3) {
      return 24;
    } else if (i === 4) {
      return 32;
    }

    return 0;
  },
};

export const ChatContext = React.createContext({});

interface RebbidProviderProps {
  theme?: any;
  children: ReactElement | ReactElement[];
  translations?: Record<string, string>;
}

const defaultTranslations = {
  "type.as.owner": "Type message as owner",
  send: "SEND",
  "type.message": "Type a message...",
  "fetch.more": "Load more",
};

const RebbidProvider = function ({
  theme,
  children,
  translations = defaultTranslations,
}: RebbidProviderProps) {
  return (
    <ChatThemeProvider theme={{ ...defaultTheme, ...theme }}>
      <ChatContext.Provider value={{ translations }}>
        {children}
      </ChatContext.Provider>
    </ChatThemeProvider>
  );
};

export default RebbidProvider;
