import styled from "styled-components";

const Container = styled.div({
  width: "100%",
  textAlign: "center",
});

const LoaderBase = ({ className }: { className?: string }) => {
  return (
    <Container>
      <div className={className}>
        <div></div>
        <div></div>
      </div>
    </Container>
  );
};

const Loader = styled(LoaderBase)(({ theme }) => ({
  display: "inline-block",
  position: "relative",
  width: 64,
  height: 64,
  "& div": {
    position: "absolute",
    background: theme.palette.secondary.main,
    opacity: 1,
    borderRadius: "50%",
    animation: "loading 1.4s cubic-bezier(0, 0.2, 0.8, 1) infinite",
  },
  "& div:nth-child(2)": {
    animationDelay: "-.7s",
  },
  "@keyframes loading": {
    "0%": {
      top: 28,
      left: 28,
      width: 0,
      height: 0,
      opacity: 1,
    },
    "100%": {
      top: -1,
      left: -1,
      width: 58,
      height: 58,
      opacity: 0,
    },
  },
}));

export default Loader;
