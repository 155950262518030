import { useRecordContext, useTranslate } from "react-admin";
import { Box, Grid, styled, Paper, Typography } from "@mui/material";
import SectionTitle from "../../components/common/SectionTitle";
import { displayDate, renderAddress } from "../../utils/utils";
import AdvertisementStateField from "./StateField";
import PricingSection from "./PricingSection";
import PersonInfo from "../../components/common/PersonInfo";
import SimpleList from "./SimpleList";
import ImageGallery from "../../components/widgets/media-gallery/MediaGallery";
import LocationLink from "../../components/ra/fields/LocationLinkField";
import RatingSection from "./RatingSection";
import Video from "../../components/common/Video";
import DetailSection from "./DetailSection";
import { Advertisement } from "../../types/model/model";

const CardPaper = styled(Paper)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  overflow: "hidden",
}));

const AvailableFrom = styled(Typography)(({ theme }) => ({
  fontSize: "0.875rem",
  color: theme.palette.grey[500],
}));

const AdvertisementDetail = (props) => {
  const record = useRecordContext<Advertisement>(props);
  const t = useTranslate();
  if (!record) return null;
  return (
    <Box p={4}>
      <Box display="flex" p={4} paddingLeft={0} paddingRight={0}>
        <Box flex="2">
          <Typography variant="h1">{renderAddress(record)}</Typography>
          <AvailableFrom>
            {t("advertisement.availableFrom")}:{" "}
            {record?.pricing?.availableFrom
              ? displayDate(record?.pricing?.availableFrom)
              : " - "}
          </AvailableFrom>
          <LocationLink />
        </Box>
        <Box flex="1" pl={2}>
          <Box pb={2} textAlign="right">
            <AdvertisementStateField />
          </Box>
        </Box>
      </Box>
      <Grid container spacing={2}>
        <Grid item sm={12} md={6}>
          <CardPaper elevation={4}>
            {record?.owner && (
              <PersonInfo
                advertisementType={record.advertisementType}
                photo={record.owner.photo}
                email={record.owner.email}
                firstName={record.owner.firstName}
                lastName={record.owner.lastName}
                website={record.owner.webUrl}
                bookMeUrl={record.owner.bookMeUrl}
                role={record.owner.role}
              />
            )}
            <Box p={4}>
              <SectionTitle>{t("advertisement.pricing")}</SectionTitle>
              <PricingSection rent={record.rent} pricing={record?.pricing} />
            </Box>
          </CardPaper>
          <CardPaper elevation={4}>
            <Box p={4}>
              <SectionTitle>{t("advertisement.description")}</SectionTitle>
              <Typography>{record?.description}</Typography>
            </Box>
          </CardPaper>
          <CardPaper elevation={4}>
            <Box p={4}>
              <SectionTitle>{t("advertisement.details")}</SectionTitle>
              {record?.details && <DetailSection {...record?.details} />}
            </Box>
          </CardPaper>
          <CardPaper elevation={4}>
            <Box p={4}>
              <SectionTitle>{t("advertisement.amenities")}</SectionTitle>
              <SimpleList
                items={record?.amenities?.map((amenity) => ({
                  icon: `amenities/${amenity.name}`,
                  iconUrl: amenity.iconUrl,
                  title: amenity.name,
                  disabled: !amenity.active,
                }))}
              />
            </Box>
          </CardPaper>
        </Grid>
        <Grid item sm={12} md={6}>
          {record.rating && (
            <CardPaper elevation={4}>
              <Box p={4}>
                <SectionTitle>{t("advertisement.rating")}</SectionTitle>
                <RatingSection rating={record?.rating} />
              </Box>
            </CardPaper>
          )}
          {record?.medias?.length > 0 && (
            <CardPaper elevation={4}>
              <Box p={4}>
                <ImageGallery medias={record?.medias} />
              </Box>
            </CardPaper>
          )}
          {record?.urlYoutube && (
            <CardPaper elevation={4}>
              <Box pt={2} p={4}>
                <SectionTitle>{t("advertisement.video")}</SectionTitle>
                <Box pt={2}>
                  <Video
                    controls={true}
                    width="100%"
                    url={record?.urlYoutube}
                  />
                </Box>
              </Box>
            </CardPaper>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default AdvertisementDetail;
