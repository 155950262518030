import { useInput } from "react-admin";
import Slider from "@mui/material/Slider";
import { useCallback, useEffect } from "react";
import { Labeled } from "react-admin";

const BoundedSliderInput = (props) => {
  const { label, min, step, max, initValue } = props;
  const {
    field: { name, onChange, ...rest },
  } = useInput(props);

  const handleChange = useCallback(
    (e: any, val: any) => {
      onChange(val);
    },
    [onChange]
  );

  useEffect(() => {
    onChange(initValue);
    // eslint-disable-next-line
  }, []);

  return (
    <Labeled fullWidth label={label}>
      <Slider
        value={rest.value || initValue}
        min={min}
        step={step}
        max={max}
        onChange={handleChange}
        valueLabelDisplay="auto"
        aria-labelledby="non-linear-slider"
      />
    </Labeled>
  );
};

const SliderInput = (props) => {
  const { ...rest } = props;

  return (
    <span>
      <BoundedSliderInput {...rest} />
    </span>
  );
};

export default SliderInput;
