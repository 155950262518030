import { useTranslate } from "react-admin";
import {
  Dialog as MuiDialog,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { styled } from "@mui/material";

const DialogTitleWraper = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  alignItems: "center",
  justifyContent: "space-between",
}));

type Props = {
  title?: string;
  subtitle?: string;
  onClose: (e: React.MouseEvent) => any;
  children: JSX.Element;
};
const Dialog = ({ title, subtitle, onClose, children }: Props) => {
  const t = useTranslate();

  return (
    <MuiDialog onClose={onClose} fullWidth open={true}>
      <DialogTitle>
        <DialogTitleWraper>
          <span>
            {title && <Typography variant="h6">{t(title)}</Typography>}
            {subtitle && <Typography>{t(subtitle)}</Typography>}
          </span>
          {onClose && (
            <IconButton onClick={onClose} aria-label="close">
              <CloseOutlined />
            </IconButton>
          )}
        </DialogTitleWraper>
      </DialogTitle>
      {children}
    </MuiDialog>
  );
};

export default Dialog;
