import React, { useCallback } from "react";
import Dialog from "../../common/Dialog";
import useResourceModalDialogContext from "./context/useResourceModalDialogContext";

interface Props {
  form: JSX.Element;
}

const ResourceModalDialog = ({ form }: Props) => {
  const [{ record, type }, updateState] = useResourceModalDialogContext();

  const handleClose = useCallback(() => {
    updateState({ record: null, type: null });
  }, [updateState]);

  return (
    <>
      {type === "EDIT" && record?.id && (
        <Dialog title="video.dialog.editTitle" onClose={handleClose}>
          {React.cloneElement(form, { record, onSuccess: handleClose })}
        </Dialog>
      )}
      {type === "CREATE" && (
        <Dialog title="video.dialog.createTitle" onClose={handleClose}>
          {React.cloneElement(form, { record, onSuccess: handleClose })}
        </Dialog>
      )}
    </>
  );
};

export default ResourceModalDialog;
