import { MenuItemLink } from "react-admin";
import { styled } from "@mui/material";

const StyledMenuItemLink = styled(MenuItemLink)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  fontSize: "0.95rem",
  "&.RaMenuItemLink-active": {
    color: theme.palette.primary.contrastText,
  },
  "& .RaMenuItemLink-root": {
    color: theme.palette.primary.contrastText,
  },
  "& .RaMenuItemLink-icon": {
    color: theme.palette.primary.contrastText,
  },
}));

const CustomMenuItemLink = (props: any) => {
  return <StyledMenuItemLink {...props} />;
};

export default CustomMenuItemLink;
