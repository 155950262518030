import { ReactElement } from "react";
import styled from "styled-components";
import { Size, ThemeColor } from "../../types/enums";
import { getButtonColor } from "../../utils/base";

type IconButtonProps = {
  children: ReactElement;
  size?: number;
  color?: ThemeColor;
  onClick?: () => void;
};

const IconButtonCircle = styled.div<IconButtonProps>(
  ({ theme, color, size = Size.MD }) => ({
    borderRadius: "90%",
    width: size,
    height: size,
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
    justifyContent: "center",
    backgroundColor: getButtonColor(theme, color),
    "&:hover": {
      backgroundColor: getButtonColor(theme, color, true),
    },
  })
);

function IconButton({ children, ...rest }: IconButtonProps) {
  return <IconButtonCircle {...rest}>{children}</IconButtonCircle>;
}

export default IconButton;
