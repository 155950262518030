import {
  TextField,
  useTranslate,
  FunctionField,
  BooleanField,
  TextInput,
  ReferenceInput,
  SelectInput,
  useRecordContext,
  DateField
} from "react-admin";
import UpgradeToBrokerButton from "../../modules/account/UpgradeToBrokerButton";
import Datagrid from "../../components/ra/Datagrid";
import ImageAvatarField from "../../components/ra/fields/ImageField";
import CustomList from "../../components/ra/List";
import BulkPushNotificationButton from "../../modules/push-notification/BulkPushNotificationButton";
import { useMediaQuery } from "@mui/material";
import { useState } from "react";

const UserFilter = [
  <TextInput label="user.email" source="email" alwaysOn />,
  <ReferenceInput
    label="user.office"
    source="officeId"
    alwaysOn
    reference="office"
  >
    <SelectInput optionText="name" size="small" />
  </ReferenceInput>,
  <SelectInput
    alwaysOn
    label="user.role"
    choices={[
      { id: "USER", name: "USER" },
      { id: "BROKER", name: "BROKER" },
      { id: "ADMIN", name: "ADMIN" },
    ]}
    source="userType"
    resource="users"
    fullWidth
  />,
];

const BulkActionButtons = (props) => (
  <>
    <BulkPushNotificationButton
      setSelectedIds={props.setSelectedIds}
      {...props}
    />
  </>
);

const rowStyle = (record) => ({
  backgroundColor: record?.state === "DISABLED" && "#ffefde",
  height: "41px",
});

const postRowSx = (record, selectedIDs) => ({
  backgroundColor: selectedIDs.includes(record.id) ? "#0000000a" : "white",
});

const ColoredBooleanField = (props) => {
  const record = useRecordContext();
  return (
    <BooleanField
      sx={{ color: record[props.source] === true ? "#00E400" : "#000000de" }}
      {...props}
    />
  );
};

const facebookIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="24"
    height="24"
    viewBox="0 0 48 48"
  >
    <path
      fill="#3F51B5"
      d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z"
    ></path>
    <path
      fill="#FFF"
      d="M34.368,25H31v13h-5V25h-3v-4h3v-2.41c0.002-3.508,1.459-5.59,5.592-5.59H35v4h-2.287C31.104,17,31,17.6,31,18.723V21h4L34.368,25z"
    ></path>
  </svg>
);

const appleIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="24"
    height="24"
    viewBox="0 0 50 50"
  >
    <path d="M 44.527344 34.75 C 43.449219 37.144531 42.929688 38.214844 41.542969 40.328125 C 39.601563 43.28125 36.863281 46.96875 33.480469 46.992188 C 30.46875 47.019531 29.691406 45.027344 25.601563 45.0625 C 21.515625 45.082031 20.664063 47.03125 17.648438 47 C 14.261719 46.96875 11.671875 43.648438 9.730469 40.699219 C 4.300781 32.429688 3.726563 22.734375 7.082031 17.578125 C 9.457031 13.921875 13.210938 11.773438 16.738281 11.773438 C 20.332031 11.773438 22.589844 13.746094 25.558594 13.746094 C 28.441406 13.746094 30.195313 11.769531 34.351563 11.769531 C 37.492188 11.769531 40.8125 13.480469 43.1875 16.433594 C 35.421875 20.691406 36.683594 31.78125 44.527344 34.75 Z M 31.195313 8.46875 C 32.707031 6.527344 33.855469 3.789063 33.4375 1 C 30.972656 1.167969 28.089844 2.742188 26.40625 4.78125 C 24.878906 6.640625 23.613281 9.398438 24.105469 12.066406 C 26.796875 12.152344 29.582031 10.546875 31.195313 8.46875 Z"></path>
  </svg>
);

const passwordIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    height="20"
    width="20"
    viewBox="0 0 20 20"
  >
    <path
      d="M1 5.006A2 2 0 012.995 3h14.01A2 2 0 0119 5.006v9.988A2 2 0 0117.005 17H2.995A2 2 0 011 14.994V5.006zM3 5l7 4 7-4v2l-7 4-7-4V5z"
      fill-opacity=".54"
      fill-rule="evenodd"
    ></path>
  </svg>
);

const googleIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="24"
    height="24"
    viewBox="0 0 120 120"
  >
    <path
      d="M107.145,55H100H87.569H60v18h27.569c-1.852,5.677-5.408,10.585-10.063,14.118 C72.642,90.809,66.578,93,60,93c-12.574,0-23.278-8.002-27.299-19.191C31.6,70.745,31,67.443,31,64 c0-3.839,0.746-7.505,2.101-10.858C37.399,42.505,47.823,35,60,35c7.365,0,14.083,2.75,19.198,7.273l13.699-13.21 C84.305,20.969,72.736,16,60,16c-18.422,0-34.419,10.377-42.466,25.605C14,48.291,12,55.912,12,64c0,7.882,1.9,15.32,5.267,21.882 C25.223,101.389,41.372,112,60,112c12.382,0,23.668-4.688,32.182-12.386C101.896,90.831,108,78.128,108,64 C108,60.922,107.699,57.917,107.145,55z"
      opacity=".35"
    ></path>
    <path
      fill="#44BF00"
      d="M17.267,81.882C25.223,97.389,41.372,108,60,108c12.382,0,23.668-4.688,32.182-12.386L77.506,83.118 C72.642,86.809,66.577,89,60,89c-12.574,0-23.278-8.002-27.299-19.191L17.267,81.882z"
    ></path>
    <path
      d="M77.506,83.118c-0.684,0.553-1.685,1.158-2.398,1.638l14.711,12.846 c0.807-0.641,1.6-1.298,2.363-1.988L77.506,83.118z"
      opacity=".35"
    ></path>
    <path
      fill="#0075FF"
      d="M92.182,95.614C101.896,86.83,108,74.128,108,60c0-3.078-0.301-6.083-0.855-9H100H87.569H60v18 h27.569c-1.852,5.677-5.408,10.585-10.063,14.118L92.182,95.614z"
    ></path>
    <path
      d="M32.701,69.809L17.267,81.882c0.486,0.948,1.004,1.877,1.551,2.787l15.3-11.576 C33.63,72.181,33.05,70.804,32.701,69.809z"
      opacity=".35"
    ></path>
    <path
      fill="#FFC400"
      d="M17.267,81.882C13.9,75.32,12,67.882,12,60c0-8.088,2-15.709,5.534-22.395l15.568,11.537 C31.746,52.496,31,56.161,31,60c0,3.443,0.6,6.745,1.701,9.809L17.267,81.882z"
    ></path>
    <path
      d="M17.534,37.605c-0.482,0.844-1.169,2.36-1.564,3.251l16.059,11.491 c0.299-1.095,0.653-2.167,1.072-3.205L17.534,37.605z"
      opacity=".35"
    ></path>
    <path
      fill="#FF1200"
      d="M33.101,49.142C37.399,38.505,47.823,31,60,31c7.365,0,14.083,2.75,19.198,7.273l13.699-13.21 C84.305,16.969,72.736,12,60,12c-18.422,0-34.419,10.377-42.466,25.605L33.101,49.142z"
    ></path>
  </svg>
);

const UserList = ({ ...props }) => {
  const t = useTranslate();
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));
  const [selectedIDs, setSelectedIds] = useState([]);

  return (
    <>
      <CustomList
        filters={UserFilter}
        title={"user.listTitle"}
        filterDefaultValues={{ email: "" }}
        bulkActionButtons={
          <BulkActionButtons setSelectedIds={setSelectedIds} />
        }
        {...props}
      >
        {isSmall ? (
          <Datagrid rowClick="show" rowStyle={rowStyle}>
            <ImageAvatarField label="user.photo" source="photo" />

            <TextField label="user.email" source="email" />

            <UpgradeToBrokerButton />
          </Datagrid>
        ) : (
          <Datagrid
            rowClick="show"
            rowStyle={rowStyle}
            rowSx={(record) => postRowSx(record, selectedIDs)}
            isRowSelectable={(record) =>
              record.state === "DISABLED" ? false : true
            }
          >
            {/* <EditUserButton /> */}
            <TextField label="user.id" source="originalId" sortBy="id" />
            <ImageAvatarField label="user.photo" source="photo" />
            <TextField label="user.firstName" source="firstName" />
            <TextField label="user.lastName" source="lastName" />
            <TextField label="user.uid" source="uid" />
            <TextField label="user.email" source="email" />
            <TextField label="user.office" source="office.name" />
            <FunctionField
              label="user.role"
              render={(record) => record.role && t(`user.roles.${record.role}`)}
              source="role"
            />
            <FunctionField
              label="user.provider"
              textAlign="center"
              source="provider"
              sortBy="provider"
              render={(record) =>
                record.provider?.toLowerCase() === "password"
                  ? passwordIcon
                  : record.provider?.toLowerCase() === "facebook.com"
                  ? facebookIcon
                  : record.provider?.toLowerCase() === "apple.com"
                  ? appleIcon
                  : record.provider?.toLowerCase() === "google.com"
                  ? googleIcon
                  : ""
              }
            />
            <TextField label="user.phone" source="phone" />
            <ColoredBooleanField
              label="user.phoneVerified"
              source="phoneVerified"
            />
            <TextField label="user.state" source="state" />
            <DateField
              label="general.created"
              source="createdAt"
              options={{
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
              }}
            />
            <UpgradeToBrokerButton />
          </Datagrid>
        )}
      </CustomList>
    </>
  );
};

export default UserList;
