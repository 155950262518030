import { LoadingIndicator, SidebarToggleButton, UserMenu } from "react-admin";
import {
  styled,
  Typography,
  Theme,
  AppBar as MuiAppBar,
  Toolbar,
  useMediaQuery,
} from "@mui/material";

const MyUserMenu = (props: any) => <UserMenu {...props}></UserMenu>;

const PREFIX = "RaAppBar";

export const AppBarClasses = {
  appBar: `${PREFIX}-appBar`,
  toolbar: `${PREFIX}-toolbar`,
  menuButton: `${PREFIX}-menuButton`,
  menuButtonIconClosed: `${PREFIX}-menuButtonIconClosed`,
  menuButtonIconOpen: `${PREFIX}-menuButtonIconOpen`,
  title: `${PREFIX}-title`,
};

const StyledAppBar = styled(MuiAppBar, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  [`& .${AppBarClasses.toolbar}`]: {
    paddingRight: 24,
  },

  [`& .${AppBarClasses.menuButton}`]: {
    marginLeft: "0.2em",
    marginRight: "0.2em",
  },

  [`& .${AppBarClasses.title}`]: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
}));

const Container = styled("div")(({ theme }) => ({
  position: "static",
}));

const Spacer = styled("span")(({ theme }) => ({
  flex: 1,
}));

const Title = styled(Typography)(({ theme }) => ({
  flex: 1,
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
}));

const MyAppBar = (props) => {
  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("sm")
  );

  return (
    <Container>
      <StyledAppBar
        position="static"
        className={AppBarClasses.appBar}
        // color="secondary"
      >
        <Toolbar
          disableGutters
          variant={isXSmall ? "regular" : "dense"}
          className={AppBarClasses.toolbar}
        >
          <SidebarToggleButton className={AppBarClasses.menuButton} />
          <Title variant="h6" color="inherit" id="react-admin-title" />
          <Spacer />
          <LoadingIndicator />
          <MyUserMenu />
        </Toolbar>
      </StyledAppBar>
    </Container>
  );
};

export default MyAppBar;
