import styled from "styled-components";
import {
  Markunread as MdMarkunread,
  LocationCity as BiBuildingHouse,
} from "@mui/icons-material";
import { Advertisement } from "../../../../types/model/model";

const ChatListItem = styled.li(({ theme }) => ({
  transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
  boxShadow:
    "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;",
  width: "100%",
  display: "flex",
  color: theme.palette.common.white,
  height: 40,
  justifyContent: "flex-start",
  paddingLeft: theme.spacing(1),
  position: "relative",
  alignItems: "center",
  cursor: "pointer",
  background: theme.palette.primary.light,
  marginBottom: 4,
  boxSizing: "border-box",
  borderRadius: 0,
  "&.active": {
    backgroundColor: theme.palette.primary.main,
  },
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
  },
}));

const TextContainer = styled.span(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(4),
}));

const MainItemText = styled.div(({ theme }) => ({
  fontSize: "0.8rem",
  display: "block",
  alignItems: "center",
  alignContent: "center",
  color: "inherit",
}));

const SecondaryItemText = styled.div(({ theme }) => ({
  fontSize: "0.6rem",
  display: "flex",
  justifyContent: "flex-end",
  position: "absolute",
  right: 4,
  bottom: 2,
}));

const NewMessageIcon = styled(MdMarkunread)(({ theme }) => ({
  position: "absolute",
  right: 4,
  top: 4,
  color: theme.palette.secondary.main,
}));

type AdvertisementTabItemProps = {
  onClick: (chat: Advertisement) => void;
  advertisement: Advertisement;
  selected: boolean;
};

const AdvertisementTabItem = function ({
  onClick,
  advertisement,
  selected,
}: AdvertisementTabItemProps) {
  const classNames = selected ? "active" : "";

  return (
    <ChatListItem
      key={advertisement.id}
      className={classNames}
      onClick={() => onClick(advertisement)}
    >
      <BiBuildingHouse />
      {advertisement.hasChatWithNewMessage && <NewMessageIcon />}
      <TextContainer>
        <MainItemText>{advertisement.address.streetName}</MainItemText>
        <SecondaryItemText>{advertisement.address.city}</SecondaryItemText>
      </TextContainer>
    </ChatListItem>
  );
};

export default AdvertisementTabItem;
