import { IconButton } from "@mui/material";
import { useRecordContext } from "react-admin";
import LinkIcon from "@mui/icons-material/Link";

const ExternalLinkField = (props) => {
  const record = useRecordContext(props);

  if (!props.source) {
    return null;
  }
  return (
    <a href={record[props.source]} target="_blank" rel="noopener noreferrer">
      <IconButton size="small">
        <LinkIcon />
      </IconButton>
    </a>
  );
};

export default ExternalLinkField;
