import { Edit } from "ra-ui-materialui";
import { EditActions } from "../../components/ra/Actions";
import AdvertisementForm from "../../modules/advertisement/AdvertisementForm";

const AdvertisementEdit = ({ ...props }) => (
  <Edit actions={<EditActions />} title={"advertisement.editTitle"} {...props}>
    <AdvertisementForm />
  </Edit>
);

export default AdvertisementEdit;
