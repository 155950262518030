import { mapResource } from "./provider.utils";

/**
 *
 * Push notification provider
 * @returs DataProvider
 */

export const notificationProvider = ({ apiUrl, httpClient }) => {
  return {
    create: (resource, params) => {
      const url = `${apiUrl}/${mapResource(resource)}/send`;
      return httpClient(url, {
        method: "POST",
        body: JSON.stringify(params.data),
      }).then(({ json }: { json: any }) => ({
        data: { ...params.data, id: json.id },
      }));
    },
  };
};
