import { styled } from "@mui/material";
import MediaIcon from "@mui/icons-material/ImageOutlined";
import { useTranslate } from "react-admin";

const Base = styled("div")(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  transition: "all 0.4s ease-in-out",
  opacity: 0,
  color: theme.palette.common.white,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  "&:hover": {
    opacity: 1,
    background: `rgba(0,0,0,0.7)`,
  },
}));

const Wrapper = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

const ImagePreviewOverlay = () => {
  const t = useTranslate();
  return (
    <Base>
      <Wrapper>
        <MediaIcon />
      </Wrapper>
      {t("general.showImage")}
    </Base>
  );
};

export default ImagePreviewOverlay;
