import { stringify } from "query-string";

const chatProvider = ({ apiUrl, httpClient }) => ({
  getOffsetList: (resource, params) => {
    let url = `${apiUrl}/${resource}`;

    if (params.id) {
      url += `/${params.id}`;
    }

    if (params.offset !== undefined && params.limit !== undefined) {
      url += `?${stringify({
        offset: params.offset,
        limit: params.limit,
      })}`;
    }

    return httpClient(url).then(({ json }) => {
      return {
        data: json,
        total: json.length,
      };
    });
  },

  getChatMessages: (params) => {
    return httpClient(
      `${apiUrl}/msg/${params.chatId}?${stringify({
        offset: params.offset,
        limit: params.limit,
      })}`
    ).then(({ json }) => {
      return {
        data: json,
        total: json.length,
      };
    });
  },

  createChatMessage: (resource, params) => {
    return httpClient(`${apiUrl}/${resource}`, {
      method: "POST",
      body: JSON.stringify(params.data),
    }).then(({ json }: { json: any }) => ({
      data: { ...params.data, id: json.id },
    }));
  },

  getContractOffers: (resource, params) => {
    return httpClient(
      `${apiUrl}/${resource}/${params.id}?${stringify({
        offset: params.offset,
        limit: params.limit,
      })}`
    )
      .then(({ json }) => {
        return {
          data: json,
          total: json.length,
        };
      })
      .catch((err) => {
        console.error(err);
        return {
          data: [],
          total: 0,
        };
      });
  },

  getContractOffersReference: (resource, params) => {
    const url = `${apiUrl}/${resource}/${params.id}`;
    const options = {};
    const offerMapper = (o) => ({ ...o, id: o?.offer?.id });

    return httpClient(url, options)
      .then(({ json }) => {
        return {
          data: json?.map(offerMapper),
          total: json?.length,
        };
      })
      .catch((err) => {
        console.error(err);
        return {
          data: [],
          total: 0,
        };
      });
  },
});

export default chatProvider;
