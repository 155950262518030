import OfficeIcon from "@mui/icons-material/HomeWorkOutlined";
import HomeWork from "@mui/icons-material/HomeWorkOutlined";
import PeopleIcon from "@mui/icons-material/PeopleOutline";
import MediaIcon from "@mui/icons-material/VideoFileOutlined";
import CustomMenuItemLink from "./MenuItemLink";
import { Menu } from "react-admin";

const CustomMenu = (props) => {
  return (
    <Menu {...props}>
      {/* <DashboardMenuItem /> */}
      <CustomMenuItemLink
        to="/advertisement"
        primaryText="Advertisements"
        leftIcon={<HomeWork />}
      />
      <CustomMenuItemLink
        to="/user"
        primaryText="Users"
        leftIcon={<PeopleIcon />}
      />
      <CustomMenuItemLink
        to="/office"
        primaryText="Offices"
        leftIcon={<OfficeIcon />}
      />
      <CustomMenuItemLink
        to="/video"
        primaryText="Videos"
        leftIcon={<MediaIcon />}
      />
    </Menu>
  );
};

export default CustomMenu;
