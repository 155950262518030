import { useCallback } from "react";
import { useRecordContext, useTranslate } from "react-admin";
import { Button } from "@mui/material";
import useOfficeUserManagement from "./useOfficeProvider";
import RemovePersonIcon from "@mui/icons-material/CloseOutlined";

const RemoveBrokerFromOfficeButton = ({
  classes,
  onClick,
  officeId,
  ...rest
}: any) => {
  const { removeUserFromOffice } = useOfficeUserManagement();
  const t = useTranslate();
  const record = useRecordContext();

  const handleRemoveUserFromOffice = useCallback(() => {
    removeUserFromOffice({ userId: record.uid, officeId });
  }, [record, officeId, removeUserFromOffice]);

  return (
    <Button
      size="small"
      variant="outlined"
      endIcon={<RemovePersonIcon />}
      onClick={handleRemoveUserFromOffice}
    >
      {t("office.removeBrokerButton")}
    </Button>
  );
};

export default RemoveBrokerFromOfficeButton;
