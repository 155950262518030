import { useInput } from "react-admin";
import { useFileStorage } from "../../../hooks/useFileStorage";
import { useCallback } from "react";
import Dropzone from "../../common/Dropzone/Dropzone";
import { Media } from "../../../types/model/model";

const BoundedFileInput = (props) => {
  const { folder = "advertisement" } = props;
  const { upload } = useFileStorage({ folder });
  const { multiple = false } = props;
  const {mediasError} = props;
  const {
    field: { name, onChange, value, ...rest },
  } = useInput(props);

  const handleChange = useCallback(
    (file) => {
      if (multiple && Array.isArray(value)) {
        onChange([...value, file]);
      } else {
        onChange(file?.url);
      }
    },
    [onChange, value, multiple]
  );

  const handleDelete = useCallback(
    (file: Media) => {
      // deleteFile(file.url, handleDelete);
      if (multiple && Array.isArray(value)) {
        onChange(value.filter((x) => x.url !== file.url));
      } else {
        onChange(null);
      }
    },
    [onChange, value, multiple]
  );

  const getValue = (): Array<Media> => {
    if (!value) return [];
    if (Array.isArray(value)) {
      return value;
    } else {
      return [{ url: value, id: 0, preview: false, type: "PHOTO" }];
    }
  };

  const handleMarkAsPreview = useCallback(
    (file: Media) => {
      onChange(
        value.map((x) => ({
          ...x,
          preview: x.url === file.url,
        }))
      );
    },
    [value, onChange]
  );

  return (
    <Dropzone
      accept="image/*"
      multiple={multiple}
      files={getValue()}
      onAttachmentClick={handleMarkAsPreview}
      onDrop={(acceptedFiles) => upload(acceptedFiles, handleChange)}
      onDeleteClick={handleDelete}
      mediasError={mediasError}
      {...rest}
    />
  );
};

const FileInput = (props) => {
  const { source,mediasError, ...rest } = props;

  return (
    <span>
      <BoundedFileInput source={source} mediasError={mediasError} {...rest} />
    </span>
  );
};

export default FileInput;
