import ReactPlayer from "react-player";
import FirebaseVideo from "./FirebaseVideo";

interface Props {
  url: string;
  width?: string;
  height?: string;
  controls?: boolean;
}

const Video = ({ url, ...rest }: Props) => {
  if (url.indexOf("http") > -1) {
    return <ReactPlayer url={url} {...rest} />;
  }
  return <FirebaseVideo src={url} {...rest} />;
};

export default Video;
