import { Edit } from "ra-ui-materialui";
import { EditActions } from "../../components/ra/Actions";
import UserForm from "../../modules/account/Form";

const UserEdit = ({ ...props }) => (
  <Edit actions={<EditActions />} title={"user.editTitle"} {...props}>
    <UserForm />
  </Edit>
);

export default UserEdit;
