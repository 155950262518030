import useConfig from "./useConfig";

type Props = {
  lon: string | number;
  lat: string | number;
};

const useGoogleMapsLocation = ({ lon, lat }: Props) => {
  const config = useConfig();
  const url = `${config.google.mapSearchUrl}/${lat},${lon}`;
  return url;
};

export { useGoogleMapsLocation };
