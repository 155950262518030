import { Box } from "@mui/material";
import { CustomToolbar } from "../../components/ra/Toolbars";
import { SaveButton, TextInput } from "ra-ui-materialui";
import { Form, RaRecord, required, SelectInput } from "react-admin";
import useVideoFormSubmit from "./useVideoFormSubmit";
import { useFormState } from "react-hook-form";
import { VIDEO_TYPES } from "../../types/options";
import { VideoType } from "../../types/enums";

const SubmitButton = (props) => {
  const { isDirty, isValid } = useFormState();
  return (
    <SaveButton
      color="secondary"
      disabled={!isDirty || !isValid}
      variant="contained"
      {...props}
    />
  );
};

interface Props {
  record?: RaRecord;
  onSuccess?: () => any;
}

const VideoForm = ({ record, onSuccess }: Props) => {
  const { handleSubmit } = useVideoFormSubmit({ onSuccess });

  return (
    <Form
      defaultValues={{ id: record?.id, type: record?.type, url: record?.url }}
      mode="all"
      onSubmit={handleSubmit}
    >
      <Box p={3}>
        <SelectInput
          label="video.field.type"
          defaultValue={VideoType.VIDEO}
          validate={required()}
          choices={VIDEO_TYPES}
          source="type"
          fullWidth
        />
        <TextInput
          fullWidth
          validate={required()}
          label="video.field.url"
          source="url"
        />
      </Box>
      <CustomToolbar>
        <Box display="flex" justifyContent="space-between" width="100%">
          <SubmitButton />
        </Box>
      </CustomToolbar>
    </Form>
  );
};

export default VideoForm;
