import OfficeBrokerList from "./OfficeBrokerList";
import UserToOfficePanel from "./UserToOfficePanel";
import { Box, Grid } from "@mui/material";
import useOfficeProvider from "./useOfficeProvider";
import { useCallback } from "react";
import { useRecordContext } from "react-admin";
import { Office } from "../../types/model/model";

const OfficeBrokerManagement = () => {
  const record = useRecordContext<Office>();
  const { addUserToOffice } = useOfficeProvider();

  const handleAddBrokerToOffice = useCallback(
    (user) => {
      addUserToOffice({ userId: user.uid, officeId: record.id });
    },
    [record, addUserToOffice]
  );

  return (
    <Box pb={2}>
      <Box>
        <UserToOfficePanel
          onUserPick={handleAddBrokerToOffice}
          resource="user"
          id="user-office"
          label="office.searchBroker"
          filterAttr="email"
        />
      </Box>

      <Grid container spacing={2}>
        <Grid xs={12} sm={12} md={12} item>
          <OfficeBrokerList officeId={record.id} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default OfficeBrokerManagement;
