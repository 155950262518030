export enum SenderType {
  LOOKING_RENT = "LOOKING_RENT",
  PROPERTY_OWNER = "PROPERTY_OWNER",
}

export enum MessageType {
  TEXT_MESSAGE = "TEXT_MESSAGE",
  FILE_MESSAGE = "FILE_MESSAGE",
}

export enum ButtonGrey {
  HOVER = 400,
  DEFAULT = 200,
}

export enum Size {
  XS = 20,
  SM = 30,
  MD = 40,
  LG = 60,
  XL = 80,
}

export enum ButtonType {
  BUTTON = "button",
  SUBMIT = "submit",
}

export enum ButtonVariant {
  CONTAINED = "contained",
  TEXT = "text",
  OUTLINED = "outlined",
}

export enum ThemeColor {
  PRIMARY = "primary",
  SECONDARY = "secondary",
}

export enum FontSize {
  XXSMALL = "0.6rem",
  XSMALL = "0.8rem",
  SMALL = "0.9rem",
  NORMAL = "1rem",
  MEDIUM = "1.2rem",
  LARGE = "1.5rem",
  XLARGE = "2rem",
  XXLARGE = "3rem",
  H1 = "5em",
  H2 = "4em",
  H3 = "3em",
  H4 = "2.5em",
  H5 = "2em",
  H6 = "1.5em",
  H7 = "1.2em",
}

export enum PushNotificationType {
  PROFILE_OPEN = "PROFILE_OPEN",
  DASHBOARD_OPEN = "DASHBOARD_OPEN",
  MAP_OPEN = "MAP_OPEN",
  IMPORTANT_OPEN = "IMPORTANT_OPEN",
  ADV_OPEN = "ADV_OPEN",
  ADV_EDIT = "ADV_EDIT",
  DASHBOARD_FILTERED = "DASHBOARD_FILTERED",
  MAP_FILTERED = "MAP_FILTERED",
  EXTERNAL_OPEN_URL = "EXTERNAL_OPEN_URL",
}

export enum AdvertisementType {
  APARTMENT = "APARTMENT",
  HOUSE = "HOUSE",
  ROOM = "ROOM",
  OTHER = "OTHER",
  LAND = "LAND",
  LIFESTYLE = "LIFESTYLE",
  COMMERCIAL = "COMMERCIAL",
}

export enum MediaType {
  PHOTO = "PHOTO",
  PDF = "PDF",
  VIDEO = "VIDEO",
  PROFILE_PHOTO = "PROFILE_PHOTO",
  CONTRACT = "CONTRACT",
}

export enum BathroomTypes {
  PRIVATE = "PRIVATE",
  SHARED = "SHARED",
}

export enum AdvertisementStates {
  SAVED = "SAVED",
  PUBLISHED = "PUBLISHED",
  DELETED = "DELETED",
  CONTRACTING = "CONTRACTING",
  RENTED = "RENTED",
}

export enum PriceType {
  PRICE = "PRICE",
  PRICE_GUIDE = "PRICE_GUIDE",
  PRICE_UPON_REQUEST = "PRICE_UPON_REQUEST",
  AUCTION = "AUCTION",
}

export enum VideoType {
  VIDEO = "VIDEO",
  HOW_TO_VIDEOS_PLAYLIST = "HOW_TO_VIDEOS_PLAYLIST",
}

export enum AppResource {
  PushNotification = "push-notification",
  ChatMessage = "msg",
  Chat = "chat",
  AdvertisementWithChat = "adv/with-chats",
  ContractOffers = "contract/offers",
  AdvertisementExternal = "advertisement-external",
  Video = "video",
  Office = "office",
  User = "user",
  Advertisement = "advertisement",
}
