import React from "react";
import reducers from "./reducers";
import Store, { defaultState } from "./ResourceModalDialogStore";

type Props = {
  children: JSX.Element | JSX.Element[];
};

const ResourceModalDialogProvider = ({ children }: Props) => {
  const [state, dispatch]: any = React.useReducer(reducers, defaultState);

  return <Store.Provider value={[state, dispatch]}>{children}</Store.Provider>;
};

export default ResourceModalDialogProvider;
