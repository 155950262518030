import { ReactNode } from "react";
import styled from "styled-components";
import MdClose from "@mui/icons-material/Close";

type DialogHeaderProps = {
  className?: string;
  children: ReactNode;
  onClose?: () => void;
};

const IconButton = styled.div(({ theme }) => ({
  padding: theme.spacing(1),
  borderRadius: "90%",
  backgroundColor: theme.palette.primary.main,
  width: 40,
  height: 40,
  cursor: "pointer",
  transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
  "&:hover": {
    backgroundColor: theme.palette.primary.light,
  },
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
}));

const DialogHeaderOuter = styled.div(({ theme }) => ({
  height: 50,
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  fontSize: "1.2em",
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const DialogHeader = ({ children, onClose }: DialogHeaderProps) => {
  return (
    <DialogHeaderOuter>
      <span>{children}</span>
      <span>
        <IconButton onClick={onClose}>
          <MdClose />
        </IconButton>
      </span>
    </DialogHeaderOuter>
  );
};

export default DialogHeader;
