import { ResourceModalDialogState } from "./ResourceModalDialogStore";

export const SET_RESOURCE_MODAL_DIALOG_STATE = "SET_RESOURCE_MODAL_DIALOG_STATE";

export const setModalDialogActionState = (
  state: Partial<ResourceModalDialogState>
) => ({
  type: SET_RESOURCE_MODAL_DIALOG_STATE,
  state,
});
