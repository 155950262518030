import { Datagrid as RaDatagrid } from "react-admin";
import { styled } from "@mui/material";

const CustomDataGrid = styled(RaDatagrid)(({ theme }) => ({
  "& td": {
    paddingTop: 0,
    paddingBottom: 0,
  },
  "& th": {
    position: "static",
  },
  "& .MuiTableCell-root.MuiTableCell-paddingCheckbox>*": {
    padding: 8,
  },
  "& .RaDatagrid-headerCell": {
    top: "-24px !important",
  }
}));

const Datagrid = ({ children, ...props }) => {
  return (
    <CustomDataGrid size="small" {...props}>
      {children}
    </CustomDataGrid>
  );
};

export default Datagrid;
