import {
  Button,
  useTranslate,
  useUnselectAll,
  useListContext,
} from "react-admin";
import { Fragment, useEffect, useMemo, useState } from "react";
import { Dialog, DialogTitle, IconButton, Typography } from "@mui/material";
import { CloseOutlined, MessageOutlined } from "@mui/icons-material";
import { styled } from "@mui/material";
import NotificationForm from "./NotificationForm";

const DialogTitleWraper = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  alignItems: "center",
  justifyContent: "space-between",
}));

const extractUsersFromRows = (selectedIds, records) => {
  const selectedRows = Object.entries(records)
    .map(([row, user]: any) => user)
    .filter((x) => selectedIds.includes(x.id));

  const mappedUsers = selectedRows.reduce((prev, next) => {
    if (next?.owner?.email) {
      return {
        ...prev,
        [next.owner?.email]: next.owner?.uid,
      };
    } else {
      return {
        ...prev,
        [next.email]: next.uid,
      };
    }
  }, {});

  return mappedUsers;
};

const BulkPushNotificationButton = ({ selectedIds, resource, setSelectedIds }) => {
  const t = useTranslate();
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const { page, perPage, data: records } = useListContext();
  const unselect = useUnselectAll(resource);

  /* Unselect when datagrid change page */
  useEffect(() => {
    unselect();
  }, [page, perPage, resource, unselect]);

  const mappedUsers = useMemo(
    () => extractUsersFromRows(selectedIds, records),
    [records, selectedIds]
  );

  useEffect(() => {
    // Send the selectedIds to the parent component
    setSelectedIds(selectedIds)

}, [selectedIds, setSelectedIds]);

  return (
    <Fragment>
      <Button
        variant="contained"
        startIcon={<MessageOutlined />}
        color="secondary"
        label="pushNotification.sendPushNotification"
        onClick={handleClick}
      />
      <Dialog onClose={handleDialogClose} fullWidth open={open}>
        <DialogTitle>
          <DialogTitleWraper>
            <span>
              <Typography variant="h6">
                {t("pushNotification.dialog.title")}
              </Typography>
              <Typography>{t("pushNotification.dialog.subtitle")}</Typography>
            </span>
            <IconButton onClick={handleDialogClose} aria-label="close">
              <CloseOutlined />
            </IconButton>
          </DialogTitleWraper>
        </DialogTitle>
        <NotificationForm
          onClose={handleDialogClose}
          onSuccess={handleDialogClose}
          users={mappedUsers}
        />
      </Dialog>
    </Fragment>
  );
};

export default BulkPushNotificationButton;
