import EditIcon from "@mui/icons-material/Edit";
import ShowIcon from "@mui/icons-material/Visibility";
import { IconButton } from "@mui/material";
import { useRecordContext, useRedirect } from "react-admin";

const IconEditButton = () => {
  const record = useRecordContext();
  const redirectTo = useRedirect();
  return (
    <IconButton
      // component={Link}
      size="small"
      // to={`${basePath}/${record?.id}`}
      onClick={(e) => {
        redirectTo(`${record?.id}`);
        e.stopPropagation();
      }}
    >
      <EditIcon />
    </IconButton>
  );
};

const IconShowButton = () => {
  const record = useRecordContext();
  const redirectTo = useRedirect();
  return (
    <IconButton
      size="small"
      // component={Link}
      // to={`${basePath}/${record.id}/show`}
      onClick={(e) => {
        redirectTo(`${record?.id}/show`);
        e.stopPropagation();
      }}
    >
      <ShowIcon />
    </IconButton>
  );
};

export { IconShowButton, IconEditButton };
