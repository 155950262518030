import useFileUrl from "../../hooks/useFileUrl";
import { Loading } from "ra-ui-materialui";
import ReactPlayer from "react-player";

type VideoProps = {
  src?: string;
  className?: string;
};

const FirebaseVideo = ({ src, ...rest }: VideoProps) => {
  const { url, loading, error } = useFileUrl(src);
  if (loading) return <Loading />;
  if (error) return null;
  return <ReactPlayer controls={true} width="100%" height="100%" url={url} />;
};

export default FirebaseVideo;
