import { RefreshOutlined as MdRefresh } from "@mui/icons-material";
import styled from "styled-components";
import useText from "../_hooks/useText";
import { ThemeColor } from "../../../../types/enums";
import Button from "../../../common/Button";

const FetchButtonBase = styled(Button)(({ theme }) => ({
  margin: "auto",
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

type FetchButtonProps = {
  onClick?: () => void;
};

const FetchButton = function ({ onClick }: FetchButtonProps) {
  const t = useText();
  return (
    <FetchButtonBase
      onClick={onClick}
      color={ThemeColor.SECONDARY}
      rightIcon={<MdRefresh />}
    >
      {t("fetch.more")}
    </FetchButtonBase>
  );
};

export default FetchButton;
