import { List, ListProps, Pagination } from "ra-ui-materialui";
import ListActions from "./ListActions";

const CustomPagination = (props) => (
  <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />
);

const CustomList = (
  props: ListProps & { children: JSX.Element; onCreateClick?: any }
) => {
  return (
    <List
      perPage={50}
      pagination={<CustomPagination />}
      actions={<ListActions />}
      {...props}
    >
      {props.children}
    </List>
  );
};

export default CustomList;
