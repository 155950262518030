import {
  TextField,
  FunctionField,
  DateField,
  BooleanField,
  TextInput,
  SimpleList,
  useRecordContext,
  SelectInput,
} from "react-admin";
import AdvertisementStateField from "../../modules/advertisement/StateField";
import Datagrid from "../../components/ra/Datagrid";
import List from "../../components/ra/List";
import BulkPushNotificationButton from "../../modules/push-notification/BulkPushNotificationButton";
import { renderAddress } from "../../utils/utils";
import LocationButton from "../../components/ra/buttons/LocationIconButton";
import { useMediaQuery } from "@mui/material";
import { IconEditButton } from "../../components/ra/buttons/ListIconButtons";
import { useState } from "react";

const AdvertisementFilter = [
  <TextInput label="advertisement.owner" source="email" alwaysOn />,
  <SelectInput
    alwaysOn
    label="advertisement.state"
    choices={[
      { id: "SAVED", name: "SAVED" },
      { id: "PUBLISHED", name: "PUBLISHED" },
      { id: "DELETED", name: "DELETED" },
      { id: "CONTRACTING", name: "CONTRACTING" },
      { id: "RENTED", name: "RENTED" },
    ]}
    source="state"
    resource="advertisement"
    fullWidth
  />,
  // <SelectInput
  //   alwaysOn
  //   label="advertisement.advertisementType"
  //   choices={ADVERTISEMENT_TYPE}
  //   source="advertisement_type"
  //   resource="advertisement"
  //   fullWidth
  // />,
];

const BulkActionButtons = (props) => (
  <>
    <BulkPushNotificationButton {...props} />
  </>
);

const renderSecondaryText = (record) => {
  const address: string[] = [];
  record?.owner?.email && address.push(record?.owner?.email);
  return address.join(", ");
};

const ColoredBooleanField = (props) => {
  const record = useRecordContext();
  return (
    <BooleanField
      sx={{ color: record[props.source] === true ? "#00E400" : "#000000de" }}
      {...props}
    />
  );
};

const postRowSx = (record, selectedIDs) => ({
  backgroundColor: selectedIDs.includes(record.id) ? "#0000000a" : "white",
});

const AdvertisementList = ({ ...props }) => {
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));
  const [selectedIDs, setSelectedIds] = useState([]);
  return (
    <List
      sort={{ field: "id", order: "ASC" }}
      filters={AdvertisementFilter}
      title={"advertisement.listTitle"}
      bulkActionButtons={<BulkActionButtons setSelectedIds={setSelectedIds} />}
      {...props}
    >
      {isSmall ? (
        <SimpleList
          primaryText={renderAddress}
          secondaryText={renderSecondaryText}
        />
      ) : (
        <Datagrid
          rowClick="show"
          rowSx={(record) => postRowSx(record, selectedIDs)}
        >
          <IconEditButton />

          <TextField label="general.id" source="id" />
          <TextField
            label="advertisement.owner"
            sortable={false}
            source="owner.email"
          />
          <ColoredBooleanField label="advertisement.rent" source="rent" />

          <TextField
            label="advertisement.advertisementType"
            source="advertisementType"
          />

          <AdvertisementStateField sortBy="state" label="advertisement.state" />

          <TextField
            label="advertisement.priceType"
            sortable={false}
            source="priceType"
          />

          <FunctionField
            sortBy="price"
            sortable={false}
            label="advertisement.price"
            render={(record) =>
              record?.price && (
                <span>{record?.price.toLocaleString("sk")} €</span>
              )
            }
          />
          <FunctionField
            label="advertisement.address"
            sortable={false}
            render={renderAddress}
          />
          <DateField
            label="general.created"
            source="createdAt"
            options={{
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
            }}
          />
          <DateField
            label="general.updated"
            source="updatedAt"
            options={{
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
            }}
          />

          <LocationButton />
        </Datagrid>
      )}
    </List>
  );
};

export default AdvertisementList;
