import { Edit } from "ra-ui-materialui";
import { EditActions } from "../../components/ra/Actions";
import OfficeForm from "../../modules/office/OfficeForm";

const OfficeEdit = ({ ...props }) => {
  return (
    <Edit actions={<EditActions />} title={"office.editTitle"} {...props}>
      <OfficeForm />
    </Edit>
  );
};

export default OfficeEdit;
