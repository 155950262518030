import { useCallback, useEffect, useState } from "react";
import {
  GetListResult,
  useDataProvider,
  useInput,
  useTranslate,
} from "react-admin";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

const UserAutocompleteInput = (props) => {
  const {
    resource,
    id = "",
    filterAttr = "name",
    searchColumn = "q",
    label,
    data,
    setData,
    setInputValue

  } = props;
  const {
    field: {
      // name,
      onChange,
      value: inputValue,
    },
  } = useInput(props);
  const [filter, setFilter] = useState("");
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  useEffect(() => {setInputValue(inputValue)}, [inputValue, setInputValue]);
  useEffect(() => {
    if (filter.length < 2) {
      return;
    }
    dataProvider
      .getList(resource, {
        pagination: {
          page: 0,
          perPage: 1000,
        },
        sort: {
          field: filterAttr,
          order: "ASC",
        },
        filter: {
          [searchColumn]: filter,
        },
      })
      .then((records: GetListResult) => {
        setData(records.data);
      });
  }, [filter, dataProvider, filterAttr, resource, searchColumn, setData]);

  const handleChange = useCallback(
    (e, val) => {
      onChange(val || e);
    },
    [onChange]
  );

  return (
    <Autocomplete
      fullWidth
      value={inputValue}
      id={`autocomplete-input-${id}`}
      onChange={handleChange}
      options={data.map((x) => x.email)}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            label={translate(label)}
            id={`autocomplete-input-field-${id}`}
            onChange={(e) => setFilter(e.target.value)}
            margin="normal"
            variant="filled"
          />
        );
      }}
    />
  );
};

export default UserAutocompleteInput;
