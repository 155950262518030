import styled from "styled-components";

const Display = styled.div<{ delay: string }>(({ theme, delay }) => ({
  animation: `${delay} fadeIn`,
  animationFillMode: "forwards",
  visibility: "hidden",
  width: "100%",
  "@keyframes fadeIn": {
    "99%": {
      visibility: "hidden",
    },
    "100%": {
      visibility: "visible",
    },
  },
}));

export default Display;
