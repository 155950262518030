import { useMemo, useCallback } from "react";
import SimpleMapComponent from "../../widgets/map/MapComponent";
import { styled, Box, Typography } from "@mui/material";
import { useController } from "react-hook-form";
import { maxValue, minValue, NumberInput, required } from "react-admin";
import { useTranslate } from "ra-core";

const Base = styled("div")(({ theme }) => ({
  height: 400,
  position: "relative",
  width: "100%",
  marginBottom: theme.spacing(6),
}));

const CoordinatesInput = (props) => {
  const longitudeInput = useController({ name: "coordinate.longitude" });
  const latitudeInput = useController({ name: "coordinate.latitude" });
  const {coordinatesError} = props;
  const t = useTranslate();

  const handleChange = useCallback(
    (latLng) => {
      latitudeInput.field.onChange(latLng[0]);
      longitudeInput.field.onChange(latLng[1]);
    },
    [longitudeInput.field, latitudeInput.field]
  );

  const value = useMemo(() => {
    if (!latitudeInput?.field?.value || !longitudeInput?.field?.value) {
      return null;
    }

    // check lon, lat values
    if (
      longitudeInput?.field?.value > 180 ||
      longitudeInput?.field?.value < -180
    )
      return;

    if (latitudeInput?.field?.value > 90 || latitudeInput?.field?.value < -90)
      return;

    const coordinates = {
          longitude: longitudeInput?.field?.value,
          latitude: latitudeInput?.field?.value,
    }
      
    return coordinates;
  }, [latitudeInput?.field?.value, longitudeInput?.field?.value]);

  return (
    <Base>
      <Box mt={1} mb={2} display="flex">
        <Box flex="1" mr={2}>
          <NumberInput
            variant="filled"
            validate={[required(), maxValue(180), minValue(-180)]}
            source="coordinate.longitude"
            label="longitude"
            fullWidth
            size="small"
          />
                    {coordinatesError ? <Box sx={{backgroundColor: 'yellow'}}><Typography align="left" color='#d32f2f' >{t("coordinates.error")}</Typography></Box> : null}
        </Box>
        <Box flex="1">
          <NumberInput
            variant="filled"
            validate={[required(), maxValue(90), minValue(-90)]}
            source="coordinate.latitude"
            label="latitude"
            fullWidth
            size="small"
          />
        </Box>
      </Box>
      <SimpleMapComponent coordinatesInputEvent={handleChange} coordinates={value}/>
    </Base>
  );
};

export default CoordinatesInput;
