import { ReactElement } from "react";
import styled from "styled-components";
import { ButtonType, ButtonVariant, ThemeColor } from "../../types/enums";

type ButtonBaseProps = {
  variant: ButtonVariant;
  color: ThemeColor;
  height: number | "auto";
};

const ButtonBase = styled.button<ButtonBaseProps>(
  ({ theme, variant, height, color }) => ({
    borderWidth: 0,
    height,
    color: theme.palette.common.white,
    backgroundColor: theme.palette[color].main,
    justifyContent: "space-between",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette[color].dark,
      transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
    },
    padding: "0rem 0.75rem",
    borderRadius: 0,
    fontWeight: 400,
    textAlign: "center",
    verticalAlign: "middle",
    display: "flex",
    alignItems: "center",
  })
);

const Text = styled.span(({ theme }) => ({}));

const IconContainer = styled.div(({ theme }) => ({
  fontSize: "1.2rem",
  marginLeft: 4,
}));

type ButtonProps = {
  rightIcon?: ReactElement;
  children: string;
  type?: ButtonType;
  variant?: ButtonVariant;
  color?: ThemeColor;
  height?: number | "auto";
  className?: string;
  onClick?: () => void;
};

function Button({
  rightIcon,
  children,
  className,
  onClick = () => {},
  color = ThemeColor.PRIMARY,
  variant = ButtonVariant.CONTAINED,
  type = ButtonType.BUTTON,
  height = "auto",
}: ButtonProps) {
  return (
    <ButtonBase
      onClick={onClick}
      color={color}
      height={height}
      className={className}
      type={type}
      variant={variant}
    >
      <Text>{children}</Text>
      <IconContainer>{rightIcon}</IconContainer>
    </ButtonBase>
  );
}

export default Button;
