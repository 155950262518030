import { Show } from "ra-ui-materialui";
import { ShowActions } from "../../components/ra/Actions";
import UserDetail from "../../modules/account/UserDetail";

const UserShow = ({ ...props }) => {
  return (
    <Show actions={<ShowActions />} {...props} title="user.dialog.dialog.title">
      <UserDetail {...props} />
    </Show>
  );
};

export default UserShow;
