import { useCallback } from "react";
import { authProvider } from "./auth.provider";
import useConfig from "../hooks/useConfig";
import fetchJson from "./fetchJson";

const useHttpClient = () => {

  const { httpHeaders: {call, version} } = useConfig()

  return useCallback(
    async (
      url: string,
      options: any = {},
      additional?: { raFetch: boolean }
    ) => {
      const { raFetch } = additional || { raFetch: true };
      const token = await authProvider.getJWTToken();

      options.headers = new Headers({
        Accept: "application/json",
        call: call,
        version: version,
        ...options.headers,
      });

      options.headers.set("Authorization", `${token}`);
      const data = raFetch
        ? fetchJson(url, options)
        : fetch(url, options);
      return data;
    },
    [call, version]
  );
};

export default useHttpClient;
