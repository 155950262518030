import { useRef, useState } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import { Chat } from "../../../../types/model/model";
import ChatTabItem from "./ChatTabItem";
import OffsetList from "./OffsetList";

const LIMIT = 500;
const OFFSET = 10;

const Base = styled.ul(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  overflow: "auto",
  paddingTop: 4,
  paddingLeft: theme.spacing(1),
  height: 380,
  listStyle: "none",
  marginBottom: 0,
  width: "100%",
  marginTop: 0,
  boxSizing: "border-box",
}));

type ChatListProps = {
  value?: number;
  advId: number;
  onScrollBottomChatListPanel?: () => void;
  onItemClick: (chat: Chat) => void;
};

const ChatList = ({
  advId,
  onItemClick,
  value,
  onScrollBottomChatListPanel,
}: ChatListProps) => {
  const chatListContainerRef = useRef<HTMLUListElement>(null);
  const [limit] = useState(LIMIT);
  const [offset, setOffset] = useState(0);

  const handleFetchClick = useCallback(() => {
    setOffset((offset) => offset + OFFSET);
  }, []);

  const handleSuccessFetch = useCallback(
    ({ data }) => {
      if (offset === 0) {
        onItemClick(data[0]);
      }
    },
    [offset, onItemClick]
  );

  const handleScroll = useCallback(
    (e) => {
      if (
        typeof onScrollBottomChatListPanel === "function" &&
        e.target.scrollTop === e.target.clientHeight
      ) {
        onScrollBottomChatListPanel();
      }
    },
    [onScrollBottomChatListPanel]
  );

  useEffect(() => {
    const elem = chatListContainerRef.current;
    elem?.addEventListener("scroll", handleScroll);
    return () => {
      elem?.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <Base ref={chatListContainerRef}>
      <OffsetList
        resource="chat"
        payload={{ id: advId }}
        onSuccess={handleSuccessFetch}
        onFetchClick={handleFetchClick}
        offset={offset}
        limit={limit}
      >
        {({ data }) => {
          return data.map((chat) => (
            <ChatTabItem
              key={chat.chatId}
              selected={value === chat.chatId}
              chat={chat}
              onClick={(ch) => onItemClick(ch)}
            />
          ));
        }}
      </OffsetList>
    </Base>
  );
};

export default ChatList;
