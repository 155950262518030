import { cloneElement, useMemo, useContext } from "react";
import {
  sanitizeListRestProps,
  useListContext,
  useResourceContext,
  useResourceDefinition,
} from "ra-core";
import {
  CreateButton,
  FilterButton,
  FilterContext,
  ListActionsProps,
  TopToolbar,
} from "ra-ui-materialui";
import { styled } from "@mui/material";
import ExportButton from "./buttons/ExportButton";

const CustomTopToolbar = styled(TopToolbar)(({ theme }) => ({
  paddingRight: "0 !important",
  paddingLeft: "16px",
  display: "flex",
  flex: "1 1",
  justifyContent: "flex-start",
  width: "100%",
  marginBottom: "0 !important",
}));

const CustomFilterButton = styled(FilterButton)(({ theme }) => ({
  marginRight: theme.spacing(1),
  marginLeft: 0,
}));

const CustomExportButton = styled(ExportButton)(({ theme }) => ({
  marginRight: theme.spacing(1),
  marginLeft: 0,
  ":last-child": {
    marginRight: 0,
  },
}));

const CustomCreateButton = styled(CreateButton)(({ theme }) => ({
  marginRight: theme.spacing(1),
  marginLeft: 0,
}));

interface CustomListActionsProps extends ListActionsProps {
  exporter?: any;
  filters?: any;
  perPage?: number;
  page?: number;
}

const CustomListActions = (props: CustomListActionsProps) => {
  const {
    className,
    exporter,
    filters: filtersProp,
    hasCreate: create,
    ...rest
  } = props;
  const {
    sort,
    perPage,
    page,
    displayedFilters,
    filterValues,
    selectedIds = [],
    showFilter,
    total,
  } = useListContext(props);
  const resource = useResourceContext(rest);
  const { hasCreate } = useResourceDefinition(rest);
  const filters = useContext(FilterContext) || filtersProp;
  const hasAlwaysOn = filters?.find((x: any) => x?.props?.alwaysOn);
  return useMemo(
    () => (
      <CustomTopToolbar {...sanitizeListRestProps(rest)}>
        {filtersProp
          ? cloneElement(filtersProp, {
              resource,
              showFilter,
              displayedFilters,
              filterValues,
              context: "button",
            })
          : filters && !hasAlwaysOn && <CustomFilterButton />}
        {hasCreate ? (
          <CustomCreateButton variant="contained" color="secondary" />
        ) : null}
        {exporter !== false && (
          <CustomExportButton
            page={page}
            perPage={perPage}
            variant="contained"
            color="secondary"
            disabled={total === 0}
            resource={resource}
            sort={sort}
            filterValues={filterValues}
          />
        )}
      </CustomTopToolbar>
    ),
    [resource, displayedFilters, filterValues, selectedIds, filters, total] // eslint-disable-line react-hooks/exhaustive-deps
  );
};

export default CustomListActions;
