import { useCallback } from "react";
import { useDataProvider, useNotify, useRefresh } from "react-admin";
import { useMutation } from "react-query";

const useOfficeProvider = () => {
  const refresh = useRefresh();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const { mutate: addUserToOffice } = useMutation(
    ["office", "addUserToOffice"],
    ({ userId, officeId }: any) =>
      dataProvider.addUserToOffice({ userId, officeId })
  );

  const { mutate: removeUserFromOffice } = useMutation(
    ["office", "removeUserFromOffice"],
    ({ userId, officeId }: any) =>
      dataProvider.removeUserFromOffice({ userId, officeId })
  );

  const handleAddUserToOffice = useCallback(
    ({ userId, officeId }) => {
      addUserToOffice(
        { officeId, userId },
        {
          onSuccess: () => {
            refresh();
          },
          onError: (err) => {
            console.error(err);
            notify("error.failedFetch", { type: "error" });
          },
        }
      );
    },
    [addUserToOffice, notify, refresh]
  );

  const handleRemoveUserFromOffice = useCallback(
    ({ userId, officeId }) => {
      removeUserFromOffice(
        { officeId, userId },
        {
          onSuccess: () => {
            refresh();
          },
          onError: (err) => {
            console.error(err);
            notify("error.failedFetch", { type: "error" });
          },
        }
      );
    },
    [notify, refresh, removeUserFromOffice]
  );

  return {
    addUserToOffice: handleAddUserToOffice,
    removeUserFromOffice: handleRemoveUserFromOffice,
  };
};

export default useOfficeProvider;
