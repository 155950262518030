import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import MessageInput from "./MessageInput";
import Message from "./Message";
import styled from "styled-components";
import Panel from "./Panel";
import useConfig from "../../../../hooks/useConfig";
import { useDataProvider } from "react-admin";
import { useQuery } from "react-query";
import { Advertisement } from "../../../../types/model/model";

const OFFSET_INCREMENT = 10;

type MessagePanelProps = {
  chatId: number;
  activeAdvertisement: Advertisement;
  offset: number;
  setOffset: (offset: any) => any;
};

const ChatMessages = styled.div(({ theme }) => ({
  overflow: "auto",
  backgroundColor: "#f1f4ff",
  height: 330,
  position: "relative",
}));

const Divider = styled.div(({ theme }) => ({
  height: theme.spacing(1),
}));

function MessagePanel({
  chatId,
  offset,
  setOffset,
  activeAdvertisement,
}: MessagePanelProps) {
  const endRef = useRef<HTMLDivElement>(null);
  const [items, setItems] = useState<any>([]);
  const config = useConfig();
  const [disableAutoscroll, setDisableAutoscroll] = useState(true);
  const [limit] = useState(10);
  const [loading, setLoading] = useState(true);
  const [sendingMessage, setSendingMessage] = useState(false);

  const scrollToBottom = useCallback(() => {
    endRef.current?.scrollIntoView();
  }, []);

  useEffect(() => {
    setDisableAutoscroll(false);
    setItems([]);
  }, [chatId]);

  const dataProvider = useDataProvider();

  const {
    isLoading: fetching,
    error,
    data = { data: [] },
    refetch,
  } = useQuery(
    [
      `msg`,
      "getChatMessages",
      {
        chatId,
        offset,
        limit,
      },
    ],
    () =>
      dataProvider.getChatMessages({
        chatId,
        offset,
        limit,
      })
  );

  useEffect(() => {
    const callback = () => {
      refetch();
    };
    const timer = setInterval(callback, config.chat.messagesRefetchInterval);
    return () => {
      clearInterval(timer);
    };
  }, [refetch, config.chat.messagesRefetchInterval]);

  useEffect(() => {
    if ((!fetching && !disableAutoscroll) || sendingMessage)
      setTimeout(scrollToBottom, 100);
    // eslint-disable-next-line
  }, [fetching, scrollToBottom, sendingMessage]);

  useEffect(() => {
    if (!data?.data) return;

    setItems((state) => {
      const newMessages = data?.data?.filter(
        (x) => !state.find((i) => i.id === x.id)
      );
      const reversed = newMessages.reverse();
      if (reversed.length === 1) return [...state, ...reversed];
      // new message
      else return [...reversed, ...state];
    });
    setLoading(false);
  }, [data?.data]);

  const handleFetchClick = useCallback(() => {
    setDisableAutoscroll(true);
    setOffset((offset) => offset + OFFSET_INCREMENT);
  }, [setOffset]);

  const handleOnSendMessage = useCallback(() => {
    setDisableAutoscroll(false);
    if (offset !== 0) setOffset(0);
    else refetch();
  }, [refetch, setOffset, offset]);

  return (
    <Fragment>
      <ChatMessages>
        <Panel
          delay="0.25s"
          fetchMore={true}
          loading={loading || sendingMessage}
          error={error}
          loadingPosition={items.length !== 0 ? "bottom" : "center"}
          fetchPosition="top"
          onFetchClick={handleFetchClick}
        >
          {items.map((message) => (
            <Message
              activeAdvertisement={activeAdvertisement}
              key={message.id}
              message={message}
            />
          ))}
        </Panel>
        <Divider />
        <div ref={endRef} />
      </ChatMessages>
      <MessageInput
        advOwnerId={activeAdvertisement.owner?.uid}
        onLoading={setSendingMessage}
        onSendMessage={handleOnSendMessage}
        chatId={chatId}
        allowUpload={false}
      />
    </Fragment>
  );
}

export default MessagePanel;
