import React, { ReactElement } from "react";
import styled from "styled-components";

const Span = styled.span({
  marginLeft: 8,
});

type DialogActionsProps = {
  className?: string;
  children: ReactElement | ReactElement[];
};

const DialogActionsBase = ({ className, children }: DialogActionsProps) => {
  return (
    <div className={className}>
      {React.Children.map(children, (child: ReactElement, key: number) => (
        <Span key={key}>{child}</Span>
      ))}
    </div>
  );
};

const DialogActions = styled(DialogActionsBase)(({ theme }) => ({
  height: 50,
  display: "flex",
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  alignItems: "center",
  justifyContent: "flex-end",
  background: theme.palette.grey[100],
}));

export default DialogActions;
