import FetchButton from "./FetchButton";
import Loader from "../../../common/Loader";
import Display from "../../../common/Display";
import { useCallback } from "react";

type Props = {
  delay?: string;
  fetchMore?: boolean;
  fetchPosition?: "top" | "bottom";
  loadingPosition?: "center" | "top" | "bottom";
  onFetchClick?: () => void;
  children: JSX.Element | JSX.Element[] | null;
  loading?: boolean;
  error?: any;
};

const Panel = ({
  delay = "0s",
  fetchPosition,
  fetchMore = false,
  loadingPosition = "center",
  loading = false,
  error,
  onFetchClick,
  children,
}: Props) => {
  const displayFetchMore = useCallback(
    (position: string) => {
      return fetchPosition === position && fetchMore;
    },
    [fetchMore, fetchPosition]
  );

  if (error) return null;

  if (loading && loadingPosition === "center") return <Loader />;
  return (
    <Display delay={delay}>
      {displayFetchMore("top") && <FetchButton onClick={onFetchClick} />}
      {loading && loadingPosition === "top" && <Loader />}
      {children}
      {displayFetchMore("bottom") && <FetchButton onClick={onFetchClick} />}
      {loading && loadingPosition === "bottom" && <Loader />}
    </Display>
  );
};

export default Panel;
