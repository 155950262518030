import skMessages from "../assets/i18n/sk";
import enMessages from "../assets/i18n/en";
import polyglotI18nProvider from "ra-i18n-polyglot";
import englishMessages from "ra-language-english";
import slovakMessages from "ra-language-slovak";
import { mergeTranslations } from "react-admin";

const messages: any = {
  sk: mergeTranslations(slovakMessages, skMessages),
  en: mergeTranslations(englishMessages, enMessages),
};

const i18nProvider = polyglotI18nProvider((locale) => messages[locale], "en");

export default i18nProvider;
