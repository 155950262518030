import { Create } from "ra-ui-materialui";
import { CreateActions } from "../../components/ra/Actions";
import AdvertisementForm from "../../modules/advertisement/AdvertisementForm";

const AdvertisementCreate = ({ ...props }) => (
  <Create
    actions={<CreateActions />}
    title={"advertisement.createTitle"}
    {...props}
  >
    <AdvertisementForm />
  </Create>
);

export default AdvertisementCreate;
