import AdvertisementTabItem from "./AdvertisementTabItem";
import { useCallback, useState } from "react";
import OffsetList from "./OffsetList";
import { Advertisement } from "../../../../types/model/model";

const LIMIT = 500;
const OFFSET = 10;

type Props = {
  value?: number;
  onItemClick: (data: Advertisement) => void;
  onFetchClick?: () => void;
};

function AdvertisementWithChatList({ value, onItemClick }: Props) {
  const [limit] = useState(LIMIT);
  const [offset, setOffset] = useState(0);

  const handleFetchClick = useCallback(() => {
    setOffset((offset) => offset + OFFSET);
  }, []);

  const handleSuccessFetch = useCallback(
    ({ data }) => {
      if (offset === 0) {
        onItemClick(data[0]);
      }
    },
    [offset, onItemClick]
  );

  return (
    <OffsetList
      resource="adv/with-chats"
      onSuccess={handleSuccessFetch}
      onFetchClick={handleFetchClick}
      offset={offset}
      limit={limit}
    >
      {({ data }) => {
        return data.map((item) => (
          <AdvertisementTabItem
            key={item.id}
            advertisement={item}
            selected={value === item.id}
            onClick={(a) => onItemClick(a)}
          />
        ));
      }}
    </OffsetList>
  );
}

export default AdvertisementWithChatList;
