import { useRecordContext, useResourceDefinition } from "ra-core";
import { styled } from "@mui/material";
import {
  CreateActionsProps,
  EditActionsProps,
  EditButton,
  ListButton,
  ShowActionsProps,
  ShowButton,
} from "ra-ui-materialui";
import { CustomTopToolbar } from "./Toolbars";

const CustomShowButton = styled(ShowButton)(({ theme }) => ({
  marginLeft: 0,
  marginRight: theme.spacing(1),
}));

const CustomListButton = styled(ListButton)(({ theme }) => ({
  marginLeft: 0,
  marginRight: theme.spacing(1),
}));

const CustomEditButton = styled(EditButton)(({ theme }) => ({
  marginLeft: 0,
  marginRight: theme.spacing(1),
}));

const EditActions = ({ data, ...rest }: EditActionsProps) => {
  const { hasShow } = useResourceDefinition(rest);
  return (
    <CustomTopToolbar>
      {hasShow && (
        <CustomShowButton color="secondary" variant="contained" record={data} />
      )}
      <CustomListButton color="secondary" variant="contained" />
    </CustomTopToolbar>
  );
};

const ShowActions = ({ ...rest }: ShowActionsProps) => {
  const { hasEdit } = useResourceDefinition();
  const data = useRecordContext();
  return (
    <CustomTopToolbar>
      {hasEdit && (
        <CustomEditButton color="secondary" variant="contained" record={data} />
      )}
      <CustomListButton color="secondary" variant="contained" />
    </CustomTopToolbar>
  );
};

const CreateActions = ({ ...rest }: CreateActionsProps) => {
  return (
    <CustomTopToolbar>
      <ListButton color="secondary" variant="contained" />
    </CustomTopToolbar>
  );
};

export { CreateActions, EditActions, ShowActions };
