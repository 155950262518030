import { useCallback } from "react";
import {
  Button,
  Form,
  SaveButton,
  TextInput,
  required,
  useTranslate,
  FormDataConsumer,
  SelectInput,
  BooleanInput,
} from "react-admin";
import { Box, DialogContent, Grid } from "@mui/material";
import { SendOutlined } from "@mui/icons-material";
import { CancelOutlined as IconCancel } from "@mui/icons-material";
import SliderInput from "../../components/ra/inputs/SliderInput";
import {
  ADVERTISEMENT_TYPE_RB_CHOICES,
  PUSH_NOTIFICATION_TYPES,
} from "../../types/options";
import { AdvertisementType, PushNotificationType } from "../../types/enums";
import { CustomToolbar } from "../../components/ra/Toolbars";
import usePushNotification from "../../hooks/usePushNotification";
import { useFormState } from "react-hook-form";

const SubmitButton = (props) => {
  const { isDirty, isValid } = useFormState();
  return (
    <SaveButton
      variant="contained"
      icon={<SendOutlined />}
      color="secondary"
      label="pushNotification.sendPushNotification"
      disabled={!isDirty || !isValid}
      {...props}
    />
  );
};

const createPayload = (mappedUsers, values) => {
  const payload = {
    users: mappedUsers,
    ...values,
  };
  if (values.price) {
    payload.priceFrom = values.price[0];
    payload.priceTo = values.price[1];
  }
  return payload;
};

const PushNotificationForm = ({ users, onSuccess, onClose }) => {
  const t = useTranslate();
  const { sendPushNotification, loading } = usePushNotification();

  const getTranslatedPushNotifications = () => {
    const PUSH_NOTIFICATION_TYPES_FILTERED = PUSH_NOTIFICATION_TYPES.filter(item => item.availableForRB === true) ;
    return PUSH_NOTIFICATION_TYPES_FILTERED.map((x) => ({
      ...x,
      name: t(`pushNotification.types.${x.name}`),
    }));
  };

  const handleSubmit = useCallback(
    (values) => {
      const payload = createPayload(users, values);
      sendPushNotification(payload, onSuccess);
    },
    [users, onSuccess, sendPushNotification]
  );

  return (
    <Form mode="onChange" onSubmit={handleSubmit}>
      <DialogContent>
        <SelectInput
          label="general.notificationType"
          defaultValue={PushNotificationType.DASHBOARD_OPEN}
          choices={getTranslatedPushNotifications()}
          source="type"
          fullWidth
        />
        <Box display="flex">
          <Box flex={1}>
            <TextInput
              source="title"
              label={t("general.title")}
              validate={required()}
              fullWidth
            />
          </Box>
          <Box flex={1}></Box>
        </Box>
        <Box display="flex">
          <Box flex={1}>
            <TextInput
              multiline
              minRows={3}
              source="body"
              label={t("general.body")}
              fullWidth
            />
          </Box>
        </Box>
        <FormDataConsumer>
          {({ formData }) => {
            return (
              <>
                {formData.type === PushNotificationType.EXTERNAL_OPEN_URL && (
                  <TextInput
                    key="url"
                    source="url"
                    label={t("general.url")}
                    validate={required()}
                    fullWidth
                  />
                )}
                {(formData.type === PushNotificationType.ADV_EDIT ||
                  formData.type === PushNotificationType.ADV_OPEN) && (
                  <TextInput
                    key="advId"
                    source="advId"
                    validate={required()}
                    label={t("general.advId")}
                    fullWidth
                  />
                )}
                {(formData.type === PushNotificationType.DASHBOARD_FILTERED ||
                  formData.type === PushNotificationType.MAP_FILTERED) && (
                  <>
                    <Grid container spacing={2}>
                        <Grid xs={12} sm={4} item>
                          <BooleanInput
                            label="advertisement.rent"
                            source="rent"
                            fullWidth
                          />
                        </Grid>

                      <Grid xs={12} sm={8} item>
                        <SelectInput
                          isRequired
                          key="advertisementType"
                          label="general.advertisementType"
                          choices={ADVERTISEMENT_TYPE_RB_CHOICES}
                          source="advertisementType"
                          resource="adv"
                          fullWidth
                        />
                      </Grid>
                      <Grid pl={2} xs={12} pt={0} pr={2} sm={6}>
                        <SliderInput
                          key="price"
                          source="price"
                          step={100}
                          min={0}
                          initValue={[0, 10000]}
                          max={10000}
                          label={t("general.price")}
                          fullWidth
                        />
                      </Grid>
                      {formData.advertisementType !== AdvertisementType.LAND &&
                        formData.advertisementType !==
                          AdvertisementType.LIFESTYLE && (
                          <Grid pl={4} xs={12} pt={0} sm={6}>
                            <SliderInput
                              key="rooms"
                              source="rooms"
                              label={t("general.rooms")}
                              fullWidth
                              initValue={1}
                              step={1}
                              min={1}
                              max={5}
                            />
                          </Grid>
                        )}
                    </Grid>
                  </>
                )}
              </>
            );
          }}
        </FormDataConsumer>
      </DialogContent>
      <CustomToolbar>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          p={2}
        >
          <Button label="ra.action.cancel" onClick={onClose} disabled={loading}>
            <IconCancel />
          </Button>
          <SubmitButton />
        </Box>
      </CustomToolbar>
    </Form>
  );
};

export default PushNotificationForm;
