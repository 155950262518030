import { useEffect, useState } from "react";
import { useFileStorage } from "./useFileStorage";

const useFileUrl = (path) => {
  const { getUrl } = useFileStorage();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>();
  const [url, setUrl] = useState<string>();

  useEffect(() => {
    const getDownloadUrl = async () => {
      setLoading(true);
      try {
        const downloadUrl = await getUrl(path);
        setUrl(downloadUrl);
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
        return () => {};
      }
    };

    getDownloadUrl();
  }, [path, getUrl]);

  return { url, loading, error };
};

export default useFileUrl;
