import { Box, styled } from "@mui/material";

const Base = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  lineHeight: 1,
  color: theme.palette.primary.main,
  alignItems: "center",
  "& > a": {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
    display: "block",
  },
}));

type Props = {
  href: string;
  icon?: JSX.Element;
  children: string;
};

const ExternalLink = ({ href, icon, children }: Props) => {
  return (
    <Base>
      <a href={href} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
      {icon && <Box>{icon}</Box>}
    </Base>
  );
};

export default ExternalLink;
