import useFetch from "react-fetch-hook";
import useConfig from "../../hooks/useConfig";

function GetAmenities(type:string) {

  const { httpHeaders: {call, version}, URL_API } = useConfig()
  
  const result = useFetch(
    `${URL_API}/code/item/${type}`,
    {
      headers: {
        "Content-Type": "application/json",
        call: call,
        version: version,
        lang: "en"
      },
    }
  );

  return result
}

export default GetAmenities;