import {
  AdvertisementCreate,
  AdvertisementEdit,
  AdvertisementList,
  AdvertisementShow,
} from "./pages/advertisement";
import { Admin, Resource } from "react-admin";
import { UserList, UserShow, UserEdit } from "./pages/user";
import AppLayout from "./components/layout/AppLayout";
import i18nProvider from "./core/i18n.provider";
import {
  OfficeCreate,
  OfficeEdit,
  OfficeList,
  OfficeShow,
} from "./pages/office";
import Video from "./pages/video";
import useApiProvider from "./core/providers/api.provider";
import userProvider from "./core/providers/user.provider";
import officeProvider from "./core/providers/office.provider";
import videoProvider from "./core/providers/video.provider";
import advertisementProvider from "./core/providers/advertisement.provider";
import { AdvertisementExternalList } from "./pages/advertisement-external";
import chatProvider from "./core/providers/chat.provider";
import useConfig from "./hooks/useConfig";
import { AppResource } from "./types/enums";
import { notificationProvider } from "./core/providers/notification.provider";
import { authProvider } from "./core/auth.provider";

const App = () => {
  const dataProvider = useApiProvider({
    [AppResource.User]: userProvider,
    [AppResource.Office]: officeProvider,
    [AppResource.Video]: videoProvider,
    [AppResource.Advertisement]: advertisementProvider,
    [AppResource.ChatMessage]: chatProvider,
    [AppResource.Chat]: chatProvider,
    [AppResource.ContractOffers]: chatProvider,
    [AppResource.PushNotification]: notificationProvider,
  });

  const { theme } = useConfig();

  return (
    <Admin
      i18nProvider={i18nProvider}
      layout={AppLayout}
      disableTelemetry
      theme={theme}
      authProvider={authProvider}
      dataProvider={dataProvider}
    >
      <Resource
        options={{ exact: true }}
        list={AdvertisementList}
        edit={AdvertisementEdit}
        create={AdvertisementCreate}
        show={AdvertisementShow}
        name={AppResource.Advertisement}
      />
      <Resource
        list={UserList}
        show={UserShow}
        edit={UserEdit}
        name={AppResource.User}
      />
      <Resource
        list={OfficeList}
        create={OfficeCreate}
        edit={OfficeEdit}
        show={OfficeShow}
        name={AppResource.Office}
      />
      <Resource {...Video} name={AppResource.Video} />
      <Resource name={AppResource.PushNotification} />
      <Resource name={AppResource.ChatMessage} />
      <Resource name={AppResource.Chat} />
      <Resource name={AppResource.AdvertisementWithChat} />
      <Resource name={AppResource.ContractOffers} />
      <Resource
        list={AdvertisementExternalList}
        name={AppResource.AdvertisementExternal}
      />
    </Admin>
  );
};

export default App;
