import { ButtonGrey, ThemeColor } from "../types/enums";
import { User } from "../types/model/model";

const isToday = (date: Date) => {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};

const convertUTCDateTimeToLocal = ({ dateString }: { dateString: string }) => {
  try {
    return new Date(dateString + "Z");
  } catch (err) {
    throw new Error(`Cannot parse Date ${dateString}`);
  }
};

export const getUserFirstCapital = (user: Partial<User>) => {
  const name = getUserName(user);
  if (typeof name === "string" && name.length > 0) return name[0].toUpperCase();
  return "";
};

export const getButtonColor = (
  theme: any,
  color?: ThemeColor,
  hover: boolean = false
) => {
  const grey = hover ? ButtonGrey.HOVER : ButtonGrey.DEFAULT;

  if (color) {
    const paletteColor = theme.palette[color];
    return hover ? paletteColor.dark : paletteColor.main;
  }

  return theme.palette.grey[grey];
};

export const getUserName = (user: Partial<User>) => {
  if (!user.firstName || !user.lastName) {
    return user.email;
  }
  return `${user.firstName} ${user.lastName}`;
};

export const showLocaleDate = ({ dateString }: { dateString: string }) => {
  try {
    return convertUTCDateTimeToLocal({ dateString }).toLocaleDateString();
  } catch (err) {
    //silent
  }
  return "";
};


export const showLocaleDateTime = ({
  dateString,
  todayOnlyTime = false,
}: {
  dateString: string;
  todayOnlyTime?: boolean;
}) => {
  try {
    const parsedDate = convertUTCDateTimeToLocal({ dateString });
    if (todayOnlyTime && isToday(parsedDate)) {
      return parsedDate.toLocaleTimeString();
    } else {
      return parsedDate.toLocaleString();
    }
  } catch (err) {
    //silent, do not show date.
  }
  return "";
};
