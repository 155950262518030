import { Typography, styled } from "@mui/material";
import { useTranslate } from "ra-core";

const Base = styled("div")(({ theme }) => ({
  color: theme.palette.common.white,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
  position: "absolute",
  top: 0,
  left: 0,
  height: "100%",
  width: "100%",
  backgroundImage:
    "linear-gradient(90deg, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.3) 100%)",
  "&:hover > div.marked-as-preview": {
    display: "none",
  },
  "& > div.mark-as-preview": {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    display: "none",
    backgroundImage:
      "linear-gradient(90deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.4) 100%)",
  },
  "&:hover > div.mark-as-preview": {
    display: "flex",
  },
}));

type Props = {
  preview: boolean;
  onClick: (file: any) => void;
};

const MarkAsPreviewOverlay = ({ preview, onClick, ...rest }: Props) => {
  const t = useTranslate();
  return (
    <Base {...rest}>
      {preview && (
        <div className="marked-as-preview">
          <Typography>{t("general.markedAsPreview")}</Typography>
        </div>
      )}
      <div onClick={onClick} className="mark-as-preview">
        <Typography>{t("general.markAsPreview")}</Typography>
      </div>
    </Base>
  );
};

export default MarkAsPreviewOverlay;
