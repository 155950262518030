import moment from "moment";
import { PriceType } from "../types/enums";

export const renderAddress = (record) => {
  const address: string[] = [];
  record?.address?.streetName && address.push(record?.address?.streetName);
  record?.address?.number && address.push(record?.address?.number);
  record?.address?.city && address.push(record?.address?.city);
  record?.address?.country && address.push(record?.address?.country);
  return address.join(", ");
};

export const formatMoney = (value) => {
  return value ? `${value.toLocaleString("sk")} €` : "";
};

export const formatDate = (date) => {
  if (!date) return "";
  const formatted = moment(date, "DD-MM-YYYY").format("YYYY-MM-DD");

  return formatted;
};

export const formatDateInputDate = (date) => {
  if (!date) return "";
  const regEx = /^\d{4}-\d{2}-\d{2}$/;
  if (date.match(regEx)) return date;
  return moment(date, "DD-MM-YYYY").format("YYYY-MM-DD");
};

export const displayDate = (date) => {
  if (!date) return "";
  const formatted = moment(date, "YYYY-MM-DD").format("DD. MM. YYYY");

  return formatted;
};

export const parseDate = (date) => {
  if (!date) return "";
  const parsed = moment(date, "YYYY-MM-DD").format("DD-MM-YYYY");

  return parsed;
};

export const formatPriceTypePayload = (pricing) => {
  if (!pricing) {
    return {};
  }

  switch (pricing.priceType) {
    case PriceType.AUCTION:
      return {
        ...pricing,
        monthlyPayment: "",
        deposit: "",
        minimalRent: "",
      };
    case PriceType.PRICE_GUIDE:
    case PriceType.PRICE_UPON_REQUEST:
      return {
        ...pricing,
        monthlyPayment: "",
        deposit: "",
        auctionUrl: "",
        minimalRent: "",
      };
    case PriceType.PRICE:
      return {
        ...pricing,
        auctionUrl: "",
      };
    case "" || null:
      return {
        ...pricing,
        price: "",
        monthlyPayment: "",
        deposit: "",
        auctionUrl: "",
        minimalRent: "",
        availableFrom: "",
      };
    default:
      return pricing;
  }
};
