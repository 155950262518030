import { Box, Grid, Typography } from "@mui/material";
import { useRecordContext } from "react-admin";
import ImageAvatarField from "../../components/ra/fields/ImageField";
import LocationLinkField from "../../components/ra/fields/LocationLinkField";
import SectionTitle from "../../components/common/SectionTitle";
import { Office } from "../../types/model/model";

const OfficeDetail = () => {
  const record = useRecordContext<Office>();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box mb={2}>
          <Box mt={2} mb={2} display="flex">
            <Box display="flex" flex="1">
              <ImageAvatarField
                size={40}
                square
                label={"office.logo"}
                sortable={false}
                source="logo"
              />
            </Box>
            <Box display="flex" justifyContent="flex-end" flex="1">
              <LocationLinkField />
            </Box>
          </Box>
          <Box>
            {record?.name && <SectionTitle>{record?.name}</SectionTitle>}
            <Box mt={2}>
              <Typography>{record?.address}</Typography>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default OfficeDetail;
