import { styled } from "@mui/material";
import packageJSON from "../../../package.json";
import { useEffect, useState } from "react";
import useConfig from "../../hooks/useConfig";

const Wrapper = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.grey[800],
  color: "#9f9f9f",
  position: "absolute",
  bottom: 0,
  padding: "16px",
  fontSize: "12px",
}));

interface Actuator {
  build: {
    version: string;
    group: string;
    artifact: string;
    time: string;
    name: string;
  };
}

const Par = styled("p")(({ theme }) => ({
  margin: 0,
}));

const Version = () => {
  const [data, setData] = useState<Actuator | null>(null);
  const { URL_API } = useConfig();

  useEffect(() => {
    fetch(`${URL_API}/actuator/info`)
      .then((response) => response.json())
      .then((json: Actuator) => setData(json));
  }, [URL_API]);
  return (
    <Wrapper>
      <Par>Backoffice version: {packageJSON.version}</Par>
      <Par>API: {data?.build.version}</Par>
    </Wrapper>
  );
};

export default Version;
