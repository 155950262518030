import { Box, Grid, styled, Typography, Chip } from "@mui/material";
import UserAvatar from "./UserAvatar";
import {
  MailOutline,
  PhoneAndroid,
  LanguageOutlined,
  BookOutlined,
} from "@mui/icons-material";
import { Size } from "../../types/enums";
import { useTranslate } from "ra-core";
import ExternalLink from "./ExternalLink";

const Item = styled(Box)(({ theme }) => ({
  color: theme.palette.grey[400],
  fontSize: "1rem",
}));

const Sites = styled("div")(({ theme }) => ({
  position: "absolute",
  top: -8,
  right: 0,
  display: "flex",
  flexDirection: "column",
}));

const PrimaryText = styled(Typography)(({ theme }) => ({
  paddingLeft: 2,
}));

type PersonInfoItemProps = {
  icon?: JSX.Element;
  primaryText?: string;
  classes?: { item?: string; icon?: string; primaryText?: string };
};

const PersonInfoItem = ({
  classes,
  icon,
  primaryText,
}: PersonInfoItemProps) => {
  if (!primaryText) return null;
  return (
    <Item alignItems="center" display="flex">
      {icon}
      <PrimaryText>{primaryText}</PrimaryText>
    </Item>
  );
};

type Props = {
  advertisementType?: string;
  uid?: string;
  photo: string;
  firstName: string;
  lastName: string;
  email?: string;
  phone?: string;
  website?: string;
  bookMeUrl?: string;
  role?: string;
};

const PersonInfo = ({ ...props }: Props) => {
  const {
    advertisementType,
    email,
    firstName,
    lastName,
    phone,
    role,
    website,
    bookMeUrl,
  } = props;
  const t = useTranslate();

  return (
    <Box p={4} display="flex">
      <Grid alignItems="center" container>
        <Grid
          alignItems="center"
          alignContent="center"
          justifyContent="center"
          item
          xs={12}
          sm={2}
        >
          <Box>
            <UserAvatar size={Size.LG} user={props} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={10}>
          <Box position="relative">
            {advertisementType ? (
              <Typography>
                {t("advertisement.hostedBy", {
                  advertisementType:
                    advertisementType?.toLowerCase() ||
                    t("advertisement.advertisement"),
                })}{" "}
                {(firstName === null || lastName === null) ? email : `${firstName} ${lastName}`}
              </Typography>
            ) : (
              <Typography>
                {firstName} {lastName}
              </Typography>
            )}
            <PersonInfoItem
              primaryText={email}
              icon={<MailOutline fontSize="small" />}
            />
            <PersonInfoItem
              primaryText={phone}
              icon={<PhoneAndroid fontSize="small" />}
            />
            {(website || bookMeUrl || role) && (
              <Sites>
                {role && (
                  <Box mb={1}>
                    <Chip label={t(`user.roles.${role}`)} />
                  </Box>
                )}
                {website && (
                  <ExternalLink icon={<LanguageOutlined />} href={website}>
                    {website}
                  </ExternalLink>
                )}
                {bookMeUrl && (
                  <ExternalLink icon={<BookOutlined />} href={bookMeUrl}>
                    {bookMeUrl}
                  </ExternalLink>
                )}
              </Sites>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PersonInfo;
