import logoRb from "../../assets/logo-rb.svg";
import { Box, styled } from "@mui/material";
import { useSidebarState } from "react-admin";

const LogoLarge = styled("img")(({ theme }) => ({
  padding: theme.spacing(1),
  marginBottom: theme.spacing(2),
  margin: "auto",
  marginTop: theme.spacing(2),
  width: 90,
  height: "auto",
  transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
}));

const LogoSmall = styled("img")(({ theme }) => ({
  padding: theme.spacing(1),
  marginBottom: theme.spacing(0),
  margin: "auto",
  marginTop: theme.spacing(0),
  width: 50,
  height: "auto",
  transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
}));

const Logo = () => {
  const [sidebarOpen] = useSidebarState();

  return (
    <Box display="flex" justifyContent="center">
      {sidebarOpen ? (
        <LogoLarge src={logoRb} alt="Logo" />
      ) : (
        <LogoSmall src={logoRb} alt="Logo" />
      )}
    </Box>
  );
};

export default Logo;
