import React from "react";
import { RaRecord } from "react-admin";

export interface ResourceModalDialogState {
  type: "CREATE" | "EDIT" | "SHOW" | null;
  record: RaRecord | null;
}

export const defaultState = { record: null, type: null };

const Store = React.createContext<[ResourceModalDialogState, any]>([
  defaultState,
  {},
]);

Store.displayName = "ResourceModalDialogStore";

export default Store;
