import {
  useNotify,
  useRedirect,
  useCreate,
  useUpdate,
} from "ra-core";
import { useCallback } from "react";
import { useRefresh } from "react-admin";


const useUserFormSubmit = ({ onSuccess }) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const [updateVideo, { isLoading: updating }] = useUpdate();
  const [createVideo, { isLoading: creating }] = useCreate();

  const handleFailure = useCallback(
    (error) => {
      redirect("/video");
      refresh();
      notify("error.unexpected", { type: "error" });
    },
    [notify, refresh, redirect]
  );

  const handleSuccess = useCallback(
    (message) => {
      redirect("/video");
      refresh()
      onSuccess()
      notify(message, { type: "success", messageArgs: { resource: "video" } });
    },
    [notify, onSuccess, refresh, redirect]
  );

  const callUpdateVideo = useCallback((values) => {
    updateVideo(
      "video",
      { id: values.id, data: values },
      {
        onSuccess: () => handleSuccess("general.resourceUpdated"),
        onError: handleFailure,
      }
    );
  }, [updateVideo, handleSuccess, handleFailure])

  const callCreateVideo = useCallback((values) => {
    createVideo(
      "video",
      { data: values },
      {
        onSuccess: () => handleSuccess("general.resourceCreated"),
        onError: handleFailure,
      }
    );
  }, [createVideo, handleSuccess, handleFailure])

  const handleSubmit = useCallback(
    (values) => {
      if (values.id)
        callUpdateVideo(values);
      else
        callCreateVideo(values)
    },
    [callUpdateVideo, callCreateVideo]
  );

  return { handleSubmit, creating, updating };
};

export default useUserFormSubmit;
