import { stringify } from "query-string";
import { createQueryParams, mapResource } from "./provider.utils";

const userProvider = ({ apiUrl, httpClient }) => {
  const BASE_URL = `${apiUrl}/account`;
  return {
    getUserDetail: async (id) => {
      const url = `${BASE_URL}/user/${id}`;
      return await httpClient(url, {
        method: "GET",
      }).then((data) => {
        return data;
      });
    },
    getList: (resource, params) => {
      const query = createQueryParams(params);
      const url = `${apiUrl}/${mapResource(resource)}?${stringify(query)}`;
      return httpClient(url, {}).then(
        ({ headers, json }: { headers: any; json: any }) => {
          return {
            data: json.content.map((x) => ({ ...x, id: x.uid, originalId: x.id})),
            total: json.totalElements,
          };
        }
      );
    },

    getOne: (resource, params) => {
      const url = `${apiUrl}/${mapResource(resource)}/${params.id}`;
      return httpClient(url).then(({ json }: { json: any }) => {
        return {
          data: {
            ...json,
            id: json.uid,
            state: json.state === "ENABLED" ? true : false,
            originalId: json.id
          },
        };
      });
    },

    update: (resource, params) => {
      return httpClient(
        `${apiUrl}/${mapResource(resource)}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ...params.data }),
        },
        { raFetch: false }
      )
        .then((res) => {
          return res.json();
        })
        .then((json) => {
          return { data: { ...json, id: json.uid } };
        });
    },

    upgradeToBroker: async (data) => {
      const url = `${BASE_URL}/broker`;
      return await httpClient(url, {
        method: "PUT",
        body: JSON.stringify(data),
      }).then((data) => {
        return { data: {} };
      });
    },
    activateUser: async (user) => {
      const url = `${BASE_URL}/user/${user.uid}/activate`;
      return await httpClient(url, {
        method: "PUT",
      }).then((data) => {
        return { data: {} };
      });
    },
    deactivateUser: async (user) => {
      const url = `${BASE_URL}/user/${user.uid}/deactivate`;
      return await httpClient(url, {
        method: "PUT",
      }).then((data) => {
        return { data: {} };
      });
    },
  };
};

export default userProvider;
