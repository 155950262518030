import { useDataProvider } from "ra-core";
import FetchButton from "./FetchButton";
import Loader from "../../../common/Loader";
import Display from "../../../common/Display";
import { useCallback } from "react";
import { useQuery } from "react-query";

const limitItemsForDisplayFetchButton = 5;

type Props = {
  offset: number;
  limit: number;
  delay?: string;
  resource: string;
  onSuccess?: (data: any) => void;
  payload?: object;
  fetchMore?: boolean;
  fetchPosition?: "top" | "bottom";
  loadingPosition?: "center" | "top" | "bottom";
  onFetchClick?: () => void;
  children: ({ data }) => any;
};

const OffsetList = ({
  offset,
  limit,
  delay = "0s",
  fetchPosition,
  fetchMore = false,
  loadingPosition = "center",
  onSuccess,
  resource,
  payload,
  onFetchClick,
  children,
}: Props) => {
  const dataProvider = useDataProvider();
  const {
    isLoading: loading,
    error,
    data = { data: [] },
  } = useQuery(
    [
      resource,
      "getList",
      {
        ...payload,
        offset,
        limit,
      },
    ],
    () =>
      dataProvider.getOffsetList(resource, {
        ...payload,
        offset,
        limit,
      }),
    { onSuccess }
  );

  const displayFetchMore = useCallback(
    (position: string) => {
      return (
        data?.data?.length > limitItemsForDisplayFetchButton &&
        fetchPosition === position &&
        fetchMore
      );
    },
    [data?.data, fetchMore, fetchPosition]
  );

  if (error) return null;

  if (loading && loadingPosition === "center") return <Loader />;

  return (
    <Display delay={delay}>
      {displayFetchMore("top") && <FetchButton onClick={onFetchClick} />}
      {loading && loadingPosition === "top" && <Loader />}
      {children({
        data: data?.data || [],
      })}
      {displayFetchMore("bottom") && <FetchButton onClick={onFetchClick} />}
      {loading && loadingPosition === "bottom" && <Loader />}
    </Display>
  );
};

export default OffsetList;
