import ReactDropzone from "react-dropzone";
import { List, Typography, Box } from "@mui/material";
import { styled } from "@mui/material";
import { useTranslate } from "ra-core";
import Attachment from "./Attachment";
import { Media } from "../../../types/model/model";

const MediaList = styled(List)(({ theme }) => ({
  maxHeight: "85vh",
  overflowY: "auto",
  display: "grid",
  gap: 4,
  gridAutoFlow: "row",
  gridTemplateColumns: "repeat(3, 1fr)",
  gridTemplateRows: "auto",
}));

const DropzoneArea = styled("div")(({ theme }) => ({
  borderWidth: 4,
  borderColor: theme.palette.secondary.main,
  borderStyle: "dashed",
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(2),
  padding: theme.spacing(2),
  minHeight: 120,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  margin: "auto",
  cursor: "pointer",
  color: theme.palette.secondary.main,
  "&:hover": {
    backgroundColor: theme.palette.grey[200],
  },
}));

const DropZoneSection = styled("section")(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(2),
}));

interface Props {
  onDeleteClick: (file: Media) => void;
  onDrop: (files: any) => void;
  onAttachmentClick?: (file: Media) => void;
  files: Array<Media>;
  accept?: string;
  multiple?: boolean;
  mediasError?: boolean
}

const Dropzone = ({
  onDeleteClick,
  onAttachmentClick,
  files = [],
  onDrop,
  mediasError,
  ...rest
}: Props) => {
  const t = useTranslate();
  return (
    <>
      <ReactDropzone onDrop={onDrop} {...rest}>
        {({ getRootProps, getInputProps }) => (
          <DropZoneSection>
            <DropzoneArea {...getRootProps()}>
              <input {...getInputProps()} />
              <Typography align="center">{t("dropzone.text")}</Typography>
            </DropzoneArea>
            {mediasError ? <Box sx={{backgroundColor: 'yellow'}}><Typography align="left" color='#d32f2f' marginTop='10px' >{t("dropzone.error")}</Typography></Box> : null}
          </DropZoneSection>
        )}
      </ReactDropzone>
      {files.length > 0 && (
        <MediaList dense disablePadding>
          {files.map((file, idx) => (
            <Attachment
              key={idx}
              onAttachmentClick={onAttachmentClick}
              file={file}
              onDeleteClick={onDeleteClick}
            />
          ))}
        </MediaList>
      )}
    </>
  );
};

export default Dropzone;
