export const rbTheme = {
  palette: {
    primary: {
      dark: "#00001d",
      light: "#44496e",
      main: "#192243",
      contrastText: "#fff",
    },
    secondary: {
      dark: "#970000",
      light: "#ff504c",
      main: "#d10023",
      contrastText: "#fff",
    },
    success: {
      main: "#2e7d32",
      light: "#4caf50",
      dark: "#1b5e20",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: "Rubik, sans-serif",
    h1: {
      fontSize: "2rem",
    },
    h2: {
      fontSize: "1.5rem",
    },
    h3: {
      fontSize: "1.2rem",
    },
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: "filled" as const,
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: "filled" as const,
      },
    },
    RaSelectInput: {
      defaultProps: {
        size: "small",
      },
      styleOverrides: {
        root: {
          margin: 0,
          marginTop: 0,
          marginBottom: 0,
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          marginBottom: 8,
        },
      },
    },
    RaTopToolbar: {
      styleOverrides: {
        root: {
          marginBottom: 0,
          paddingLeft: 0,
        },
      },
    },
    RaDatagridHeaderCell: {
      styleOverrides: {
        root: {
          fontWeight: 900,
        },
      },
    },
  },
  overrides: {
    MuiPaper: {
      // Name of the rule
      root: {
        // Some CSS
        // backgroundColor: 'white',
      },
    },
    MuiToolbar: {
      root: {
        paddingBottom: 16,
      },
    },
    MuiTableCell: {
      root: {
        padding: 0,
      },
      sizeSmall: {
        padding: 8,
      },
    },
  },
};
