import { Chip } from "@mui/material";
import { FunctionField } from "react-admin";
import { styled } from "@mui/material";
import { STATE_COLORS } from "../../types/options";

const StyledChip = styled(Chip)(({ theme }) => ({
  padding: 4,
  paddingRight: 0,
  paddingLeft: 0,
  height: "auto",
  color: theme.palette.common.white,
}));

const AdvertisementStateChip = (record) => {
  if (!record.state) return null;
  const backgroundColor = STATE_COLORS[record.state];
  return (
    <StyledChip
      // classes={{ root: classes.root }}
      style={{ backgroundColor }}
      label={record.state}
    />
  );
};

const AdvertisementStateField = (props) => (
  <FunctionField
    {...props}
    sortBy="state"
    label="general.state"
    render={(props) => <AdvertisementStateChip {...props} />}
  />
);

export default AdvertisementStateField;
