import { useCallback } from "react";
import DownloadIcon from "@mui/icons-material/GetApp";
import {
  fetchRelatedRecords,
  useDataProvider,
  useNotify,
  useListContext,
  SortPayload,
  Exporter,
  FilterPayload,
  useResourceContext,
} from "ra-core";
import { Button, ButtonProps } from "react-admin";

const ExportButton = (props: ExportButtonProps) => {
  const {
    onClick,
    page = 0,
    perPage = 1000,
    label = "ra.action.export",
    icon = defaultIcon,
    exporter: customExporter,
    ...rest
  } = props;
  const {
    filter,
    filterValues,
    sort,
    exporter: exporterFromContext,
    total,
  } = useListContext(props);
  const resource = useResourceContext(props);
  const exporter = customExporter || exporterFromContext;
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const handleClick = useCallback(
    (event) => {
      dataProvider
        .getList(resource, {
          sort: sort,
          filter: filter ? { ...filterValues, ...filter } : filterValues,
          pagination: { page, perPage },
        })
        .then(
          ({ data }) =>
            exporter &&
            exporter(
              data,
              fetchRelatedRecords(dataProvider),
              dataProvider,
              resource
            )
        )
        .catch((error) => {
          console.error(error);
          notify("ra.notification.http_error", { type: "warning" });
        });
      if (typeof onClick === "function") {
        onClick(event);
      }
    },
    [
      dataProvider,
      exporter,
      filter,
      filterValues,
      notify,
      onClick,
      resource,
      sort,
      page,
      perPage,
    ]
  );

  return (
    <Button
      onClick={handleClick}
      label={label}
      disabled={total === 0}
      {...sanitizeRestProps(rest)}
    >
      {icon}
    </Button>
  );
};

const defaultIcon = <DownloadIcon />;

const sanitizeRestProps = ({
  basePath,
  filterValues,
  resource,
  ...rest
}: Omit<ExportButtonProps, "sort" | "maxResults" | "label" | "exporter">) =>
  rest;

interface Props {
  basePath?: string;
  exporter?: Exporter;
  filterValues?: FilterPayload;
  icon?: JSX.Element;
  label?: string;
  maxResults?: number;
  onClick?: (e: Event) => void;
  resource?: string;
  sort?: SortPayload;
}

export type ExportButtonProps = Props &
  ButtonProps & { perPage?: number; page?: number };

export default ExportButton;
