import { Sidebar, useSidebarState } from "react-admin";
import { styled, Box } from "@mui/material";
import Logo from "./Logo";
import { Divider } from "@mui/material";
import Version from "./Version";

const DIVIDER_SPACING = 4;

const CustomSidebar = styled(Sidebar)(({ theme }) => ({
  backgroundColor: theme.palette.grey[800],
  color: theme.palette.primary.contrastText,
  minHeight: "100vh",
}));

const CustomDivider = styled(Divider)(({theme}) => ({
  maxWidth: 30,
  margin: "auto",
  borderColor: theme.palette.grey[400]
}))

const MySidebar = (props) => {
  const [open] = useSidebarState();
  return (
    <CustomSidebar {...props}>
      <Logo />
      {open && (
        <Box mb={DIVIDER_SPACING} mt={DIVIDER_SPACING}>
          <CustomDivider />
        </Box>
      )}
      {props.children}
      <Version />
    </CustomSidebar>
  );
};

export default MySidebar;
