import {
  AdvertisementStates,
  AdvertisementType,
  BathroomTypes,
  PriceType,
  PushNotificationType,
  VideoType,
} from "./enums";

export const ADVERTISEMENT_TYPE_RB_CHOICES = [
  { id: AdvertisementType.HOUSE, name: AdvertisementType.HOUSE },
  { id: AdvertisementType.APARTMENT, name: AdvertisementType.APARTMENT },
  { id: AdvertisementType.LIFESTYLE, name: AdvertisementType.LIFESTYLE },
  { id: AdvertisementType.COMMERCIAL, name: AdvertisementType.COMMERCIAL },
  { id: AdvertisementType.LAND, name: AdvertisementType.LAND },
  { id: AdvertisementType.OTHER, name: AdvertisementType.OTHER },
];

export const PRICE_TYPE = [
  { id: PriceType.AUCTION, name: PriceType.AUCTION },
  { id: PriceType.PRICE, name: PriceType.PRICE },
  { id: PriceType.PRICE_GUIDE, name: PriceType.PRICE_GUIDE },
  { id: PriceType.PRICE_UPON_REQUEST, name: PriceType.PRICE_UPON_REQUEST },
];

export const PUSH_NOTIFICATION_TYPES = [
  {
    id: PushNotificationType.PROFILE_OPEN,
    name: PushNotificationType.PROFILE_OPEN,
    availableForLoggedOutUsers: true,
    availableForRB:true
  },
  {
    id: PushNotificationType.DASHBOARD_OPEN,
    name: PushNotificationType.DASHBOARD_OPEN,
    availableForLoggedOutUsers: true,
    availableForRB:true
  },
  {
    id: PushNotificationType.MAP_OPEN,
    name: PushNotificationType.MAP_OPEN,
    availableForLoggedOutUsers: true,
    availableForRB:true
  },
  {
    id: PushNotificationType.IMPORTANT_OPEN,
    name: PushNotificationType.IMPORTANT_OPEN,
    availableForLoggedOutUsers: false,
    availableForRB:true
  },
  {
    id: PushNotificationType.ADV_OPEN,
    name: PushNotificationType.ADV_OPEN,
    availableForLoggedOutUsers: true,
    availableForRB:true
  },

  {
    id: PushNotificationType.ADV_EDIT,
    name: PushNotificationType.ADV_EDIT,
    availableForLoggedOutUsers: false,
    availableForRB:false
  },
  {
    id: PushNotificationType.DASHBOARD_FILTERED,
    name: PushNotificationType.DASHBOARD_FILTERED,
    availableForLoggedOutUsers: true,
    availableForRB:false
  },
  {
    id: PushNotificationType.MAP_FILTERED,
    name: PushNotificationType.MAP_FILTERED,
    availableForLoggedOutUsers: true,
    availableForRB:false
  },
  {
    id: PushNotificationType.EXTERNAL_OPEN_URL,
    name: PushNotificationType.EXTERNAL_OPEN_URL,
    availableForLoggedOutUsers: true,
    availableForRB:true
  },
];

export const VIDEO_TYPES = [
  {
    id: VideoType.HOW_TO_VIDEOS_PLAYLIST,
    name: VideoType.HOW_TO_VIDEOS_PLAYLIST,
  },
  {
    id: VideoType.VIDEO,
    name: VideoType.VIDEO,
  },
];

export const AMENITY_TYPE = [
  {
    id: "bathtub",
    name: "bathtub",
    active: true,
  },
  {
    id: "internet",
    name: "internet",
    active: true,
  },
  {
    id: "aircondition",
    name: "aircondition",
    active: true,
  },
  {
    id: "balcony",
    name: "balcony",
    active: true,
  },
  {
    id: "wardrobe",
    name: "wardrobe",
    active: true,
  },
  {
    id: "elevator",
    name: "elevator",
    active: true,
  },
  {
    id: "fridge",
    name: "fridge",
    active: true,
  },
  {
    id: "washmachine",
    name: "washmachine",
    active: true,
  },
  {
    id: "dishwasher",
    name: "dishwasher",
    active: true,
  },
  {
    id: "microwave",
    name: "microwave",
    active: true,
  },
  {
    id: "beds",
    name: "beds",
    active: true,
  },
  {
    id: "blankets",
    name: "blankets",
    active: true,
  },
  {
    id: "dishes",
    name: "dishes",
    active: true,
  },
  {
    id: "curtains",
    name: "curtains",
    active: true,
  },
  {
    id: "diningtable",
    name: "diningtable",
    active: true,
  },
  {
    id: "lighting",
    name: "lighting",
    active: true,
  },
  {
    id: "bookcase",
    name: "bookcase",
    active: true,
  },
  {
    id: "sofa",
    name: "sofa",
    active: true,
  },
  {
    id: "tv",
    name: "tv",
    active: true,
  },
  {
    id: "carpets",
    name: "carpets",
    active: true,
  },
  {
    id: "cable",
    name: "cable",
    active: true,
  },
  {
    id: "decorations",
    name: "decorations",
    active: true,
  },
  {
    id: "parking",
    name: "parking",
    active: true,
  },
  {
    id: "garden",
    name: "garden",
    active: true,
  },
];

export const ADVERTISEMENT_STATE_FILTER = [
  { id: AdvertisementStates.SAVED, name: AdvertisementStates.SAVED },
  { id: AdvertisementStates.PUBLISHED, name: AdvertisementStates.PUBLISHED },
  { id: AdvertisementStates.DELETED, name: AdvertisementStates.DELETED },
  {
    id: AdvertisementStates.CONTRACTING,
    name: AdvertisementStates.CONTRACTING,
  },
];

export const ADVERTISEMENT_STATE = [
  { id: AdvertisementStates.PUBLISHED, name: AdvertisementStates.PUBLISHED },
  { id: AdvertisementStates.SAVED, name: AdvertisementStates.SAVED },
  {
    id: AdvertisementStates.CONTRACTING,
    name: AdvertisementStates.CONTRACTING,
  },
  { id: AdvertisementStates.RENTED, name: AdvertisementStates.RENTED },
  { id: AdvertisementStates.DELETED, name: AdvertisementStates.DELETED },
];

export const BATHROOM_TYPES = [
  { id: BathroomTypes.PRIVATE, name: BathroomTypes.PRIVATE },
  { id: BathroomTypes.SHARED, name: BathroomTypes.SHARED },
];

export const EXTERNAL_ADVERTISEMENT_STATE = [
  { id: AdvertisementStates.PUBLISHED, name: AdvertisementStates.PUBLISHED },
  { id: AdvertisementStates.SAVED, name: AdvertisementStates.SAVED },
  // {
  //   id: AdvertisementStates.CONTRACTING,
  //   name: AdvertisementStates.CONTRACTING,
  // },
];

export const STATE_COLORS = {
  CONTRACTING: "#71a7ab",
  PUBLISHED: "#fc9403",
  DELETED: "#ff8d63",
  SAVED: "#878787",
  RENTED: "#649e75",
  DISABLED: "#878787",
  SENT: "#878787",
  READ: "#878787",
  ENABLED: "#878787",
};
