import { styled } from "@mui/material";
import Notification from "./Notification";
import Sidebar from "./SideBar";
import Appbar from "./AppBar";
import Menu from "./Menu";
import { LayoutComponent, useSidebarState } from "react-admin";
import RebbidChat from "../widgets/chat";

const Root = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  zIndex: 1,
  minHeight: "100vh",
  backgroundColor: theme.palette.background.default,
  position: "relative",
}));

const AppFrame = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  overflowX: "auto",
}));

const ContentWithSidebar = styled("main")(({ theme }) => ({
  display: "flex",
  flexGrow: 1,
}));

const Content = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flexGrow: 2,
  padding: theme.spacing(3),
  height: "100vh",
  overflowX: "auto",
  overflowY: "auto",
}));

const AppbarWithContent = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  position: "relative",
  flexGrow: 1,
  backgroundColor: theme.palette.grey[300],
  height: "100vh",
  overflowX: "auto",
  overflowY: "auto",
}));

const MyLayout: LayoutComponent = ({ children, title }) => {
  const [open] = useSidebarState();

  return (
    <Root>
      <AppFrame>
        <ContentWithSidebar>
          <Sidebar>
            <Menu />
          </Sidebar>
          <AppbarWithContent>
            <Appbar title={title} open={open} />
            <Content>{children}</Content>
          </AppbarWithContent>
        </ContentWithSidebar>
        <Notification />
        <RebbidChat />
      </AppFrame>
    </Root>
  );
};

export default MyLayout;
