import Avatar from "./Avatar";
import { Size } from "../../types/enums";
import { User } from "../../types/model/model";
import { getUserFirstCapital } from "../../utils/base";
import useFileUrl from "../../hooks/useFileUrl";
import { styled } from "@mui/material";

type UserAvatarProps = {
  user: Partial<User>;
  size?: Size;
  active?: boolean;
};

const Image = styled("img")(({ theme }) => ({
  width: "100%",
  height: "auto",
  backgroundPosition: "center",
}));

const AvatarImage = ({ user }: { user: Partial<User> }) => {
  const { loading, url, error } = useFileUrl(user.photo);
  if (loading || error) return <>{getUserFirstCapital(user)}</>;
  return <Image src={url} alt="avatar" />;
};

const UserAvatar = ({ user, size = Size.MD, ...rest }: UserAvatarProps) => {
  if (!user.photo) {
    <Avatar size={size} {...rest}>
      {getUserFirstCapital(user)}
    </Avatar>;
  }

  return (
    <Avatar size={size} {...rest}>
      <AvatarImage user={user} />
    </Avatar>
  );
};

export default UserAvatar;
