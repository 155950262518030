import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { Advertisement, Chat } from "../../../../types/model/model";
import AdvertisementWithChatList from "./AdvertisementWithChatList";
import ChatList from "./ChatList";
import MessagePanel from "./MessagePanel";

const Base = styled.div(({ theme }) => ({
  display: "flex",
}));

const AdvertisementListColumn = styled.div(({ theme }) => ({
  flex: "0 0 30%",
  maxWidth: "30%",
  background: theme.palette.grey[600],
  position: "relative",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  overflow: "auto",
  paddingTop: 4,
  paddingLeft: 4,
  paddingRight: 4,
  paddingBottom: 4,
  height: 380,
  listStyle: "none",
  marginBottom: 0,
  marginTop: 0,
  boxSizing: "border-box",
}));

const ChatListColumn = styled.div(({ theme }) => ({
  flex: "0 0 30%",
  maxWidth: "30%",
  position: "relative",
  width: "100%",
  background: theme.palette.grey[100],
  marginLeft: 0,
  marginRight: 0,
}));

const MessageListColumn = styled.div(({ theme }) => ({
  flex: "0 0 40%",
  maxWidth: "40%",
  position: "relative",
  backgroundColor: "#f1f4ff",
  width: "100%",
  overflow: "auto",
  height: 380,
}));

type Props = {
  onChangeAdvertisement: (advertisement: Advertisement) => any;
};

const ChatBox = ({ onChangeAdvertisement }: Props) => {
  const [activeChat, setActiveChat] = useState<Chat>();
  const [activeAdvertisement, setActiveAdvertisement] =
    useState<Advertisement>();

  useEffect(() => {
    if (activeAdvertisement) onChangeAdvertisement(activeAdvertisement);
  }, [activeAdvertisement, onChangeAdvertisement]);

  const [offset, setOffset] = useState(0);

  const handleSetActiveChat = useCallback(
    (chat) => {
      setActiveChat(chat);
      setOffset(0);
    },
    [setOffset]
  );

  return (
    <Base>
      <AdvertisementListColumn>
        <AdvertisementWithChatList
          value={activeAdvertisement?.id}
          onItemClick={setActiveAdvertisement}
        />
      </AdvertisementListColumn>
      <ChatListColumn>
        {activeAdvertisement?.id && (
          <ChatList
            value={activeChat?.chatId}
            advId={activeAdvertisement?.id}
            onItemClick={handleSetActiveChat}
          />
        )}
      </ChatListColumn>
      <MessageListColumn>
        {activeChat?.id && activeAdvertisement && (
          <MessagePanel
            offset={offset}
            setOffset={setOffset}
            activeAdvertisement={activeAdvertisement}
            chatId={activeChat?.chatId}
          />
        )}
      </MessageListColumn>
    </Base>
  );
};

export default ChatBox;
