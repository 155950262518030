import { stringify } from "query-string";
import { createQueryParams, mapResource } from "./provider.utils";

const officeProvider = ({ apiUrl, httpClient }) => {
  const BASE_URL = `${apiUrl}/account/backoffice/office`;

  return {
    getList: (resource, params) => {
      const query = createQueryParams(params);
      const url = `${apiUrl}/${mapResource(resource)}/all?${stringify(query)}`;

      return httpClient(url, {}).then(
        ({ headers, json }: { headers: any; json: any }) => {
          return {
            data: json.content,
            total: json.totalElements,
          };
        }
      );
    },

    addUserToOffice: async (data) => {
      const url = `${BASE_URL}/${data.officeId}/broker/add/${data.userId}`;
      return await httpClient(url, {
        method: "PUT",
      }).then((data) => {
        return { data: {} };
      });
    },

    removeUserFromOffice: async (data) => {
      const url = `${BASE_URL}/${data.officeId}/broker/remove/${data.userId}`;
      return await httpClient(url, {
        method: "PUT",
      }).then((data) => {
        return { data: {} };
      });
    },

    delete: async (resource, data) => {
      const url = `${BASE_URL}`;
      return await httpClient(url, {
        method: "DELETE",
        body: JSON.stringify({ id: data.id }),
      }).then((response) => {
        return { data: data };
      });
    },

    update: async (resource, data) => {
      const url = `${BASE_URL}`;
      return await httpClient(url, {
        method: "PUT",
        body: JSON.stringify({ ...data.data }),
      }).then((response) => {
        return { data: response.json };
      });
    },

    create: async (resource, data) => {
      const url = `${BASE_URL}`;
      return await httpClient(url, {
        method: "POST",
        body: JSON.stringify({ ...data.data }),
      }).then((response) => {
        return { data: response.json };
      });
    },
  };
};

export default officeProvider;
