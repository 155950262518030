import { useInput, useTranslate } from "react-admin";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { useCallback } from "react";

const CustomSelectInput = styled(Select)(({ theme }) => ({
  height: 48,
}));

const CustomSFormControl = styled(FormControl)(({ theme }) => ({
  marginTop: theme.spacing(1),
}));

const BoundedSelectArrayInput = (props) => {
  const t = useTranslate();
  const {
    field: { onChange, value },
  } = useInput(props);

  const handleChange = useCallback(
    (e, val) => {
      const v = e.target.value;
      const newChoices = props.choices.map((ch) => ({
        ...ch,
        active: v.find((name) => name === ch.name) ? true : false,
      }));
      onChange(newChoices);
    },
    [onChange, props.choices]
  );

  const mappedValue = (value || []).filter((v) => v.active).map((x) => x.name);
  return (
    <CustomSFormControl fullWidth variant="filled">
      <InputLabel id="select-array-input">Ammenity</InputLabel>
      <CustomSelectInput
        labelId="select-array-input"
        id="demo-mutiple-checkbox"
        multiple
        onChange={handleChange}
        defaultValue={props.defaultValue}
        value={mappedValue}
        renderValue={(selected: any) => {
          return t("advertisement.selectedAmenities", {
            count: selected.length,
          });
        }}
      >
        {props.choices.map((a, key) => (
          <MenuItem key={key} value={a.name}>
            <Checkbox
              checked={
                mappedValue.find((name) => name === a.name) ? true : false
              }
            />
            <ListItemText primary={a.name} />
          </MenuItem>
        ))}
      </CustomSelectInput>
    </CustomSFormControl>
  );
};

const SelectArrayInput = (props) => {
  const { source, label, ...rest } = props;

  return (
    <span>
      <BoundedSelectArrayInput source={source} label={label} {...rest} />
    </span>
  );
};

export default SelectArrayInput;
