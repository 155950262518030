import MdChatBubble from "@mui/icons-material/ChatBubble";
import { styled } from "@mui/material";

const Base = styled("div")(({ theme }) => ({
  position: "fixed",
  right: theme.spacing(1),
  bottom: theme.spacing(1),
  zIndex: 9998,
  cursor: "pointer",
  backgroundColor: theme.palette.secondary.main,
  width: 50,
  height: 50,
  display: "flex",
  alignItems: "center",
  boxShadow: theme.shadows[2],
  transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
  justifyContent: "center",
  borderRadius: "90%",
  "&:hover": {
    backgroundColor: theme.palette.secondary.dark,
  },
}));

const Icon = styled(MdChatBubble)(({ theme }) => ({
  color: theme.palette.common.white,
}));

type ToggleButtonProps = {
  onClick?: () => void;
};

function ToggleButton({ onClick }: ToggleButtonProps) {
  return (
    <Base onClick={onClick}>
      <Icon />
    </Base>
  );
}

export default ToggleButton;
