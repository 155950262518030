import { styled } from "@mui/material";
import useFileUrl from "../../../hooks/useFileUrl";
import { useEffect } from "react";
import ImagePreviewOverlay from "./ImagePreviewOverlay";

const Img = styled("img")(({ theme }) => ({
  width: "100%",
  height: "30vw",
  maxHeight: "25rem",
  display: "block",
  objectFit: "cover",
  objectPosition: "center",
}));

const LightGalleryComponent: React.FC<{
  media: string;
  setLoadingState: any;
  index: number;
}> = ({media, setLoadingState, index}) => {
  const { url, error, loading } = useFileUrl(media);
  const noImageUrl = "/imageNotAvailable.jpg";

  useEffect(() => {
    setLoadingState((prevState: any) => {
      return {
        ...prevState,
        [index]: loading,
      };
    });
  }, [loading, index, setLoadingState]);

  return (
    <>
      {url === undefined ? (
        <a href={noImageUrl} className="lightGalleryElement">
          <Img src={noImageUrl} alt="property" />
        </a>
      ) : (
        <a
          href={error === undefined ? url : noImageUrl}
          className="lightGalleryElement"
        >
          <Img src={error === undefined ? url : noImageUrl} alt="property" />
          <ImagePreviewOverlay />
        </a>
      )}
    </>
  );
};

export default LightGalleryComponent;
