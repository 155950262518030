import {
  TextField,
  useRecordContext,
  RaRecord,
  ChipField,
  Button,
  DeleteButton,
} from "react-admin";
import Datagrid from "../../components/ra/Datagrid";
import CustomList from "../../components/ra/List";
import { IconButton } from "@mui/material";
import ExternalLinkField from "../../components/ra/fields/ExternalLinkField";
import EditIcon from "@mui/icons-material/Edit";
import VideoForm from "../../modules/video/VideoForm";
import { AddOutlined } from "@mui/icons-material";
import { CustomTopToolbar } from "../../components/ra/Toolbars";
import { ResourceModalDialog } from "../../components/widgets/resource-modal-dialog";
import {
  useResourceModalDialogContext,
  ResourceModalDialogProvider,
} from "../../components/widgets/resource-modal-dialog";

interface HowToVideoEditButtonProps {
  onClick: (id: RaRecord) => any;
}

const VideoListActions = ({ onCreateClick }) => {
  return (
    <CustomTopToolbar>
      <Button
        startIcon={<AddOutlined />}
        color="secondary"
        label="ra.action.create"
        variant="contained"
        onClick={onCreateClick}
      />
    </CustomTopToolbar>
  );
};

const HowToVideoEditButton = ({ onClick }: HowToVideoEditButtonProps) => {
  const record = useRecordContext();

  return (
    <IconButton onClick={() => onClick(record)} size="small">
      <EditIcon />
    </IconButton>
  );
};

const VideoListContent = ({ ...props }) => {
  const [, updateState] = useResourceModalDialogContext();

  return (
    <>
      <CustomList
        empty={false}
        actions={
          <VideoListActions
            onCreateClick={() => updateState({ record: null, type: "CREATE" })}
          />
        }
        title={"video.listTitle"}
        sort={{ field: "type", order: "ASC" }}
        {...props}
      >
        <Datagrid bulkActionButtons={false}>
          <HowToVideoEditButton
            onClick={(record) => updateState({ record, type: "EDIT" })}
          />
          <TextField label={"general.id"} source="id" />
          <ChipField label={"video.field.type"} source="type" />
          <ExternalLinkField label={"video.field.url"} source="url" />
          <DeleteButton />
        </Datagrid>
      </CustomList>
      <ResourceModalDialog form={<VideoForm />} />
    </>
  );
};

const VideoList = ({ ...props }) => {
  return (
    <ResourceModalDialogProvider>
      <VideoListContent {...props} />
    </ResourceModalDialogProvider>
  );
};

export default VideoList;
