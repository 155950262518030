import {
  TextField,
  useTranslate,
  FunctionField,
  BooleanField,
  TextInput,
  Identifier,
} from "react-admin";
import Datagrid from "../../components/ra/Datagrid";
import ImageAvatarField from "../../components/ra/fields/ImageField";
import CustomList from "../../components/ra/List";
import RemoveBrokerFromOfficeButton from "./RemoveBrokerFromOfficeButton";

const UserFilter = [<TextInput label="user.email" source="email" alwaysOn />];

type Props = {
  officeId: Identifier;
};

const OfficeBrokerList = ({ officeId }: Props) => {
  const t = useTranslate();

  return (
    <CustomList
      filters={UserFilter}
      title={"user.listTitle"}
      resource="user"
      // basePath="/user"
      filter={{ email: "", officeId: officeId || "" }}
    >
      <Datagrid bulkActionButtons={false}>
        <ImageAvatarField label={"user.photo"} source="photo" />

        <TextField label={"general.id"} source="id" />

        <TextField label={"user.email"} source="email" />

        <TextField label={"user.firstName"} source="firstName" />

        <TextField label={"user.lastName"} source="lastName" />

        <FunctionField
          label={"user.type"}
          render={(record) => record.type && t(`user.types.${record.type}`)}
          source="type"
        />

        <FunctionField
          label={"user.role"}
          render={(record) => record.role && t(`user.roles.${record.role}`)}
          source="role"
        />

        <BooleanField label={"user.emailVerified"} source="emailVerified" />
        <RemoveBrokerFromOfficeButton officeId={officeId} />
      </Datagrid>
    </CustomList>
  );
};

export default OfficeBrokerList;
