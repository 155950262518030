import styled from "styled-components";

const Fade = styled.div(({ theme }) => ({
  position: "fixed",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  background: "rgba(0,0,0,0.5)",
  zIndex: 9999,
}));

export default Fade;
