const videoProvider = ({ apiUrl, httpClient }) => {
  const BASE_URL = apiUrl + "/adv/backoffice/video";

  return {
    update: (resource, params) => {
      return httpClient(BASE_URL, {
        method: "POST",
        body: JSON.stringify({ ...params.data }),
      }).then(({ json }) => {
        return {
          data: {
            ...json,
            id: json.id || json.uid,
          },
        };
      });
    },

    create: (resource, params) => {
      return httpClient(BASE_URL, {
        method: "POST",
        body: JSON.stringify({ ...params.data }),
      }).then(({ json }) => {
        return {
          data: {
            ...json,
            id: json.id || json.uid,
          },
        };
      });
    },
  };
};

export default videoProvider;
