import { styled, Typography } from "@mui/material";
import SvgIcon from "../../components/common/SvgIcon";

const Title = styled(Typography)<{ disabled?: boolean }>(
  ({ theme, disabled = false }) => ({
    paddingLeft: theme.spacing(1),
    color: disabled ? theme.palette.grey[300] : "inherit",
    textDecoration: disabled ? "line-through" : "none",
  })
);

const List = styled("ul")(({ theme }) => ({
  padding: 0,
  display: "flex",
  flexWrap: "wrap",
}));

const ListItemBase = styled("li")<{ disabled?: boolean }>(
  ({ theme, disabled = false }) => ({
    flex: "1 1 33%",
    display: "flex",
    color: disabled ? theme.palette.grey[300] : "inherit",
    textDecoration: disabled ? "line-through" : "none",
  })
);

type Item = {
  icon?: string;
  disabled?: boolean;
  title: string | JSX.Element;
  iconUrl?: string
};

type Props = {
  items: Item[];
};

const ListItem = ({ icon, iconUrl, disabled = false, title }: Item) => {
  return (
    <ListItemBase disabled={disabled}>
      {icon && <SvgIcon name={icon} iconUrl={iconUrl!}/>}
      <Title>{title}</Title>
    </ListItemBase>
  );
};

const SimpleList = ({ items = [] }: Props) => {
  return (
    <List>
      {items.map((item, idx) => (
        <ListItem key={idx} {...item} />
      ))}
    </List>
  );
};

export default SimpleList;
