import LocationIcon from "@mui/icons-material/RoomOutlined";
import { IconButton, styled } from "@mui/material";
import { ButtonProps } from "ra-ui-materialui";
import { useGoogleMapsLocation } from "../../../hooks/useGoogleMapsLocation";
import { useCallback } from "react";
import { useRecordContext } from "react-admin";

const CustomIconButton = styled(IconButton)(({ theme }) => ({
  padding: 4,
}));

const LocationButtonBase = (props: ButtonProps) => {
  const record = useRecordContext();
  const url = useGoogleMapsLocation({
    lon: record?.coordinate?.longitude,
    lat: record?.coordinate?.latitude,
  });

  const handleShowLocation = useCallback(
    (e) => {
      e.stopPropagation();
      window.open(url, "_blank");
    },
    [url]
  );
  return (
    <CustomIconButton onClick={handleShowLocation}>
      <LocationIcon />
    </CustomIconButton>
  );
};

const LocationButton = () => {
  const record = useRecordContext();
  if (!record?.coordinate?.latitude || !record?.coordinate?.longitude)
    return null;
  return <LocationButtonBase />;
};

export default LocationButton;
