import styled from "styled-components";
import { MessageType, Size } from "../../../../types/enums";
import { getUserName, showLocaleDateTime } from "../../../../utils/base";
import FileMessage from "./FileMessage";
import { useGetIdentity, UserIdentity } from "ra-core";
import { Box } from "@mui/material";
import { Advertisement, ChatMessage } from "../../../../types/model/model";
import UserAvatar from "../../../common/UserAvatar";

const TextMessage = styled.span<{ align: string }>(({ theme, align }) => ({
  paddingTop: 4,
  paddingBottom: theme.spacing(3),
  paddingLeft: 0,
  paddingRight: 0,
}));

const MessageDate = styled.span<{ align: string }>(({ theme, align }) => ({
  fontSize: "0.6rem",
  display: "flex",
  justifyContent: "flex-end",
  position: "absolute",
  bottom: 4,
  right: align === "left" ? 8 : "auto",
  left: align === "right" ? 8 : "auto",
  color: theme.palette.grey[700],
}));

const Row = styled.div<{ align: string }>(({ align, theme }) => ({
  display: "flex",
  justifyContent: align === "left" ? "flex-start" : "flex-end",
  width: "100%",
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  boxSizing: "border-box",
}));

const MessageBubble = styled.div<{ align: string }>(({ theme, align }) => ({
  boxShadow:
    "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;",
  maxWidth: "85%",
  fontSize: "0.8rem",
  backgroundColor: align === "right" ? "#ffeae3" : "#fff",
  padding: theme.spacing(1),
  borderRadius: 8,
  minWidth: 110,
  // display: "flex",
  position: "relative",
  borderBottomLeftRadius: align === "left" ? 0 : 8,
  borderBottomRightRadius: align === "right" ? 0 : 8,
  marginBottom: theme.spacing(1),
  marginTop: theme.spacing(1),
  // flexDirection: align === "right" ? "row-reverse" : "row",
  transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
}));

const isMeOrOwner = ({
  message,
  activeAdvertisement,
  user,
}: {
  message: ChatMessage;
  activeAdvertisement?: Advertisement;
  user?: UserIdentity;
}) => {
  const isMee = message?.senderUid?.uid === user?.id;
  const isPropertyOwner =
    activeAdvertisement?.owner?.uid === message?.senderUid?.uid;
  return isMee || isPropertyOwner;
};

const UserName = styled.div(({ theme }) => ({
  paddingLeft: 4,
  paddingRight: 4,
  color: theme.palette.grey[900],
}));

const Pill = styled.div(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],
  display: "flex",
  alignItems: "center",
  borderRadius: 90,
  paddingRight: theme.spacing(1),
}));

function Message({
  message,
  activeAdvertisement,
}: {
  message: ChatMessage;
  activeAdvertisement?: Advertisement;
}) {
  const { identity: user } = useGetIdentity();
  const isMeeOrOwner = isMeOrOwner({ message, activeAdvertisement, user });
  const align = isMeeOrOwner ? "left" : "right";

  return (
    <Row align={align}>
      <MessageBubble align={align}>
        <Box
          alignItems="center"
          flexDirection={align === "right" ? "row-reverse" : "row"}
          display="flex"
          width="100%"
        >
          <Pill>
            <span>
              <UserAvatar size={Size.SM} user={message.senderUid} />
            </span>

            <UserName>{getUserName(message.senderUid)}</UserName>
          </Pill>
        </Box>

        <Box paddingBottom={2}>
          {message.type === MessageType.FILE_MESSAGE ? (
            <FileMessage align={align} message={message} />
          ) : (
            <TextMessage align={align}>{message.value}</TextMessage>
          )}
        </Box>

        <MessageDate align={align}>
          {showLocaleDateTime({
            dateString: message.created,
            todayOnlyTime: true,
          })}
        </MessageDate>
      </MessageBubble>
    </Row>
  );
}

export default Message;
