import styled from "styled-components";
import { getUserName, showLocaleDate } from "../../../../utils/base";
import MdMarkunread from "@mui/icons-material/Markunread";
import UserAvatar from "../../../common/UserAvatar";
import { Chat } from "../../../../types/model/model";

const ChatListItem = styled.li(({ theme }) => ({
  transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
  boxShadow:
    "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;",
  width: "100%",
  display: "flex",
  height: 40,
  justifyContent: "flex-start",
  position: "relative",
  alignItems: "center",
  cursor: "pointer",
  background: theme.palette.common.white,
  marginBottom: 4,
  borderTopLeftRadius: 90,
  boxSizing: "border-box",
  borderBottomLeftRadius: 90,
  "&.active": {
    backgroundColor: "#f1f4ff",
    color: theme.palette.secondary.main,
  },
  "&:hover": {
    backgroundColor: "#f1f4ff",
  },
}));

const TextContainer = styled.span(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(4),
}));

const MainItemText = styled.div(({ theme }) => ({
  fontSize: "0.8rem",
  display: "flex",
  alignItems: "center",
  alignContent: "center",
  color: "inherit",
}));

const SecondaryItemText = styled.div(({ theme }) => ({
  fontSize: "0.6rem",
  display: "flex",
  justifyContent: "flex-end",
  position: "absolute",
  color: theme.palette.grey[700],
  right: 4,
  bottom: 2,
}));

const NewMessageIcon = styled(MdMarkunread)(({ theme }) => ({
  position: "absolute",
  right: 4,
  top: 4,
  color: theme.palette.secondary.main,
}));

type ChatTabItemProps = {
  onClick: (chat: Chat) => void;
  chat: Chat;
  selected: boolean;
};

const ChatTabItem = function ({ onClick, chat, selected }: ChatTabItemProps) {
  const classNames = selected ? "active" : "";

  return (
    <ChatListItem
      key={chat.chatId}
      className={classNames}
      onClick={() => onClick(chat)}
    >
      <span>
        <UserAvatar user={chat.user} active={selected} />
      </span>
      {chat.hasNewMessage && <NewMessageIcon />}
      <TextContainer>
        <MainItemText>{getUserName(chat.user)}</MainItemText>
        <SecondaryItemText>
          {showLocaleDate({ dateString: chat.lastMessageDate })}
        </SecondaryItemText>
      </TextContainer>
    </ChatListItem>
  );
};

export default ChatTabItem;
