import styled from "styled-components";
import PlaceholderImageSvg from "../_assets/preview-placeholder.svg";
import ImagePreview from "./ImagePreview";
import { useState } from "react";
import useFileUrl from "../../../../hooks/useFileUrl";
import { ChatMessage } from "../../../../types/model/model";

const Image = styled.img<{ align: string }>(({ theme, align }) => ({
  width: "100%",
  height: "auto",
  paddingRight: align === "right" ? theme.spacing(1) : 0,
  paddingLeft: align === "left" ? theme.spacing(1) : 0,
  backgroundPosition: "center",
  marginBottom: theme.spacing(3),
  cursor: "pointer",
}));

const Container = styled.div({
  height: 120,
  cursor: "pointer",
});

const PlaceholderImage = styled(Image)(({ theme }) => ({
  width: 100,
}));

const FileMessage = ({
  message,
  align,
}: {
  message: ChatMessage;
  align: string;
}) => {
  const { loading, url, error } = useFileUrl(message.value);
  const [showPreview, setShowPreview] = useState(false);

  if (loading) return <span></span>;

  return (
    <>
      <Container onClick={() => setShowPreview(true)}>
        {error ? (
          <PlaceholderImage
            align={align}
            src={PlaceholderImageSvg}
            alt="Image placeholder"
          />
        ) : (
          <Image align={align} src={url} alt="Chat message image" />
        )}
      </Container>
      {showPreview && url ? (
        <ImagePreview src={url} onClose={() => setShowPreview(false)} />
      ) : null}
    </>
  );
};

export default FileMessage;
