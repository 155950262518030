import { Create } from "ra-ui-materialui";
import { CreateActions } from "../../components/ra/Actions";
import OfficeForm from "../../modules/office/OfficeForm";

const OfficeCreate = ({ ...props }) => {
  return (
    <Create actions={<CreateActions />} title={"office.createTitle"} {...props}>
      <OfficeForm />
    </Create>
  );
};

export default OfficeCreate;
