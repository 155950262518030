import {
  Form,
  DateInput,
  TextInput,
  useNotify,
  useCreate,
  useRedirect,
  useUpdate,
  SaveButton,
  useGetIdentity,
  useTranslate,
  SelectInput,
  BooleanInput,
  FormDataConsumer,
  required,
  RaRecord,
} from "react-admin";
import { Typography, Box, Grid } from "@mui/material";
import UserAutocompleteInput from "../../components/ra/inputs/UserAutocompleteInput";
import FileInput from "../../components/ra/inputs/FileInput";
import SelectArrayInput from "../../components/ra/inputs/SelectArrayInput";
import {
  ADVERTISEMENT_STATE,
  ADVERTISEMENT_TYPE_RB_CHOICES,
  PRICE_TYPE,
} from "../../types/options";
import { formatDateInputDate, formatPriceTypePayload } from "../../utils/utils";
import { CustomToolbar } from "../../components/ra/Toolbars";
import { useCallback, useState } from "react";
import CoordinatesInput from "../../components/ra/inputs/CoordinatesInput";
import { PriceType } from "../../types/enums";
import GetAmenities from "../../core/providers/amenities.provider";

const mapValuesToPayload = (identity, values) => {
  const amenities = values.amenities || [];
  const medias = values.medias || [];
  const mediasPreviewCheck =
    medias.length !== 0 && medias.every((obj) => obj.preview === false)
      ? ((medias[0].preview = true), medias)
      : medias;
  /*   const owner = {
    uid: identity?.id,
    email: identity?.email,
    firstName: identity?.fullName.split(" ")[0],
    lastName: identity?.fullName.split(" ")[1],
    photo: identity?.avatar,
  }; */
  console.log(values)
  const payload = {
    ...values,
    details: {
      ...values.details,
      bathroomType: "PRIVATE", //RB-751, bathroomType hidden in the form but required in the backend
    },
    matterport: {
      preview: false,
      type: "MATTERPORT_RB",
      url: "https://my.matterport.com/show/?m=sFjHbAAmxLQ"
    },
    amenities: amenities.map((x) => ({
      name: x.name,
      active: x.active,
      codeId: x.id ? x.id : x.codeId,
    })),
    pricing: formatPriceTypePayload(values.pricing),
    medias: mediasPreviewCheck.map((x) => ({
      type: x.type,
      url: x.url,
      preview: x.preview,
    })),
  };
  console.log(payload)
  return payload;
};

const SubmitButton = (props) => {
  return <SaveButton color="secondary" {...props} />;
};

const AdvertisementForm = (props) => {
  const t = useTranslate();
  const notify = useNotify();
  const redirect = useRedirect();
  const [createAdvertisement] = useCreate();
  const [updateAdvertisement] = useUpdate();
  const { identity } = useGetIdentity();
  const amenitiesSell = GetAmenities("AMENITY_SELL");
  const amenitiesRent = GetAmenities("AMENITY_RENT");
  const [mediasError, setMediasError] = useState(false);
  const [coordinatesError, setCoordinatesError] = useState(false);
  const [data, setData] = useState<RaRecord[]>([]);
  const [inputValue, setInputValue] = useState<RaRecord[]>([]);

  const handleUpdateAdvertisement = useCallback(
    (payload) => {
      let payloadCorrection = { ...payload };

      if (data.length !== 0) {
        payloadCorrection.owner = {
          ...payloadCorrection.owner,
          email: data.find((x) => x.email === inputValue)?.email,
          uid: data.find((x) => x.email === inputValue)?.uid,
        };
      }
      updateAdvertisement(
        "advertisement",
        {
          data: payloadCorrection,
          id: payloadCorrection.id,
          previousData: payloadCorrection,
        },
        {
          onSuccess: () => {
            redirect(`/advertisement/${payloadCorrection.id}/show`);
            notify(
              t("general.resourceUpdated", { resource: "Advertisement" }),
              { type: "success" }
            );
          },

          onError: (error: any) => {
            let finalErrors: string[] = [];
            error.errors.forEach((e: any) => finalErrors.push(t(e)));
            if (finalErrors.length === 0) {
              finalErrors.push(t("error.unexpected"));
            }
            const finalErrorsString = finalErrors.join("\n");
            notify(finalErrorsString, { multiLine: true, type: "error" });
          },
        }
      );
    },
    [notify, redirect, t, updateAdvertisement, data, inputValue]
  );

  const handleCreateAdvertisement = useCallback(
    (payload) => {
      createAdvertisement(
        "advertisement",
        { data: payload },
        {
          onSuccess: (data) => {
            redirect(`/advertisement/${data.id}/show`);
            notify(
              t("general.resourceCreated", { resource: "Advertisement" }),
              { type: "success" }
            );
          },
          onError: () => {
            notify(t("error.unexpected"), { type: "error" });
          },
        }
      );
    },
    [notify, redirect, t, createAdvertisement]
  );
  const validateRecordCreation = (values) => {
    if (values.medias.length !== 0) {
      setMediasError(false);
    }
    if (values.state !== "PUBLISHED") return;
    const errors: any = {
      coordinate: [],
      address: {},
      details: {},
      medias: [],
      pricing: {},
    };

    //ADD TYPE CHECK

    if (!values.advertisementType) {
      errors.advertisementType = "Advertisement type is required";
    }

    //ADDRESS CHECK

    if (values.advertisementType !== "LAND" && !values.address.number) {
      errors.address.number = "Number is required";
    }
    if (!values.address.streetName) {
      errors.address.streetName = "Street name is required";
    }
    if (!values.address.city) {
      errors.address.city = "City is required";
    }
    if (!values.address.country) {
      errors.address.country = "Country is required";
    }

    //MEDIA CHECK

    if (values.medias.length === 0) {
      errors.medias = "Photos required";
      setMediasError(true);
    }
    if (values.medias.length !== 0) {
      setMediasError(false);
    }

    //PRICING CHECK

    if (values.rent && !values.pricing.minimalRent) {
      errors.pricing.minimalRent = "Minimal rent is required";
    }
    if (!values.pricing.priceType) {
      errors.pricing.priceType = "Price type is required";
    }
    if (!values.pricing.availableFrom) {
      errors.pricing.availableFrom = "Available from is required";
    }
    if (values.pricing.priceType === "PRICE" && !values.pricing.price) {
      errors.pricing.price = "Price is required";
    }
    if (values.rent && !values.pricing.deposit) {
      errors.pricing.deposit = "Deposit is required";
    }

    if (values.pricing.priceType === "AUCTION" && !values.pricing.auctionUrl) {
      errors.pricing.auctionUrl = "Auction url is required";
    }

    //DETAILS CHECK

    if (values.advertisementType !== 'OTHER') {
      if (!values.details.roomsInTotal) {
        errors.details.roomsInTotal = "Required";
      }
      
      if (!values.details.floor) {
        errors.details.floor = "Required";
      }
      
      if (!values.details.numberOfBathrooms) {
        errors.details.numberOfBathrooms = "Required";
      }

      if (!values.details.area) {
        errors.details.area = "Required";
      }

      if (values.advertisementType !== 'APARTMENT' && !values.details.plot) {
        errors.details.plot = "Required";
      }

      if (values.rent && !values.details.maximalOccupancy) {
        errors.details.maximalOccupancy = "Required";
      }
    }

    //COORDINATES CHECK

    if (values.coordinate.latitude === undefined) {
      setCoordinatesError(true);
    }
    if (values.coordinate.latitude !== undefined) {
      setCoordinatesError(false);
    }

    return errors;
  };

  const handleSubmit = useCallback(
    (values) => {
      const payload = mapValuesToPayload(identity, values);
      if (values.id) {
        handleUpdateAdvertisement(payload);
      } else {
        handleCreateAdvertisement(payload);
      }
    },
    [handleUpdateAdvertisement, identity, handleCreateAdvertisement]
  );

  return (
    <Form mode="all" onSubmit={handleSubmit} validate={validateRecordCreation}>
      <FormDataConsumer>
        {({ formData, ...rest }) => (
          <>
            <Box p={3}>
              <Grid container spacing={2}>
                <Grid xs={12} sm={12} md={6} item>
                  <BooleanInput
                    label="advertisement.rent"
                    source="rent"
                    resource="advertisement"
                    fullWidth
                  />
                  <Grid spacing={2} container>
                    <Grid sm={12} xs={12} item>
                      <Typography color="secondary" variant="h6">
                        {t("advertisement.advertisement")}
                      </Typography>
                    </Grid>
                    <Grid sm={12} xs={12} item>
                      <SelectInput
                        label="advertisement.advertisementType"
                        choices={ADVERTISEMENT_TYPE_RB_CHOICES}
                        source="advertisementType"
                        resource="advertisement"
                        fullWidth
                        FormHelperTextProps={{ style: { display: "none" } }}
                      />
                    </Grid>
                    <Grid sm={12} xs={12} item>
                      {amenitiesSell.isLoading || amenitiesRent.isLoading ? (
                        <p>Amenities loading...</p>
                      ) : (
                        <SelectArrayInput
                          label="advertisement.amenities"
                          source="amenities"
                          optionValue="name"
                          choices={
                            formData.rent
                              ? amenitiesRent.data
                              : amenitiesSell.data
                          }
                          resource="advertisement"
                          fullWidth
                        />
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Typography color="secondary" variant="h6">
                        {t("advertisement.address")}
                      </Typography>
                    </Grid>
                    <Grid md={6} sm={12} xs={12} item>
                      <TextInput
                        label="advertisement.streetName"
                        source="address.streetName"
                        resource="advertisement"
                        validate={required()}
                        fullWidth
                      />
                      <TextInput
                        label="advertisement.city"
                        source="address.city"
                        resource="advertisement"
                        validate={required()}
                        fullWidth
                      />
                      {formData?.advertisementType === "APARTMENT" && (
                        <TextInput
                          label="advertisement.appartmentNumber"
                          source="address.apartmentNumber"
                          resource="advertisement"
                          fullWidth
                        />
                      )}
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <TextInput
                        label="advertisement.streetNumber"
                        source="address.number"
                        resource="advertisement"
                        validate={
                          formData?.advertisementType !== "LAND"
                            ? required()
                            : undefined
                        }
                        fullWidth
                      />
                      <TextInput
                        label="advertisement.country"
                        source="address.country"
                        resource="advertisement"
                        validate={required()}
                        fullWidth
                      />
                      <TextInput
                        label="advertisement.additionalNumber"
                        source="address.additionalNumber"
                        resource="advertisement"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography color="secondary" variant="h6">
                        {t("advertisement.details")}
                      </Typography>
                    </Grid>
{/*                     <Grid item xs={12}>
                      <SelectInput
                        label="advertisement.bathroomTypes"
                        defaultValue="PRIVATE"
                        choices={BATHROOM_TYPES}
                        source="details.bathroomType"
                        resource="advertisement"
                        fullWidth
                      />
                    </Grid> */}
                    <Grid item md={6} sm={12} xs={12}>
                      {(formData?.advertisementType === "APARTMENT" ||
                        formData?.advertisementType === "HOUSE" ||
                        formData?.advertisementType === "OTHER") && (
                        <TextInput
                          label="advertisement.totalRooms"
                          source="details.roomsInTotal"
                          type="number"
                          validate={formData?.advertisementType !== "OTHER"
                          ? required() : undefined}
                          resource="advertisement"
                          fullWidth
                        />
                      )}
                      {(formData?.advertisementType === "APARTMENT" ||
                        formData?.advertisementType === "HOUSE" ||
                        formData?.advertisementType === "OTHER") && (
                        <TextInput
                          label="advertisement.numOfBathrooms"
                          source="details.numberOfBathrooms"
                          type="number"
                          validate={formData?.advertisementType !== "OTHER"
                          ? required() : undefined}
                          resource="advertisement"
                          fullWidth
                        />
                      )}
                      {formData?.advertisementType !== "APARTMENT" && (
                        <TextInput
                          label="advertisement.plot"
                          source="details.plot"
                          type="number"
                          validate={formData?.advertisementType !== "OTHER"
                          ? required() : undefined}
                          resource="advertisement"
                          fullWidth
                        />
                      )}
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      {(formData?.advertisementType === "APARTMENT" ||
                        formData?.advertisementType === "HOUSE" ||
                        formData?.advertisementType === "OTHER") && (
                        <TextInput
                          label={
                            formData?.advertisementType === "APARTMENT"
                              ? "advertisement.floor"
                              : "advertisement.floors"
                          }
                          source="details.floor"
                          type="number"
                          resource="advertisement"
                          validate={formData?.advertisementType !== "OTHER"
                          ? required() : undefined}
                          fullWidth
                        />
                      )}
                      {formData?.rent &&
                        (formData?.advertisementType === "APARTMENT" ||
                          formData?.advertisementType === "HOUSE" ||
                          formData?.advertisementType === "OTHER") && (
                          <TextInput
                            label="advertisement.maximalOccupancy"
                            source="details.maximalOccupancy"
                            type="number"
                            validate={formData?.advertisementType !== "OTHER"
                            ? required() : undefined}
                            resource="advertisement"
                            fullWidth
                          />
                        )}
                      {formData?.advertisementType !== "LAND" && (
                        <TextInput
                          label="advertisement.area"
                          source="details.area"
                          type="number"
                          validate={formData?.advertisementType !== "OTHER"
                          ? required() : undefined}
                          resource="advertisement"
                          fullWidth
                        />
                      )}
                    </Grid>
                    {formData?.rent &&
                      (formData?.advertisementType === "APARTMENT" ||
                        formData?.advertisementType === "HOUSE" ||
                        formData?.advertisementType === "OTHER") && (
                        <Grid item xs={12}>
                          <BooleanInput
                            label="advertisement.petsAllowed"
                            source="details.petsAllowed"
                            resource="advertisement"
                            fullWidth
                          />
                        </Grid>
                      )}
                  </Grid>
                </Grid>
                <Grid xs={12} sm={12} md={6} item>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography color="secondary" variant="h6">
                        {t("advertisement.media")}
                      </Typography>
                      <FileInput
                        source="medias"
                        folder="advertisement"
                        multiple
                        defaultValue={[]}
                        label="general.media"
                        mediasError={mediasError}
                      />
                    </Grid>
                    <Grid item xs={12} sx={{marginTop: '25px'}}>
                      <Typography color="secondary" variant="h6" sx={{marginBottom: '25px'}}>
                        {t("advertisement.video")}
                      </Typography>
                      <TextInput
                          label="advertisement.youtubeUrl"
                          source="urlYoutube"
                          type="text"
                          resource="advertisement"
                          fullWidth
                          
                        />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography color="secondary" variant="h6">
                        {t("advertisement.coordinates")}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Box pb={4}>
                        <CoordinatesInput coordinatesError={coordinatesError} />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography color="secondary" variant="h6">
                        {t("advertisement.others")}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextInput
                        multiline
                        rows={10}
                        label="advertisement.description"
                        source="description"
                        resource="advertisement"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <SelectInput
                        label="advertisement.state"
                        defaultValue="SAVED"
                        choices={ADVERTISEMENT_STATE}
                        source="state"
                        resource="advertisement"
                        validate={required()}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography color="secondary" variant="h6">
                        {t("advertisement.pricing")}
                      </Typography>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      {!formData?.rent && (
                        <SelectInput
                          label="advertisement.priceType"
                          choices={PRICE_TYPE}
                          source="pricing.priceType"
                          validate={required()}
                          resource="advertisement"
                          fullWidth
                        />
                      )}
                      {formData?.rent && (
                        <TextInput
                          label="advertisement.priceRent"
                          source="pricing.price"
                          type="number"
                          resource="advertisement"
                          validate={required()}
                          fullWidth
                        />
                      )}
                      {formData?.rent && (
                        <TextInput
                          label="advertisement.minimalRent"
                          source="pricing.minimalRent"
                          type="number"
                          resource="advertisement"
                          validate={required()}
                          fullWidth
                        />
                      )}
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      {formData?.rent && (
                        <DateInput
                          format={formatDateInputDate}
                          defaultValue={null}
                          label="advertisement.availableFrom"
                          source="pricing.availableFrom"
                          resource="advertisement"
                          validate={required()}
                          fullWidth
                        />
                      )}
                      {formData?.pricing?.priceType === PriceType.AUCTION && (
                        <>
                          <TextInput
                            label="advertisement.auctionUrl"
                            source="pricing.auctionUrl"
                            resource="advertisement"
                            validate={required()}
                            fullWidth
                          />
                        </>
                      )}
                      {!formData?.rent && (
                        <TextInput
                          label="advertisement.price"
                          source="pricing.price"
                          type="number"
                          resource="advertisement"
                          validate={required()}
                          fullWidth
                        />
                      )}
                      {formData?.rent && (
                        <TextInput
                          label="advertisement.deposit"
                          source="pricing.deposit"
                          type="number"
                          resource="advertisement"
                          validate={required()}
                          fullWidth
                        />
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Typography color="secondary" variant="h6">
                        {t("advertisement.owner")}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <UserAutocompleteInput
                        id="owner"
                        filterAttr="email"
                        searchColumn="email"
                        resource="user"
                        source="owner.email"
                        label="advertisement.owner"
                        data={data}
                        setData={setData}
                        setInputValue={setInputValue}
                      />
                    </Grid>
                    {/*
              <Grid item xs={12}>
                <Typography color="secondary" variant="h6">
                  {t("advertisement.fakeUser")}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  label="advertisement.firstName"
                  source="fakeUser.firstName"
                  resource="advertisement"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  label="advertisement.lastName"
                  source="fakeUser.lastName"
                  resource="advertisement"
                  fullWidth
                />
              </Grid>
              */}
                    {/* <Grid item xs={12}>
                    <Typography color="secondary" variant="h6">
                      {t("general.broker")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <UserAutocompleteInput
                      id="broker"
                      filterAttr="email"
                      searchColumn="email"
                      resource="user"
                      source="broker.email"
                      label="general.broker"
                    />
                  </Grid> */}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <CustomToolbar padding>
              <Box
                display="flex"
                justifyContent="space-between"
                width="100%"
                position="relative"
              >
                <SubmitButton />
              </Box>
            </CustomToolbar>
          </>
        )}
      </FormDataConsumer>
    </Form>
  );
};

export default AdvertisementForm;
