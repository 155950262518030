import UpgradeIcon from "@mui/icons-material/ArrowUpwardOutlined";
import { ButtonProps } from "ra-ui-materialui";
import {
  Button,
  Form,
  RaRecord,
  required,
  SaveButton,
  TextInput,
  useDataProvider,
  useNotify,
  useRecordContext,
  useRefresh,
  useTranslate,
} from "react-admin";
import { useCallback, useState } from "react";
import IconCancel from "@mui/icons-material/Cancel";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { Box, Grid } from "@mui/material";
import { CustomToolbar } from "../../components/ra/Toolbars";
import { useMutation } from "react-query";
import { User } from "../../types/model/model";

const SubmitButton = (props) => {
  return (
    <SaveButton
      label="user.dialog.upgrade.submitButton"
      {...props}
      alwaysEnable
    />
  );
};

const UpgradeToBrokerButtonBase = (props: ButtonProps) => {
  const record = useRecordContext<User>();
  const [showDialog, setShowDialog] = useState(false);
  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();

  const { mutate: upgradeToBroker } = useMutation(
    ["user", "upgradeToBroker", { id: record?.id }],
    (data: any) =>
      dataProvider.upgradeToBroker({
        id: record?.id,
        ...data,
      })
  );

  const handleClick = useCallback(
    (e) => {
      setShowDialog(true);
      e.stopPropagation();
      e.preventDefault();
    },
    [setShowDialog]
  );

  const handleCloseClick = useCallback(
    (e) => {
      setShowDialog(false);
      e.stopPropagation();
      e.preventDefault();
    },
    [setShowDialog]
  );

  const handleSubmit = useCallback(
    (values: Partial<RaRecord>) => {
      upgradeToBroker(
        { ...record, ...values },
        {
          onSuccess: () => {
            setShowDialog(false);
            notify("user.dialog.message.successUpgrade", { type: "success" });
            refresh();
          },
          onError: (err) => {
            console.error(err);
            notify("error.failedFetch", { type: "error" });
          },
        }
      );
    },
    [upgradeToBroker, record, notify, refresh]
  );

  return (
    <Box onClick={(e) => e.stopPropagation()}>
      <Button
        variant="text"
        onClick={handleClick}
        label="user.button.upgradeToBroker"
      >
        <UpgradeIcon />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={translate("user.dialog.upgrade.title")}
      >
        <DialogTitle>{translate("user.dialog.upgrade.title")}</DialogTitle>
        <Form mode="all" onSubmit={handleSubmit}>
          <DialogContent>
            <Grid spacing={2} container>
              <Grid item sm={6}>
                <TextInput
                  margin="none"
                  fullWidth
                  validate={[required()]}
                  label={"user.firstName"}
                  source="firstName"
                />
              </Grid>
              <Grid item sm={6}>
                <TextInput
                  margin="none"
                  fullWidth
                  validate={[required()]}
                  label={"user.lastName"}
                  source="lastName"
                />
              </Grid>
              <Grid item sm={12}>
                <TextInput
                  margin="none"
                  fullWidth
                  label={"user.phone"}
                  source="phone"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <CustomToolbar padding>
            <Box display="flex" justifyContent="space-between" width="100%">
              <Button label="ra.action.cancel" onClick={handleCloseClick}>
                <IconCancel />
              </Button>
              <SubmitButton />
            </Box>
          </CustomToolbar>
        </Form>
      </Dialog>
    </Box>
  );
};

const UpgradeToBrokerButton = () => {
  const record = useRecordContext();
  if (record?.role !== "ROLE_USER" || record?.state === "DISABLED") return null;
  return <UpgradeToBrokerButtonBase />;
};

export default UpgradeToBrokerButton;
