import { useTranslate } from "react-admin";
import { PropertyDetails } from "../../types/model/model";
import SimpleList from "./SimpleList";

const DetailSection = ({
  roomsInTotal,
  floor,
  numberOfBathrooms,
  maximalOccupancy,
  floorage,
  petsAllowed,
}: PropertyDetails) => {
  const t = useTranslate();
  const getTitle = (title: string, value?: string | number) => {
    return `${t(title)} ${value || ""}`;
  };

  return (
    <SimpleList
      items={[
        {
          title: getTitle("advertisement.rooms", roomsInTotal),
          icon: "rooms",
        },
        {
          title: getTitle("advertisement.floor", floor),
          icon: "floors",
        },
        {
          title: getTitle("advertisement.numOfBathrooms", numberOfBathrooms),
          icon: "baths",
        },
        {
          title: getTitle("advertisement.occupants", maximalOccupancy),
          icon: "occupation",
        },
        {
          title: (
            <>
              {floorage} m<sup>2</sup>
            </>
          ),
          icon: "size",
        },
        {
          title: t(
            petsAllowed
              ? `advertisement.petsAllowedState`
              : `advertisement.petsDisallowedState`
          ),
          icon: "pets",
        },
      ]}
    />
  );
};

export default DetailSection;
