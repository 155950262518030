import Dialog from "./_components/Dialog";
import ChatBox from "./_components/ChatBox";
import RebbidProvider from "./_components/ChatProvider";
import ToggleButton from "./_components/ToggleButton";
import { useCallback, useState } from "react";
import { Advertisement } from "../../../types/model/model";

const RebbidChat = () => {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");

  const handleChangeAdvertisement = useCallback(
    (advertisement: Advertisement) => {
      const t: string[] = [];
      if (advertisement?.address?.streetName) {
        t.push(advertisement.address.streetName);
      }
      if (advertisement?.address?.city) {
        t.push(advertisement.address.city);
      }
      setTitle(t.join(" - "));
    },
    []
  );

  return (
    <RebbidProvider>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        title={title}
        maxHeight={500}
        maxWidth={800}
        modal={true}
      >
        <ChatBox onChangeAdvertisement={handleChangeAdvertisement} />
      </Dialog>
      <ToggleButton onClick={() => setOpen(!open)} />
    </RebbidProvider>
  );
};

export default RebbidChat;
