import { SaveButton, Toolbar, TopToolbar } from "ra-ui-materialui";
import styled from "styled-components";

interface CustomToolbarProps {
  padding?: boolean;
}

const ToolbarStyled = styled(Toolbar)<CustomToolbarProps>(
  ({ theme, padding }) => ({
    paddingRight: 0,
    paddingLeft: 0,
    display: "flex",
    alignItems: "flex-start",
    marginRight: 0,
    marginLeft: 0,
    padding: padding ? "24px !important" : 0,
  })
);

const CustomToolbar = ({ padding, ...props }: any) => {
  return (
    <ToolbarStyled padding={padding} {...props}>
      {props?.children}
    </ToolbarStyled>
  );
};

const EditToolbar = (props) => {
  return (
    <CustomToolbar {...props}>
      <SaveButton />
    </CustomToolbar>
  );
};

const CreateToolbar = (props) => {
  return (
    <CustomToolbar {...props}>
      <SaveButton />
    </CustomToolbar>
  );
};

const CustomTopToolbar = styled(TopToolbar)(({ theme }) => ({
  paddingRight: 0,
  paddingLeft: 0,
  display: "flex",
  justifyContent: "flex-start !important",
  width: "100%",
  marginBottom: "8px !important",
}));

export { EditToolbar, CreateToolbar, CustomToolbar, CustomTopToolbar };
