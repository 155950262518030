import { Box } from "@mui/material";
import ResetPasswordButton from "./ResetPasswordButton";
import UpgradeIcon from "@mui/icons-material/ArrowUpwardOutlined";
import { CustomToolbar } from "../../components/ra/Toolbars";
import { BooleanInput, SaveButton, TextInput } from "ra-ui-materialui";
import {
  Form,
  required,
  useNotify,
  useRedirect,
  useTranslate,
  useUpdate,
  useRecordContext,
  FormDataConsumer,
  useRefresh,
  useDataProvider,
  Button
} from "react-admin";
import { useCallback } from "react";
import { useFormState } from "react-hook-form";
import { useMutation } from "react-query";
import { User } from "../../types/model/model";

const SubmitButton = (props) => {
  const { isDirty, isValid } = useFormState();
  return (
    <SaveButton
      color="secondary"
      disabled={!isDirty || !isValid}
      variant="contained"
      {...props}
    />
  );
};

const UserForm = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const t = useTranslate();
  const [updateUser] = useUpdate();
  const record = useRecordContext<User>(props);
  const refresh = useRefresh();
  const dataProvider = useDataProvider();

  const handleUpdateUser = useCallback(
    (payload) => {
      updateUser(
        "user",
        { data: payload, id: payload.id, previousData: payload },
        {
          onSuccess: () => {
            redirect(`/user/${payload.id}/show`);
            notify(t("general.resourceUpdated", { resource: "User" }), {
              type: "success",
            });
          },

          onError: (error: any) => {
            let finalErrors: string[] = [];
            error.errors.forEach((e: any) => finalErrors.push(t(e)));
            if (finalErrors.length === 0) {
              finalErrors.push(t("error.unexpected"));
            }
            const finalErrorsString = finalErrors.join("\n");
            notify(finalErrorsString, { multiLine: true, type: "error" });
          },
        }
      );
    },
    [notify, redirect, t, updateUser]
  );

  const { mutate: deactivateUser } = useMutation(
    ["user", "deactivateUser", { id: record?.id }],
    (uid: string) =>
      dataProvider.deactivateUser({
        id: record?.id,
        uid,
      })
  );

  const { mutate: activateUser } = useMutation(
    ["user", "activateUser", { id: record?.id }],
    (uid: string) =>
      dataProvider.activateUser({
        id: record?.id,
        uid,
      })
  );

  const setUserState = useCallback(
    (userStatus) => {
      userStatus === false
        ? deactivateUser(record?.uid, {
            onSuccess: () => {
              notify("user.dialog.deactivate.success", { type: "success" });
              refresh();
            },
            onError: (err) => {
              console.error(err);
              notify("error.failedFetch", { type: "error" });
            },
          })
        : activateUser(record?.uid, {
            onSuccess: () => {
              notify("user.dialog.activate.success", { type: "success" });
              refresh();
            },
            onError: (err) => {
              console.error(err);
              notify("error.failedFetch", { type: "error" });
            },
          });
    },
    [record?.uid, deactivateUser, activateUser, notify, refresh]
  );

  const handleSubmit = useCallback(
    (values) => {
      const payload = values;
      const userStatus =
        values.state === "ENABLED" || values.state === true ? true : false;

      handleUpdateUser(payload);
      setUserState(userStatus);
    },
    [handleUpdateUser, setUserState]
  );

  const { mutate: upgradeToBroker } = useMutation(
    ["user", "upgradeToBroker", { id: record?.id }],
    (data: any) =>
      dataProvider.upgradeToBroker({
        id: record?.id,
        ...data,
      })
  );

  const handleUpgrade = useCallback(
    () => {
      upgradeToBroker(
        { ...record },
        {
          onSuccess: () => {
            notify("user.dialog.message.successUpgrade", { type: "success" });
            refresh();
          },
          onError: (err) => {
            console.error(err);
            notify("error.failedFetch", { type: "error" });
          },
        }
      );
    },
    [upgradeToBroker, record, notify, refresh]
  );

  return (
    <Form mode="all" onSubmit={handleSubmit}>
      <FormDataConsumer>
        {({ formData, ...rest }) => (
          <>
            <Box pl="2em" pt={"2em"} pr="2em">
              <Box display="flex">
                <Box flex={1} mr="0.5em">
                  <TextInput
                    fullWidth
                    validate={required()}
                    label={"user.firstName"}
                    source="firstName"
                  />
                  <TextInput
                    fullWidth
                    label={"user.email"}
                    source="email"
                    disabled
                  />
                  <TextInput
                    fullWidth
                    validate={required()}
                    label={"user.profilePicture"}
                    source="photo"
                    disabled
                  />
                  <BooleanInput
                    label={
                      formData.state === true ? "user.enabled" : "user.disabled"
                    }
                    source="state"
                  />
                </Box>
                <Box flex={1} ml="0.5em">
                  <TextInput
                    fullWidth
                    validate={required()}
                    label={"user.lastName"}
                    source="lastName"
                  />
                  <TextInput fullWidth label={"user.phone"} source="phone" />
                  <TextInput
                    fullWidth
                    label={"user.password"}
                    source="password"
                    disabled
                  />
                  <BooleanInput
                    label={
                      formData.emailVerified === true
                        ? "user.emailVerified"
                        : "user.emailNotVerified"
                    }
                    source="emailVerified"
                    disabled
                  />
                </Box>
              </Box>
              {record?.role !== "ROLE_USER" ||
              record?.state === "DISABLED" ? null : (
                <Button
                  variant="text"
                  onClick={handleUpgrade}
                  label="user.button.upgradeToBroker"
                >
                  <UpgradeIcon />
                </Button>
              )}
            </Box>
            <CustomToolbar padding>
              <Box display="flex" justifyContent="space-between" width="100%">
                <SubmitButton />
                {props?.record?.id && <ResetPasswordButton />}
              </Box>
            </CustomToolbar>
          </>
        )}
      </FormDataConsumer>
    </Form>
  );
};

export default UserForm;
