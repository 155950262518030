import { CircularProgress, styled } from "@mui/material";
import useFileUrl from "../../hooks/useFileUrl";

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const ImgRounded = styled("img")<{ size: number }>(({ theme, size }) => ({
  height: size,
  width: size,
  objectFit: "cover",
  borderRadius: "90%",
  overflow: "hidden",
}));

const ImgSquare = styled("img")<{ size: number }>(({ theme, size }) => ({
  height: size,
  width: size,
  objectFit: "cover",
  borderRadius: 0,
  overflow: "hidden",
}));

const Loader = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.secondary.main,
  margin: "auto",
}));

const ImagePreview = ({ url: path, square = false, size = 25 }) => {
  const { url, loading } = useFileUrl(path);
  if (loading) return <Loader size={size} />;

  return url ? (
    <Container>
      {square ? (
        <ImgSquare size={size} src={url} alt="preview" />
      ) : (
        <ImgRounded size={size} src={url} alt="preview" />
      )}
    </Container>
  ) : null;
};

export default ImagePreview;
