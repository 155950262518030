import { AppConfig } from "../../types/types";
import { rbTheme } from "./theme";

let messagesRefetchInterval;

const getEnv = (env): string => {
  return process.env[env] || "";
};

try {
  messagesRefetchInterval =
    parseInt(getEnv("REACT_APP_CHAT_MESSAGES_REFETCH_INTERVAL")) || 5000;
} catch (err) {
  messagesRefetchInterval = 5000;
  console.warn(err);
}

const config: AppConfig = {
  chat: {
    messagesRefetchInterval,
  },
  google: {
    mapSearchUrl: "https://www.google.com.sa/maps/search",
    dynamicLink: "https://firebasedynamiclinks.googleapis.com/v1/shortLinks",
  },
  VAPID_KEY: getEnv("REACT_APP_FIREBASE_VAPID_KEY"),
  firebase: {
    apiKey: getEnv("REACT_APP_FIREBASE_API_KEY"),
    authDomain: getEnv("REACT_APP_FIREBASE_AUTH_DOMAIN"),
    databaseURL: getEnv("REACT_APP_FIREBASE_DATABASE_URL"),
    projectId: getEnv("REACT_APP_FIREBASE_PROJECT_ID"),
    storageBucket: getEnv("REACT_APP_FIREBASE_STORAGE_BUCKET"),
    messagingSenderId: getEnv("REACT_APP_FIREBASE_MESSAGING_SENDER_ID"),
    appId: getEnv("REACT_APP_FIREBASE_APP_ID"),
    measurementId: getEnv("REACT_APP_FIREBASE_MEASUREMENT_ID"),
  },
  dynamicLink: {
    urlHost: getEnv("REACT_APP_DYNAMICLINK_URL_HOST"),
    domainURIPrefix: getEnv("REACT_APP_DYNAMICLINK_DOMAIN_URI_PREFIX"),
    appStoreID: getEnv("REACT_APP_DYNAMICLINK_APP_STORE_ID"),
    androidPackageName: getEnv("REACT_APP_DYNAMICLINK_ANDROID_PACKAGE_NAME"),
  },
  URL_API: getEnv("REACT_APP_API_URL"),
  httpHeaders: {
    call: getEnv("REACT_APP_HTTP_CLIENT_CALL"),
    version: getEnv("REACT_APP_HTTP_CLIENT_VERSION"),
  },
  resources: {
    advertisement: "adv/backoffice",
    office: "account/backoffice/office",
    user: "account/backoffice/users",
    video: "adv/backoffice/video",
    "push-notification": "notification/push",
  },
  theme: rbTheme,
};

export default config;
