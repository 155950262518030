import { useCallback, useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import MdPhotoCamera from "@mui/icons-material/PhotoCamera";
import MdSend from "@mui/icons-material/Send";
import styled from "styled-components";
import useText from "../_hooks/useText";
import Button from "../../../common/Button";
import IconButton from "../../../common/IconButton";
import {
  ButtonType,
  ButtonVariant,
  Size,
  ThemeColor,
} from "../../../../types/enums";
import { useCreate, useGetIdentity } from "ra-core";

const MessageInput = styled.textarea(({ theme }) => ({
  display: "block",
  width: "75%",
  height: 50,
  resize: "none",
  padding: "0rem 0.75rem",
  paddingTop: theme.spacing(1),
  color: "#495057",
  backgroundColor: "#fff",
  backgroundClip: "padding-box",
  borderWidth: 0,
  outline: "none",
}));

const UploadButtonContainer = styled.span(({ theme }) => ({
  paddingLeft: 4,
  paddingRight: 4,
}));

const MessageFormBase = styled.form<{ onSubmit: any }>(({ theme }) => ({
  maxWidth: "100%",
  margin: 0,
  padding: 0,
  height: 50,
}));

const InputContainer = styled.div(({ theme }) => ({
  maxWidth: "100%",
  margin: 0,
  display: "flex",
  alignItems: "center",
}));

type MessageFormProps = {
  allowUpload?: boolean;
  chatId?: number;
  advOwnerId?: string | number;
  onSendMessage: (data: any) => void;
  onLoading?: (loading: boolean) => void;
};

function MessageForm({
  allowUpload = false,
  onSendMessage,
  chatId,
  onLoading,
  advOwnerId,
}: MessageFormProps) {
  const [message, setMessage] = useState("");
  const t = useText();
  const formRef = useRef<HTMLFormElement>(null);
  // const [typeAsOwner, setTypeAsOwner] = useState(false);
  const { identity } = useGetIdentity();
  const [createMessage, { isLoading: loading }] = useCreate();

  const handleAddMessage = useCallback(
    (event: any) => {
      event.preventDefault();
      if (!chatId || !identity?.id) return;

      // const senderId = typeAsOwner ? advOwnerId : identity.id;
      // if (!senderId) return;
      if (!identity?.id) return null;

      const messageObject = {
        chatId,
        senderUid: identity.id,
        value: message,
        type: "TEXT_MESSAGE",
      };
      createMessage(
        "msg",
        { data: messageObject },
        {
          onSuccess: () => {
            onSendMessage(messageObject);
          },
        }
      );

      setMessage("");
    },
    [
      message,
      onSendMessage,
      // typeAsOwner,
      // advOwnerId,
      identity,
      chatId,
      createMessage,
    ]
  );

  useEffect(() => {
    onLoading && onLoading(loading);
  }, [loading, onLoading]);

  const onEnterPress = useCallback(
    (event: any) => {
      if (
        (event.keyCode === 13 || event.which === 13) &&
        event.shiftKey === false
      ) {
        handleAddMessage(event);
      }
    },
    [handleAddMessage]
  );

  return (
    <MessageFormBase ref={formRef} onSubmit={handleAddMessage}>
      {/* <TypeAsOwnerCheckbox onClick={() => setTypeAsOwner(!typeAsOwner)} /> */}
      <InputContainer>
        <MessageInput
          onKeyDown={onEnterPress}
          value={message}
          onChange={(event) => setMessage(event.target.value)}
          placeholder={t("type.message")}
        />
        {allowUpload && (
          <UploadButtonContainer>
            <IconButton size={Size.MD}>
              <MdPhotoCamera />
            </IconButton>
          </UploadButtonContainer>
        )}
        <Button
          height={50}
          variant={ButtonVariant.CONTAINED}
          type={ButtonType.SUBMIT}
          color={ThemeColor.SECONDARY}
          rightIcon={<MdSend />}
        >
          {t("send")}
        </Button>
      </InputContainer>
    </MessageFormBase>
  );
}

export default MessageForm;
