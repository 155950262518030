import { SET_RESOURCE_MODAL_DIALOG_STATE } from "./actions";
import { ResourceModalDialogState } from "./ResourceModalDialogStore";

const reducers = (state: ResourceModalDialogState, action: any) => {
  switch (action.type) {
    case SET_RESOURCE_MODAL_DIALOG_STATE:
      return {
        ...state,
        ...action.state,
      };
  }
};

export default reducers
