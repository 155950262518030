import {
  useNotify,
  useCreate,
  useRedirect,
  useUpdate,
  SaveButton,
  useTranslate,
  TextInput,
  Form,
  required,
  number,
} from "react-admin";
import { Box, Grid } from "@mui/material";
import { CustomToolbar } from "../../components/ra/Toolbars";
import FileInput from "../../components/ra/inputs/FileInput";
import SectionTitle from "../../components/common/SectionTitle";
import { useCallback } from "react";
import CoordinatesInput from "../../components/ra/inputs/CoordinatesInput";
import { useFormState } from "react-hook-form";

const SubmitButton = (props) => {
  const { isDirty, isValid } = useFormState();
  return (
    <SaveButton color="secondary" disabled={!isDirty || !isValid} {...props} />
  );
};

const OfficeForm = (props) => {
  const t = useTranslate();
  const notify = useNotify();
  const redirect = useRedirect();
  const [createOffice] = useCreate();
  const [updateOffice] = useUpdate();

  const handleUpdateOffice = useCallback(
    (payload) => {
      updateOffice(
        "office",
        { id: payload.id, data: payload },
        {
          onSuccess: (data) => {
            notify(t("general.resourceUpdated", { resource: "office" }), {
              type: "success",
            });
            redirect(`/office/${payload.id}/show`);
          },
          onError: (error) => {
            notify(t("error.unexpected"), { type: "error" });
          },
        }
      );
    },
    [t, notify, redirect, updateOffice]
  );

  const handleCreateOffice = useCallback(
    (payload) => {
      createOffice(
        "office",
        { data: payload },
        {
          onSuccess: (data) => {
            redirect("/office");
            notify(t("general.resourceCreated", { resource: "office" }), {
              type: "success",
            });
          },
          onError: (error) => {
            notify(t("error.unexpected"), { type: "error" });
          },
        }
      );
    },
    [t, notify, redirect, createOffice]
  );

  const handleSubmit = useCallback(
    (values) => {
      const payload = values;
      if (payload?.id) {
        handleUpdateOffice(payload);
      } else {
        handleCreateOffice(payload);
      }
    },
    [handleCreateOffice, handleUpdateOffice]
  );

  return (
    <Form mode="all" onSubmit={handleSubmit}>
      <Box>
        <Box p={2}>
          <Grid container spacing={2}>
            <Grid xs={12} sm={12} md={6} item>
              <TextInput
                validate={[required()]}
                label="office.name"
                source="name"
                fullWidth
              />
              <TextInput
                validate={[required()]}
                source="contactPerson"
                fullWidth
              />

              <TextInput validate={[required()]} source="phone" fullWidth />
              <TextInput validate={[required()]} source="email" fullWidth />
              <TextInput validate={[required()]} source="street" fullWidth />
              <TextInput
                validate={[required(), number()]}
                source="streetNumber"
                fullWidth
              />
              <TextInput validate={[required()]} source="city" fullWidth />
              <TextInput validate={[required()]} source="zipCode" fullWidth />
            </Grid>
            <Grid xs={12} sm={12} md={6} item>
              <Box mt={2}>
                <SectionTitle>{t("office.upload")}</SectionTitle>
              </Box>
              <FileInput folder="office" source="logo" label="office.logo" />
              <Box mt={2} mb={2}>
                <SectionTitle>{t("office.coordinates")}</SectionTitle>
              </Box>

              <CoordinatesInput />
            </Grid>
          </Grid>
        </Box>
        <CustomToolbar padding>
          <Box display="flex" justifyContent="space-between" width="100%">
            <SubmitButton />
          </Box>
        </CustomToolbar>
      </Box>
    </Form>
  );
};

export default OfficeForm;
