import { styled, Typography } from "@mui/material";
import { useTranslate } from "ra-core";
import { Pricing } from "../../types/model/model";
import { formatMoney } from "../../utils/utils";

const List = styled("ul")(({ theme }) => ({
  padding: 0,
  "& > li": {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: 4,
    paddingBottom: 4,
    borderBottomStyle: "solid",
    borderBottomColor: theme.palette.grey[300],
    borderBottomWidth: 1,
  },
}));

type Props = {
  rent: boolean;
  pricing: Pricing;
};

const PricingSection = ({ pricing, rent }: Props) => {
  const t = useTranslate();
  return (
    <List>
      <li>
        <Typography>{t("advertisement.rent")}</Typography>
        <Typography>{rent ? "yes" : "no"}</Typography>
      </li>
      <li>
        <Typography>{t("advertisement.price")}</Typography>
        <Typography>{formatMoney(pricing?.price)}</Typography>
      </li>

      <li>
        <Typography>{t("advertisement.deposit")}</Typography>
        <Typography>{formatMoney(pricing?.deposit)}</Typography>
      </li>

      {pricing?.minimalRent && (
        <li>
          <Typography>{t("advertisement.minimalRent")}</Typography>
          {pricing?.minimalRent && (
            <Typography>
              {pricing?.minimalRent} {t("advertisement.months")}
            </Typography>
          )}
        </li>
      )}

      <li>
        <Typography>{t("advertisement.priceType")}</Typography>
        <Typography>{pricing?.priceType}</Typography>
      </li>

      {pricing?.auctionUrl && pricing?.auctionUrl !== "null" && (
        <li>
          <Typography>{t("advertisement.auctionUrl")}</Typography>
          <a href={pricing?.auctionUrl} rel="noopener noreferer">
            {pricing?.auctionUrl}
          </a>
        </li>
      )}
    </List>
  );
};

export default PricingSection;
