import React from "react";
import { styled } from "@mui/material";
import useFileUrl from "../../hooks/useFileUrl";

const Icon = styled("img")(({ theme }) => ({
  height: 25,
}));

interface IconProps {
  name: string;
  iconUrl: string
}

const SvgIcon: React.FC<IconProps> = ({ name, iconUrl }): JSX.Element | null => {
  
  function GetFirebaseUrl() {
    const FIREBASE_STORAGE = process.env.REACT_APP_FIREBASE_STORAGE_FOLDER
    const { url } = useFileUrl(`${FIREBASE_STORAGE}/${iconUrl}`);
    return url
  }

  return <Icon alt={name} src={iconUrl === undefined ? `./svg/${name}.svg` : GetFirebaseUrl()} />;
};

export default SvgIcon;
