import { ShowActions } from "../../components/ra/Actions";
import OfficeDetail from "../../modules/office/OfficeDetail";
import OfficeBrokerManagement from "../../modules/office/OfficeBrokerManagement";
import { TabbedShowLayout, Tab, Show } from "react-admin";
import BrokerIcon from "@mui/icons-material/GroupOutlined";
import GeneralIcon from "@mui/icons-material/DescriptionOutlined";

const OfficeShow = ({ ...props }) => {
  return (
    <Show actions={<ShowActions />} {...props} title="office.showTitle">
      <TabbedShowLayout>
        <Tab icon={<GeneralIcon />} label="office.general">
          <OfficeDetail {...props} />
        </Tab>
        <Tab icon={<BrokerIcon />} label="office.brokers">
          <OfficeBrokerManagement />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default OfficeShow;
