import { Media } from "../../../types/model/model";
import LightGallery from "lightgallery/react";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import LightGalleryComponent from "./LightGalleryComponent";
import { useCallback, useEffect, useRef, useState } from "react";

type ImageGalleryProps = {
  medias: Media[];
};

const MEDIA_TYPES = ["VIDEO", "PHOTO"];

const ImageGallery = ({ medias = [] }: ImageGalleryProps) => {
  const allowedMedias = medias.filter((m) => MEDIA_TYPES.includes(m.type));
  //refresh gallery
  const lightGallery = useRef<any>(null);
  const [loadingState, setLoadingState] = useState(
    allowedMedias.reduce((acc, _, index) => {
      acc[index] = true;
      return acc;
    }, {})
  );

  useEffect(() => {
    lightGallery.current.refresh();
  }, [loadingState]);

  const onInit = useCallback((detail: any) => {
    if (detail) {
      lightGallery.current = detail.instance;
    }
  }, []);

  if (!medias.length) {
    return null;
  }

  return (
    <LightGallery
      speed={500}
      plugins={[lgThumbnail, lgZoom]}
      onInit={onInit}
      elementClassNames="lightGalleryWrapper"
    >
      {allowedMedias.map((media, index) => (
        <LightGalleryComponent
          media={media.url}
          key={media.id}
          index={index}
          setLoadingState={setLoadingState}
        />
      ))}
    </LightGallery>
  );
};

export default ImageGallery;
