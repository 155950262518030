import { IconButton, ListItem, ListItemSecondaryAction } from "@mui/material";
import { styled } from "@mui/material";
import { DeleteOutline } from "@mui/icons-material";
import ImagePreview from "../Image";
import MarkAsPreviewOverlay from "./MarkAsPreviewOverlay";
import { Media } from "../../../types/model/model";
import Video from "../Video";

const IMAGE_GALLERY_HEIGHT = 120;

const CustomListItem = styled(ListItem)(({ theme }) => ({
  paddingLeft: 4,
  position: "relative",
  height: IMAGE_GALLERY_HEIGHT,
}));

type Props = {
  file: Media;
  onDeleteClick: (file: Media) => void;
  onAttachmentClick?: (file: Media) => void;
};

const Attachment = ({ file, onDeleteClick, onAttachmentClick }: Props) => {
  return (
    <CustomListItem key={file.url}>
      {file.type === "VIDEO" ? (
        <Video
          width={`${IMAGE_GALLERY_HEIGHT}px`}
          height={`${IMAGE_GALLERY_HEIGHT}px`}
          controls={true}
          url={file.url}
        />
      ) : (
        <ImagePreview src={file.url} />
      )}
      {onAttachmentClick && file.type !== "VIDEO" && (
        <MarkAsPreviewOverlay
          preview={file.preview}
          onClick={() => onAttachmentClick(file)}
        />
      )}
      {onDeleteClick && (
        <ListItemSecondaryAction onClick={() => onDeleteClick(file)}>
          <IconButton size="small" edge="end" aria-label="delete">
            <DeleteOutline />
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </CustomListItem>
  );
};

export default Attachment;
