import { ReactElement } from "react";
import { styled } from "@mui/material";

interface StyleProp {
  size?: number;
  active?: boolean;
  color?: "primary" | "secondary" | undefined;
}

const Base = styled("div")<StyleProp>(({ theme, color, size }) => ({
  borderRadius: "90%",
  width: size,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  overflow: "hidden",
  boxSizing: "border-box",
  height: size,
  transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
  cursor: "pointer",
  zIndex: "auto",
  color: "inherit",
  backgroundColor: color ? theme.palette[color].main : theme.palette.grey[300],
}));

const BaseActive = styled(Base)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  zIndex: 2,
  color: "#fff",
}));

interface AvatarProps extends StyleProp {
  children: ReactElement | string;
  className?: string;
}

function Avatar({ active = false, size = 40, children, color }: AvatarProps) {
  return active ? (
    <BaseActive size={size} color={color}>
      {children}
    </BaseActive>
  ) : (
    <Base size={size} color={color}>
      {children}
    </Base>
  );
}

export default Avatar;
