import useFileUrl from "../../hooks/useFileUrl";
import { Loading } from "ra-ui-materialui";
import { styled } from "@mui/material";
import { useTranslate } from "react-admin";

const Base = styled("div")(({ theme, h }: any) => ({
  color: theme.palette.grey[400],
  background: "rgba(0,0,0,0.5)",
  width: "100%",
  height: h || "100%",
  textAlign: "center",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "x-small",
}));

const Img = styled("img")(({ theme, h }: any) => ({
  color: theme.palette.common.white,
  background: "rgba(0,0,0,0.5)",
  width: "100%",
  height: h || "100%",
  objectFit: "cover",
  textAlign: "center",
  display: "flex",
  objectPosition: "center",
  justifyContent: "center",
  alignItems: "center",
}));

type ImageProps = {
  src?: string;
  className?: string;
  h?: string | number;
};

const Image = ({ src, h, ...rest }: ImageProps) => {
  const { url, loading, error } = useFileUrl(src);
  const t = useTranslate();

  if (loading) return <Loading />;

  if (error?.code === "storage/object-not-found") {
    return <Base>{t("error.firebase.notFound")}</Base>;
  }

  if (error) return null;

  return <Img {...rest} src={url} alt="galery_image" />;
};

export default Image;
