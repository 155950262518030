import { useCreate, useNotify, useTranslate } from "ra-core";
import { useCallback } from "react";
import { AppResource } from "../types/enums";
import { PushNotification } from "../types/types";

const usePushNotification = () => {
  const t = useTranslate();
  const notify = useNotify();
  const [createPushNotification, { isLoading: loading, error }] = useCreate();

  const sendPushNotification = useCallback(
    (payload: PushNotification, onSuccess?, onError?) => {
      createPushNotification(
        AppResource.PushNotification,
        { data: payload },
        {
          onSuccess: ({ data }) => {
            notify(t("pushNotification.sendSuccess"), { type: "success" });
            onSuccess && onSuccess(data);
          },
          onError: (error: any) => {
            notify(error?.message, { type: "error" });
            onError && onError();
          },
        }
      );
    },
    [createPushNotification, t, notify]
  );

  return { sendPushNotification, loading, error };
};

export default usePushNotification;
