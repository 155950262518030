import { ReactElement } from "react";
import { ReactNode } from "react";
import styled from "styled-components";
import Fade from "../../../../common/Fade";
import DialogActions from "./DialogActions";
import DialogHeader from "./DialogHeader";

const DialogPanel = styled.div<{
  maxWidth?: number;
  maxHeight?: number | "auto";
}>(({ theme, maxWidth, maxHeight }) => ({
  background: theme.palette.common.white,
  width: "100%",
  maxWidth: maxWidth,
  maxHeight: maxHeight,
  boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
  height: "auto",
  position: "fixed",
  borderRadius: 4,
  overflow: "hidden",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  margin: "auto",
  zIndex: 1002,
}));

const DialogContent = styled.div<{
  padding?: number;
  maxHeight?: number | "auto";
}>(({ theme, padding, maxHeight }) => ({
  padding: theme.spacing(padding),
  background: theme.palette.common.white,
  overflowY: "auto",
  maxHeight,
}));

type DialogBaseProps = {
  modal?: boolean;
  children: ReactNode;
  title?: ReactElement | string;
  maxHeight?: number | "auto";
  maxWidth?: number;
  actions?: ReactElement | ReactElement[];
  onClose: () => void;
  open?: boolean;
};

const DialogBase = ({
  children,
  title,
  maxHeight = 300,
  maxWidth = 400,
  onClose,
  actions,
}: DialogBaseProps) => {
  return (
    <DialogPanel onClick={(e) => e.stopPropagation()} maxWidth={maxWidth}>
      {title !== undefined && (
        <DialogHeader onClose={onClose}>{title}</DialogHeader>
      )}
      <DialogContent maxHeight={maxHeight}>{children}</DialogContent>
      {actions && <DialogActions>{actions}</DialogActions>}
    </DialogPanel>
  );
};

type DialogProps = {
  modal?: boolean;
  children: ReactNode;
  title?: ReactElement | string;
  maxHeight?: number | "auto";
  maxWidth?: number;
  actions?: ReactElement | ReactElement[];
  onClose: () => void;
  open?: boolean;
};

function Dialog({
  modal = false,
  open = true,
  children,
  onClose,
  ...rest
}: DialogProps) {
  if (!open) return null;

  if (modal) {
    return (
      <Fade onClick={onClose}>
        <DialogBase onClose={onClose} {...rest}>
          {children}
        </DialogBase>
      </Fade>
    );
  }

  return (
    <DialogBase onClose={onClose} {...rest}>
      {children}
    </DialogBase>
  );
}

export default Dialog;
