import { stringify } from "query-string";
import { createQueryParams, mapResource } from "./provider.utils";

const advertisementProvider = ({ apiUrl, httpClient }) => {
  return {
    getList: (resource, params) => {
      const query = createQueryParams(params);
      const url = `${apiUrl}/${mapResource(resource)}/all?${stringify(query)}`;

      return httpClient(url, {}).then(
        ({ headers, json }: { headers: any; json: any }) => {
          return {
            data: json.content,
            total: json.totalElements,
          };
        }
      );
    },
    update: (resource, params) => {
      return httpClient(
        `${apiUrl}/${mapResource(resource)}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ...params.data }),
        },
        { raFetch: false }
      )
        .then((res) => {
          return res.json();
        })
        .then((json) => {
          return { data: json };
        });
    },
    create: (resource, params) => {
      return httpClient(
        `${apiUrl}/${mapResource(resource)}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ...params.data }),
        },
        { raFetch: false }
      )
        .then((res) => {
          return res.json();
        })
        .then((json) => {
          return { data: json };
        });
    },
  };
};

export default advertisementProvider;
