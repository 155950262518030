import LocationIcon from "@mui/icons-material/RoomOutlined";
import { styled, Typography } from "@mui/material";
import { ButtonProps } from "ra-ui-materialui";
import { useRecordContext } from "react-admin";
import { useGoogleMapsLocation } from "../../../hooks/useGoogleMapsLocation";

const Anchor = styled("a")(({ theme }) => ({
  display: "flex",
  padding: 4,
  paddingLeft: 0,
  alignItems: "center",
}));

const CustomTypography = styled(Typography)(({ theme }) => ({
  fontSize: "0.8rem",
}));

const Icon = styled(LocationIcon)(({ theme }) => ({
  fontSize: "0.8rem",
}));

const LocationLinkBase = (props: ButtonProps) => {
  const record = useRecordContext();

  const url = useGoogleMapsLocation({
    lon: record?.coordinate?.longitude,
    lat: record?.coordinate?.latitude,
  });

  return (
    <Anchor href={url} target="_blank" rel="noreferrer noopener">
      <Icon />
      <CustomTypography>
        {record?.coordinate?.latitude}, {record?.coordinate?.longitude}
      </CustomTypography>
    </Anchor>
  );
};

const LocationLink = (props: ButtonProps) => {
  const record = useRecordContext();
  if (!record?.coordinate?.latitude || !record?.coordinate?.longitude)
    return null;
  return <LocationLinkBase />;
};

export default LocationLink;
