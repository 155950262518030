import { useCallback, useEffect, useState } from "react";
import {
  GetListResult,
  RaRecord,
  useDataProvider,
  useTranslate,
} from "react-admin";
import { TextField, Button, Box } from "@mui/material";
import PersonAddOutlined from "@mui/icons-material/PersonAddOutlined";
import { Autocomplete } from "@mui/material";

const ROLE_ALLOW_LIST = ["ROLE_USER_BROKER"];
const SEARCH_TEXT_MIN_LENGTH = 2;
const ITEMS_LIMIT = 1000;

type Props = {
  resource: string;
  id: string | number;
  label: string;
  filterAttr: string;
  onUserPick: (user) => any;
};

const UserToOfficePanel = ({
  resource,
  id = "user",
  label = "",
  onUserPick,
  filterAttr = "q",
}: Props) => {
  const [filter, setFilter] = useState("");
  const [data, setData] = useState<RaRecord[]>([]);
  const [value, setValue] = useState("");
  const dataProvider = useDataProvider();
  const t = useTranslate();

  useEffect(() => {
    if (filter.length < SEARCH_TEXT_MIN_LENGTH) {
      return;
    }

    dataProvider
      .getList(resource, {
        pagination: {
          page: 0,
          perPage: ITEMS_LIMIT,
        },
        sort: {
          field: filterAttr,
          order: "ASC",
        },
        filter: {
          [filterAttr]: filter,
        },
      })
      .then((records: GetListResult) => {
        setData(records?.data?.filter((d) => ROLE_ALLOW_LIST.includes(d.role)));
      });
  }, [filter, dataProvider, filterAttr, resource]);

  const handleUserPick = useCallback(
    (e, val) => {
      setValue(val || e?.target?.value);
    },
    [setValue]
  );

  const handleAddBrokerToOffice = useCallback(() => {
    const user = data?.find((d) => value === d[filterAttr]);
    typeof onUserPick === "function" && onUserPick(user);
  }, [value, data, filterAttr, onUserPick]);

  return (
    <Box display="flex" alignItems="center">
      <Box flex="1" display="flex" alignItems="center" mr={2}>
        <Autocomplete
          fullWidth
          value={value}
          id={`autocomplete-input-${id}`}
          onChange={handleUserPick}
          options={data.map((x) => x[filterAttr] || "")}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                label={label && t(label)}
                id={`autocomplete-input-field-${id}`}
                onChange={(e) => setFilter(e.target.value)}
                margin="dense"
                size="small"
                variant="filled"
              />
            );
          }}
        />
      </Box>
      <Box flex="2" display="flex" alignItems="center">
        <Button
          disabled={value ? false : true}
          endIcon={<PersonAddOutlined />}
          variant="outlined"
          onClick={handleAddBrokerToOffice}
        >
          {t("office.addBrokerButton")}
        </Button>
      </Box>
    </Box>
  );
};

export default UserToOfficePanel;
