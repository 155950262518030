import { DataProvider } from "ra-core";
import baseProviderDefinition from "./base.provider";
import useHttpClient from "../http-client";
import useConfig from "../../hooks/useConfig";

/**
 *
 * Merge API providers to one provider for react-admin APP componenent
 *
 */
const useApiProvider = (
  customProviders: Record<string, (props) => Record<string, any>>
): DataProvider => {
  const httpClient = useHttpClient();
  const { URL_API } = useConfig();

  const custom = Object.entries(customProviders).reduce(
    (prev, [key, value]: [string, any]) => {
      if (!value) {
        return prev;
      }
      return {
        ...prev,
        [key]: value({
          apiUrl: URL_API,
          httpClient,
        }),
      };
    },
    {}
  );

  return baseProviderDefinition(URL_API, httpClient, custom);
};

export default useApiProvider;
