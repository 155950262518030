import { GetListParams } from "react-admin";
import { QueryListParams } from "../../types/types";
import config from "../config/config";

export const createQueryParams = (params: GetListParams): QueryListParams => {
  const { filter, pagination, sort } = params;
  const { page, perPage } = pagination;
  const { field, order } = sort;
  return {
    page: page - 1,
    size: perPage,
    sort: [field, order].join(","),
    ...filter,
  };
};

export const mapResource = (resource) => {
  return (config?.resources || {})[resource] || resource;
};
