import ExternalAdvertisementAvatarField from "../../modules/advertisement/ImagePreviewField";
import RebbidList from "../../components/ra/List";
import {
  Datagrid,
  DateField,
  FunctionField,
  SimpleList,
  TextField,
} from "ra-ui-materialui";
import { useMediaQuery } from "@mui/material";
import ExternalLinkField from "../../components/ra/fields/ExternalLinkField";
import AdvertisementStateField from "../../modules/advertisement/StateField";

export const AdvertisementExternalList = ({ ...props }) => {
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));

  return (
    <RebbidList
      title={"advertisement.externalAdvertisementListTitle"}
      {...props}
    >
      {isSmall ? (
        <SimpleList
          primaryText={(record) => record.address}
          secondaryText={(record) => record.state}
          tertiaryText={(record) =>
            record.monthlyPayment && `${record.monthlyPayment} €`
          }
        />
      ) : (
        <Datagrid bulkActionButtons={false}>
          <ExternalAdvertisementAvatarField
            sortable={false}
            label={"general.photo"}
            source="previewPhoto"
          />
          <TextField label={"general.id"} source="id" />
          <TextField label={"general.detailId"} source="extId" />
          <FunctionField
            sortBy="state"
            label="general.state"
            render={(props) => <AdvertisementStateField {...props} />}
          />
          <TextField
            label={"general.advertisementType"}
            source="advertisementType"
          />
          <TextField label={"general.totalRooms"} source="roomsInTotal" />
          <TextField label={"general.monthly"} source="monthlyPayment" />
          <TextField label={"general.address"} source="address" />
          <DateField label={"general.created"} source="createdAt" />
          <ExternalLinkField label="general.detailUrl" source="detailUrl" />
        </Datagrid>
      )}
    </RebbidList>
  );
};

export default AdvertisementExternalList;
