import { Box, Typography } from "@mui/material";
import { useTranslate } from "ra-core";
import { Rating } from "../../types/model/model";
import { Rating as MuiRating } from "@mui/material";

type Props = {
  rating: Rating;
};

const RatingSection = ({ rating }: Props) => {
  const t = useTranslate();
  return (
    <Box>
      {rating?.globalRate && (
        <Box mb={2} borderColor="transparent">
          <Typography component="legend">
            {t("advertisement.globalRate")}
          </Typography>
          <MuiRating value={rating.globalRate} readOnly />
        </Box>
      )}

      {rating?.categoryRating &&
        Object.entries(rating.categoryRating).map(([key, value]) => {
          return (
            <Box mb={2} borderColor="transparent">
              <Typography component="legend">{key}</Typography>
              <MuiRating name={key} value={value} readOnly />
            </Box>
          );
        })}
    </Box>
  );
};

export default RatingSection;
