import { Typography, styled } from "@mui/material";

const Title = styled(Typography)(
  ({ theme }) => `
    border-bottom-style: solid;
    border-bottom-color: ${theme.palette.secondary.main};
    border-bottom-width: 1;
`
);

type Props = {
  children: string;
};

const SectionTitle = ({ children }: Props) => {
  return (
    <Title color="secondary" variant="h3" gutterBottom>
      {children}
    </Title>
  );
};

export default SectionTitle;
