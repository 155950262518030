import { DeleteWithConfirmButton, TextField } from "react-admin";
import Datagrid from "../../components/ra/Datagrid";
import RaList from "../../components/ra/List";
import LocationButton from "../../components/ra/buttons/LocationIconButton";
import ImageAvatarField from "../../components/ra/fields/ImageField";
import { IconEditButton } from "../../components/ra/buttons/ListIconButtons";
import { useMediaQuery } from "@mui/material";

const OfficeList = ({ ...props }) => {
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));

  return (
    <RaList
      sort={{ field: "id", order: "DESC" }}
      title={"office.listTitle"}
      {...props}
    >
      {isSmall ? (
        <Datagrid bulkActionButtons={false} rowClick="show">
          <IconEditButton />
          <TextField label="general.id" source="id" />
          <ImageAvatarField
            label={"office.logo"}
            sortable={false}
            source="logo"
          />
          <TextField label="office.name" source="name" />
          <TextField label="office.address" source="address" />
          <LocationButton />
        </Datagrid>
      ) : (
        <Datagrid bulkActionButtons={false} rowClick="show">
          <IconEditButton />

          <TextField label="general.id" source="id" />

          <ImageAvatarField
            label={"office.logo"}
            sortable={false}
            source="logo"
          />

          <TextField label="office.name" source="name" sx={{whiteSpace : 'noWrap'}} />
          <TextField source="contactPerson" sx={{whiteSpace : 'noWrap'}}/>
          <TextField source="phone" />
          <TextField source="email" />
          <TextField source="street" />
          <TextField source="streetNumber" />
          <TextField source="City" />
          <TextField source="zipCode" />
          <DeleteWithConfirmButton />

          <LocationButton />
        </Datagrid>
      )}
    </RaList>
  );
};

export default OfficeList;
