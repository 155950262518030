import React, { useCallback } from "react";
import { setModalDialogActionState } from "./actions";
import Store, { ResourceModalDialogState } from "./ResourceModalDialogStore";

const useResourceModalDialogContext = (): [
  ResourceModalDialogState,
  (state: Partial<ResourceModalDialogState>) => void
] => {
  const [state, dispatch] = React.useContext(Store);

  const updateState = useCallback((state: Partial<ResourceModalDialogState>) => {
    dispatch(setModalDialogActionState(state))
  }, [dispatch])

  return [state, updateState]

}

export default useResourceModalDialogContext
